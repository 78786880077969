// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Paragraph, CustomIcon } from 'antd/index';
import CopyIcon from 'assets/icons/CopyIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { showInfoMessage } from 'utils/showInfoMessage';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { contactCollectionRef } from 'services/firestore/references';
import { CONTACT_MODAL } from '../../constants';

interface IContactModalProps {
  isContactModalVisible: boolean;
  setIsContactModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IInfoState {
  productOwner: string;
  PM: string;
  technical: string;
}

const { title, clipboard, productOwner, projectManager, technicalPerson, contestPage, COPY_LINK } = CONTACT_MODAL;

const ContactModal = ({ isContactModalVisible, setIsContactModalVisible }: IContactModalProps) => {
  const [info, setInfo] = useState<IInfoState>({
    productOwner: '',
    PM: '',
    technical: '',
    contestPage: '',
  });
  useEffect(() => {
    const handleAsync = async () => {
      const { productOwner, PM, technical, contestPage } = await getCollectionDocument(contactCollectionRef, 'info');
      setInfo({ productOwner, PM, technical, contestPage });
    };

    handleAsync();
  }, []);

  const handleCloseContactModal = (): void => {
    setIsContactModalVisible(prevState => !prevState);
  };

  return (
    <Modal
      footer={null}
      title={title}
      visible={isContactModalVisible}
      onCancel={handleCloseContactModal}
      closable={true}
      bodyStyle={{ padding: 40, height: 270, width: 500 }}
      centered
      className="contact-modal"
    >
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>{productOwner}</Paragraph>
        </Col>

        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.productOwner}
          </Paragraph>
          <CopyToClipboard
            title={COPY_LINK}
            text={info.productOwner}
            onCopy={() => showInfoMessage('success', `${clipboard}`)}
          >
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>

      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>{projectManager}</Paragraph>
        </Col>

        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.PM}
          </Paragraph>
          <CopyToClipboard title={COPY_LINK} text={info.PM} onCopy={() => showInfoMessage('success', `${clipboard}`)}>
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>{technicalPerson}</Paragraph>
        </Col>
        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.technical}
          </Paragraph>
          <CopyToClipboard
            title={COPY_LINK}
            text={info.technical}
            onCopy={() => showInfoMessage('success', `${clipboard}`)}
          >
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>

      <Row className="contact-modal__row" justify="space-between">
        <Col className="contact-modal__col">
          <Paragraph>{contestPage}</Paragraph>
        </Col>
        <Col className="contact-modal__col">
          <Paragraph
            ellipsis={{
              rows: 1,
            }}
            className="contact-modal__text"
          >
            {info.contestPage}
          </Paragraph>
          <CopyToClipboard
            title={COPY_LINK}
            text={info.contestPage}
            onCopy={() => showInfoMessage('success', `${clipboard}`)}
          >
            <CustomIcon component={CopyIcon} />
          </CopyToClipboard>
        </Col>
      </Row>
    </Modal>
  );
};

export default ContactModal;
