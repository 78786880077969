/* eslint react/prop-types: 0 */

import { Radio, Col, Item, Paragraph } from 'antd/index';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import { QUESTION_SUBTYPES, QUESTION_TYPES, MANAGE_QUESTION } from 'constants/index';
import { useEffect, useState } from 'react';

const { CLOSED } = QUESTION_TYPES; // OPEN
const { CODE, FILE } = QUESTION_SUBTYPES; // NO_CODE
const {
  SELECT_QUESTION_TYPE,
  QUESTION_TYPE,
  CLOSED_QUESTION,
  // OPEN_QUESTION,
  // ANSWER_TYPE,
  // OPEN_QUESTION_INFO,
  // WITH_CODE,
  // WITHOUT_CODE,
  // FROM_FILE,
} = MANAGE_QUESTION;

const RadioSection = ({ handleGetFormFieldValue, handleSetFieldsValue, action }) => {
  const [type, setType] = useState(CLOSED);
  const subtype = handleGetFormFieldValue('subtype');

  useEffect(() => {
    setType(handleGetFormFieldValue('type'));
  });

  const handleSetClosedCodeQuestion = () => {
    handleSetFieldsValue('subtype', CODE);
    handleSetFieldsValue('type', CLOSED);
  };

  const handleOnQuestionTypeChange = e => {
    const selectedOption = e.target.value;
    handleSetFieldsValue('type', selectedOption);
    selectedOption === CLOSED && subtype === FILE
      ? handleSetClosedCodeQuestion()
      : handleSetFieldsValue('subtype', CODE);
  };

  // const handleOnAnswerTypeChange = e => {
  //   const selectedOption = e.target.value;
  //   handleSetFieldsValue('subtype', selectedOption);
  // };

  return (
    <>
      <Col className="radio-section" span={24}>
        <Item
          name="type"
          rules={[{ required: true, message: `${SELECT_QUESTION_TYPE}` }]}
          className="add-question-form__item"
          initialValue={CLOSED}
          label={<Paragraph className="add-question-form__label">{QUESTION_TYPE}</Paragraph>}
        >
          <Radio.Group
            disabled={action === 'edit'}
            name="type"
            className="custom-radio-wrapper"
            onChange={handleOnQuestionTypeChange}
          >
            <CustomRadio className="custom-radio" content={CLOSED_QUESTION} value={CLOSED} />
            {/*<CustomRadio content={OPEN_QUESTION} value={OPEN} />*/}
          </Radio.Group>
        </Item>
      </Col>

      {/*<Col span={12}>*/}
      {/*  <Item*/}
      {/*    className="add-question-form__item"*/}
      {/*    rules={[{ required: true, message: `${ANSWER_TYPE}` }]}*/}
      {/*    label={<Paragraph className="add-question-form__label"> {OPEN_QUESTION_INFO}</Paragraph>}*/}
      {/*    name="subtype"*/}
      {/*    initialValue={CODE}*/}
      {/*  >*/}
      {/*    <Radio.Group*/}
      {/*      disabled={action === 'edit' || type === 'closed'}*/}
      {/*      name="subtype"*/}
      {/*      className={` ${action === 'add' ? 'custom-radio-wrapper  radio-edit-disable' : 'custom-radio-wrapper'}`}*/}
      {/*      onChange={handleOnAnswerTypeChange}*/}
      {/*    >*/}
      {/*      <CustomRadio className="custom-radio" content={WITH_CODE} value={CODE} />*/}
      {/*      <CustomRadio className="custom-radio" content={WITHOUT_CODE} value={NO_CODE} />*/}
      {/*      <CustomRadio className="custom-radio" content={FROM_FILE} value={FILE} isDisabled={type === CLOSED} />*/}
      {/*    </Radio.Group>*/}
      {/*  </Item>*/}
      {/*</Col>*/}
    </>
  );
};

export default RadioSection;
