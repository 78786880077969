import { percentageOfNumber } from './getPercentageResult';

export const getTestCategories = answers => {
  const subjects = answers.map(({ subjects }) => subjects).flat();

  const uniqueSubjectsArray = [...new Set(subjects)].reduce((result, subject) => {
    const correctAnswerSubject = answers?.filter(({ subjects, correct }) => correct && subjects?.includes(subject))
      .length;
    const fullAnswerSubject = answers.filter(({ subjects }) => subjects?.includes(subject)).length;
    return {
      ...result,
      [subject]: {
        points: `${correctAnswerSubject} / ${fullAnswerSubject}`,
        percent: percentageOfNumber(correctAnswerSubject, fullAnswerSubject),
      },
    };
  }, {});

  return uniqueSubjectsArray;
};
