const initialState = [
  {
    letter: 'A',
    count: 0,
    content: '',
    isCorrect: false,
    name: 'answer-A',
    firestoreContent: '',
  },
  {
    letter: 'B',
    count: 1,
    content: '',
    isCorrect: false,
    name: 'answer-B',
    firestoreContent: '',
  },
];

export default initialState;
