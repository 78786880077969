/* eslint-disable react/prop-types */
import { memo } from 'react';
import Countdown from 'react-countdown';
import { Col } from 'antd/index';
import { renderer } from '../../utils/timeRenderer';
import { useSelector } from 'react-redux';
import { showQuestion } from './TestSlice';
import {
  MILISECONDS_TO_SECONDS,
  TEST_KIND_OPTIONS,
  SECONDS_TO_MINUTES,
  TEST_QUESTIONS_WRAPPER_VIEW,
} from 'constants/index';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const { TEST_TIME, MINUTES, SECONDS, QUESTION_TIME, LEFT } = TEST_QUESTIONS_WRAPPER_VIEW;

const TestQuestionsHeader = ({
  remainingTime,
  setRemainingTime,
  onComplete,
  clockRef,
  onConfirm,
  confirmAnswer,
  disableButtons,
  possibilityChooseQuestions,
  testTime,
}) => {
  const currentQuestion = useSelector(showQuestion);
  const displayedTime = new Date().getTime() + remainingTime;

  const time =
    possibilityChooseQuestions === POSSIBILITY_TO_RETURN
      ? testTime / SECONDS_TO_MINUTES
      : currentQuestion.time / MILISECONDS_TO_SECONDS;
  const intervalDelay = MILISECONDS_TO_SECONDS;
  return (
    <Col className="quizz-timer">
      <Col className="quizz-timer-title">
        {possibilityChooseQuestions === POSSIBILITY_TO_RETURN
          ? `${TEST_TIME} ${time} ${MINUTES}`
          : `${QUESTION_TIME} ${time} ${SECONDS}`}
      </Col>
      <Col className="quizz-timer-subTitle">{LEFT}</Col>
      <Col className="quizz-timer-value">
        <Countdown
          ref={clockRef}
          date={displayedTime}
          possibilityChooseQuestions={possibilityChooseQuestions}
          renderer={time && renderer}
          intervalDelay={intervalDelay}
          onTick={() => setRemainingTime(remainingTime - intervalDelay)}
          onComplete={confirmAnswer && !disableButtons ? onConfirm : onComplete}
          autoStart
          daysInHours={!time}
        />
      </Col>
    </Col>
  );
};

export default memo(TestQuestionsHeader, (prevState, nextState) => prevState.remainingTime === nextState.remainingTime);
