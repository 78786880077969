/* eslint-disable no-useless-escape */
/**
 * Global environment variables.
 */

// time to warn candidate that only XX seconds left - used in timeRenderer.js
export const TIME_OUT_WARNING = 10;

export const QUESTION_TYPES_LABELS = {
  CLOSED: 'Closed questions',
  OPEN: 'Open questions',
};
export const CODEMIRROR_SETTINGS = {
  maxLenght: 80,
};
export const FULL_NAME_REGEX = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð ,.'-]{3,}$/u;
export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const PHONE_REGEX = /(^[0-9]{9}$)/g;
export const PDF_TITLE_REGEX = /[^a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð0-9 ,.?'-]/g;

//time value in miliseconds
export const MIN_CLOSED_QUESTION_DURATION = 20000;
export const MAX_CLOSED_QUESTION_DURATION = 120000;
export const MIN_OPEN_QUESTION_DURATION = 120000;
export const MAX_OPEN_QUESTION_DURATION = 1800000;

export const MILISECONDS_TO_SECONDS = 1000;
export const SECONDS_TO_MINUTES = 60;

export const USER_ROLES = {
  RECRUITER: 'Recruiter',
  TECHNICAL: 'Technical',
  ADMIN: 'Admin',
  ROOT: 'Root',
  NO_ROLE: '',
  LEAD: 'Lead',
};

export const STORAGE_PATH = {
  TEST_TO_SOLVE: 'test-to-solve',
  QUESTION_IMAGES: 'question-images',
  CONTESTS: 'contests',
};

export const FILE_EXTENSION = {
  PNG: 'png',
  JPG: 'jpg',
  JPEG: 'jpeg',
  JS: 'js',
  XML: 'xml',
  DOC: 'doc',
  DOCX: 'docx',
  PDF: 'pdf',
};

export const METADATA = {
  PDF: {
    contentType: 'application/pdf',
  },
};

export const DOCUMENT_TYPE = {
  IMAGE: 'image',
  DOCUMENT: 'document',
  OTHER: 'other',
};

export const sendEmailEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendEmail`;
export const downloadFileEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/downloadFile`;
export const sendNotificationEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/sendNotification`;
export const createUserEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/createUser`;
export const deleteUserEndpoint = `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/deleteUser`;

export const PLACEHOLDER_TEXTS = {
  HIDDEN_TESTS: 'Hidden tests',
  LEVEL: 'Level',
  SEARCH_QUESTION: 'Search the question',
  SEARCH_TEST: 'Search the test',
  SEARCH_CONTEST: 'Search the contest',
  SEARCH_RESULT: 'Search',
  CATEGORY: 'Categories',
  KIND: 'Type of test',
  STATUS: 'Status',
  DATE: ['Start date', 'End date'],
};

export const COMPONENT_NAMES = {
  QUESTIONS: 'questions',
  QUIZZES: 'quizzes',
};
export const SUBMIT_TEST_QUESTION_STATUSES = {
  MOVE_ON: 'Go next',
  REPLY: 'Answer',
  END: 'Finish the test',
};

export const QUESTION_TYPES = {
  OPEN: 'open',
  CLOSED: 'closed',
  ANSWER_FROM_FILE: 'answersFromFile',
};

export const QUESTION_STATUSES = {
  SELECTED: 'selected',
  UNSOLVED: 'unsolved',
  SOLVED: 'solved',
};

export const SCROLLING_TYPE = {
  NEXT: 'next',
  PREV: 'prev',
};

export const RESULT_QUIZZ_COLOR = {
  RED: 'red',
  ORANGE: 'orange',
  GREEN: 'green',
  YELLOW: 'yellow',
  WHITE: 'white',
};

export const QUESTION_CHOICE_TYPES = {
  MULTI: 'multi',
  SINGLE: 'single',
  withFile: 'withFile',
};

export const TEST_STATUSES = {
  CREATED: 'created',
  IN_PROGRESS: 'in progress',
  FILLED: 'filled',
  FINISHED: 'finished',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
};

export const TEST_STATUSES_SELECT = {
  CREATED: 'created',
  IN_PROGRESS: 'in progress',
  FINISHED: 'finished',
  EXPIRED: 'expired',
};

export const LEVELS = {
  STEP_ONE: 'Step one',
  JUNIOR: 'Junior',
  PROFESSIONAL: 'Professional',
  SENIOR: 'Senior',
};

export const EDIT_CLOSED_QUESTION_LABELS = {
  VALIDATION_ERROR: 'Failed to edit the question!',
  FORM_NAME: '',
  FORM_TITLE: 'Editing a closed question',
  FORM_SUBTITLE: 'Question',
};

export const ADD_CLOSED_QUESTION_LABELS = {
  VALIDATION_ERROR: 'Failed to add a new question!',
  FORM_NAME: 'addClosedQuestionForm',
  FORM_TITLE: 'Adding a closed question',
  FORM_SUBTITLE: 'Details of the question',
};

const { WHITE, GREEN, YELLOW, RED } = RESULT_QUIZZ_COLOR;
// Not convetional sign, but needed in such way in modals
export const LEVELS_COLORS = {
  'Step one': { color: WHITE },
  Junior: { color: GREEN },
  Professional: { color: YELLOW },
  Senior: { color: RED },
};

export const MENU_ELEMENTS_CONTENT = {
  questions: 'Questions',
  results: 'Results (All)',
  tests: 'Contests',
  statistics: 'Statistics',
  settings: 'Settings',
  logout: 'Log out',
  live: 'Live',
  account: 'Account',
  contests: 'Contests Results',
};

export const USER_REDIRECT_URL = {
  Root: '/initial',
  Admin: '/initial',
  Technical: '/initial',
  Recruiter: '/initial',
  Lead: '/initial',
};

export const MENU_TYPES = {
  APP: 'APP',
  USER: 'USER',
};
export const TEST_KIND_OPTIONS = {
  NO_RETURN: 'No way back',
  NO_RETURN_ACCEPT: 'No way back + acceptation',
  POSSIBILITY_TO_RETURN: 'Possibility to return',
};

export const PERMISSIONS_PATTERN = {
  Root: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: true,
    LIVE: true,
    CONTESTS: true,
  },

  Admin: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: true,
    LIVE: true,
    CONTESTS: true,
  },

  Technical: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: false,
    LIVE: true,
    CONTESTS: true,
  },

  Recruiter: {
    HOME: true,
    QUESTIONS: false,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: false,
    STATISTICS: false,
    SETTINGS: false,
    LIVE: false,
    CONTESTS: true,
  },
  Lead: {
    HOME: true,
    QUESTIONS: true,
    RESULTS: true,
    TESTS: true,
    VERIFICATION: true,
    STATISTICS: true,
    SETTINGS: false,
    LIVE: true,
    CONTESTS: true,
  },
};
export const ROLES = {
  RECRUITER: 'Recruiter',
  TECHNICAL: 'Technical',
  ADMIN: 'Admin',
  ROOT: 'Root',
  LEAD: 'Lead',
  NO_ROLE: '',
};
export const COMPONENT_TABLE_NAME = {
  QUESTIONS: 'questions',
  BASE_TESTS: 'quizzes',
  TESTS: 'tests',
  RESULTS: 'results',
  MANAGE_TEST: 'manageTest',
  VERIFICATION: 'verification',
  CONTESTS: 'contests',
};

export const LANGUAGE_MODE = {
  CSharp: 'C#',
  C: 'C',
  CPlusPlus: 'C++',
  JAVA: 'Java',
  OBJECTIVEC: 'Objective-C',
  SCALA: 'Scala',
  LESS: 'Less',
  TYPESCRIPT: 'TypeScript',
  HTML: 'Html',
};

export const PATH_APP = {
  HOME: '/',
  QUESTIONS: '/questions',
  EDIT_QUESTIONS: '/question/edit',
  VERIFICATION: '/verification',
  VERIFICATION_WITH_ID: '/verification/:generatedTestId/:questionId',
  LOGIN: '/login',
  RESULTS: '/results',
  SETTINGS: '/settings',
  TESTS: '/tests',
  ADD_TEST: '/tests/add-test',
  EDIT_TEST: '/tests/edit',
  REQUEST_APP_ACCESS: '/request-access',
  CONTESTS: '/contests',
  INITIAL: '/initial',
};

export const ALPHABET = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const MANAGE_QUESTION_FORM_ITEM_NAMES = {
  TITLE: 'title',
  TYPE: 'type',
  SUBJECTS: 'subjects',
  LEVEL: 'level',
  TIME: 'time',
  CODE: 'code',
  CONTENT: 'content',
  SUBTYPE: 'subtype',
  CONTENT_IMAGE: 'contentImage',
  QUESTION_IMAGE: 'questionImage',
  CODE_FRAGMENT: 'codeFragment',
  INSTRUCTIONS: 'instructions',
  LANGUAGE: 'language',
};

export const QUESTION_SUBTYPES = {
  CODE: 'code',
  NO_CODE: 'no-code',
  FILE: 'file',
};

export const ASYNC_STATUSES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  IDLE: 'idle',
};

export const MANAGE_QUESTION_ACTION_TYPES = {
  ADD: 'add',
  EDIT: 'edit',
};

export const MIN_SCREEN_WIDTH_FOR_TEST = 1152;

export const SETTINGS_ACTIVE_TABS = {
  USERS: 'users',
  ROLES: 'roles',
  CATEGORIES: 'categories',
  RESULTS: 'results',
  TEMPLATES: 'templates',
  ACCESS: 'access',
};

export const SEND_EMAIL_WITH_GENERATED_TEST_LINK_MESSAGES = {
  INFO: 'An email with a link to the test has been sent to the candidate',
  WARNING: 'Email with link to the test - an error occurred while sending',
};

export const NUMBER_OF_ITEMS_PER_PAGE = 7;

export const PAGE_SIZE_OPTION = ['7', '14', '21'];

export const PAGINATION = {
  PAGE: 'page',
};

export const CONTACT_INFO = {
  PROJECT_MANAGER: 'joanna.kaczorowska@britenet.com.pl',
  TECHNICAL: 'przemyslaw.samon@britenet.com.pl',
  ADDRESS: 'briteping.support@britenet.com.pl',
};

export const DOMAIN_LINKS = {
  PRODUCTION: 'https://briteping.britenet.com.pl/',
  DEVELOPMENT: 'https://dev0briteping.britenet.com.pl/',
  UAT: 'brite-ping-uat.web.app',
};

export const APP_VERSIONS = {
  APP_PRODUCTION: 'Production',
  APP_DEVELOPMENT: 'Test',
  UAT: 'UAT',
};

export const PERCENTAGE_VALUE = {
  LOW: 30,
  AVG: 70,
};

export const UNANSWERED_QUESTION = 'b/o';

export const ADD_NEW_TEST = {
  duplicate: '-duplicate',
  testDuplicate: 'Duplicating a test',
  addNewTest: 'Adding a new test',
  testName: 'Name of the test',
  titlePlaceholder: 'Enter a name for the test',
  categories: 'Categories',
  selectPlaceholder: 'Select',
  department: 'Department',
  cancel: 'Cancel',
  next: 'Next',
};

export const CHART_COLUMNS = {
  correctAnswers: 'Correct Answers',
  incorrectAnswers: 'Incorrect Answers',
};

export const CONTACT_MODAL = {
  title: 'Information',
  link: 'Link',
  clipboard: 'Copied to the clipboard',
  googleChat: 'Google chat',
  ver: 'Version',
  productOwner: 'Product Owner',
  projectManager: 'Project Manager',
  technicalPerson: 'Technical Person',
  contestPage: 'Contest Page',
  COPY_LINK: 'Copy link',
};

export const BB_CODE_EDITOR = {
  WRITE_CODE: '// Write code...',
  ANSWER: 'Write your answer',
  QUESTION: 'Write your question',
  OPEN_CODE: 'The code must be  between the tags ``` ```',
};

export const INFO_CARD = {
  BUTTON_LABEL: 'Continue',
};

export const PLACEHOLDERS = {
  SELECT: 'Select',
  TEXT_AREA: 'Your answer',
  TIME_PICKER: 'Select',
  CHOOSE_QUESTION: 'Select a question',
  CHOOSE_TIME: 'Choose a time',
  SELECT_DAY: 'Select a day and time',
  ENTER: 'Enter',
  ENTER_CONTENT: 'Enter the content',
};

export const BUTTON_TEXTS = {
  NEW_QUESTION: 'New question',
  NEW_TEST: 'New test',
  CLEAR: 'Clear',
  FAILURE_MESSAGE_MODAL: 'Understand',
  CLOSE: 'Close',
  SAVE: 'Save',
  PREVIEW: 'Preview',
  CANCEL: 'Cancel',
  GENERATE_TEST_BUTTON: 'Generate test',
  GENERATE: 'Generate',
  EDIT: 'Edit',
  GO_NEXT: 'Go next',
};

export const statusesArray = ['All', 'Created', 'During', 'Finished', 'Expired'];

export const HIDDEN_TEST_OPTIONS = ['All', 'Expired', 'Not expired'];

export const CUSTOM_TABLE = {
  ERROR: 'An error occurred while hiding the test:',
};

export const CUSTOM_TIME_PICKER = {
  SELECT_TIME: 'Select time',
  MINUTES: 'Minutes',
  SECONDS: 'Seconds',
  MIN: 'min',
  SEC: 'sec',
};

export const QUESTION_DETAIL_MODAL = {
  QUESTION_PREVIEW: 'Question preview',
  QUESTION_CONTENT: 'The content of the question',
  ANSWER: 'Answer',
  CORRECT: 'Correct',
  INCORRECT: 'Incorrect',
  CODE: 'Code',
};

export const QUESTION_MODAL = {
  TASK: 'Task',
  QUESTION_INF: 'Information about the question:',
  LEVEL: 'Level:',
  REPLY_TIME: 'Time for a reply:',
  QUESTION: 'Question:',
  ANSWERS: 'Answers:',
};

export const QUESTION_MODAL_ROW = {
  CORRECT: 'Correct',
  INCORRECT: 'Incorrect',
};

export const FILE_QUESTION = {
  FILE_ANSWER: 'Add a file with answer.',
  MAX_SIZE: 'The size of the attached file is a maximum of 3 MB.',
  EXTENSIONS: 'You can add files with the extensions .doc, .docx, .jpg, .jpeg, .png, .pdf, .xml, .js',
  OVERWEIGHT: 'The attached file exceeds 3 MB in size.',
  INCORRECT_FILE_TYPE: 'Incorrect file type.',
  TIMES_OVER: 'The time for a reply is over.',
};

export const QUESTIONS_CONTENT = {
  ANSWER: 'Answer',
};

export const QUESTION_TIMER = {
  QUESTION_TIME: 'Question time:',
};

export const DETAILS_PDF_TEMPLATE = {
  SHORTAGE: 'Shortage ',
  CANDIDATE: 'Candidate ',
  CATEGORY: 'Category ',
  LEVEL: 'Level ',
  COMPLETION: 'Date of completion ',
  DEDICATED_TIME: 'Dedicated time ',
  OVERALL: 'Overall score ',
  CLOSED_QUESTIONS: 'Closed questions ',
  TIME: 'Time ',
  MOUSE: 'Mouse ',
  CARD: 'Card ',
  OPEN_QUESTIONS: 'Open questions ',
};

export const SOLVE_TEST_IMAGE = {
  PREVIEW: 'Preview',
};

export const STATISTIC_MODAL = {
  SELECT_QUESTION: 'Please select a question',
  TEST_INF: 'Information about the test',
  ATTEMPTS_TEST: 'Test attempts',
  CORRECT: 'Correct answers',
  INCORRECT: 'Incorrect answers',
};

export const USER_PANEL = {
  NOTIFICATION: 'Enable email notifications',
  ACCOUNT_SETTINGS: 'Account settings',
};

export const TEST_RESULT_MODAL = {
  COMPLETION: 'Date of completion ',
  EXPIRATION: 'Expiration date ',
  DEDICATED_TIME: 'Dedicated time ',
  COPY: 'Copy',
  LINK: 'Link',
  OVERALL: 'Overall score ',
  CANDIDATE: 'Candidate ',
  TEST_AUTHOR: 'Author of the test ',
  FULL_ANALYSIS: 'Full analysis',
  WAITING: 'Test result incomplete. Full Analysis and Pdf not available.',
  GENERATE_PDF: 'Generate PDF',
};

export const AUTH_PROVIDER = {
  LOGOUT_FAILED: 'Logout Failed!',
  CONNECTION_ERROR: 'Firebase Connection Error!',
};

export const BASIC_STATISTICS = {
  TITLE: 'Title',
  CREATED_BY: 'Created by',
  CREATION_DATE: 'Creation date',
  CATEGORY: 'Category',
  DEPARTMENTS: 'Departments',
  LEVEL: 'Level',
  AVERAGE_SCORE: 'Average score',
  GENERAL_STATISTICS: 'General statistics',
  SOURCE_TESTS: 'Number of source tests',
  GENERATED_TESTS: 'Number of generated tests',
};

export const FINISH_TEST = {
  COMPLETED_TEST: 'You have completed the test!',
  RESULT_INF: 'Click below to see the obtained result.',
  SHOW_RESULT: 'Show the result',
  COMPLETED_TEST_INF: 'You have completed the contest:',
  RECRUITER_CONTACT: 'The recruiter will contact you',
  CHECKING_ANSWER: 'after checking the answer.',
  GO_TO_PAGE: 'Go to the Britenet website',
  USER_RESULT: 'Your result is: ',
  THANKS_FOR_PARTICIPATING: 'Thank you for participating in our test!',
  TIME_SPENT: 'Time spent on solution: ',
  SETTLEMENT_INFO: 'We will soon inform you about the contest settlement.',
  CONTEST_CONTACT: 'Someone from our team will contact you.',
  GO_TO_CONTEST: 'Go to the start page',
};

export const GENERATE_TEST = {
  GENERATED_TEST: 'Generated test',
  CANDIDATE: 'Candidate',
  EXPIRE: 'Test expire date',
  LINK: 'Link to the test',
  COPY_LINK: 'Copy link',
  FULL_NAME: 'Candidate first and last name',
  EMAIL: 'Email',
  SET_TEST_EXPIRATION: 'Please set the test expiration time',
  SHOW_RESULT: 'Show the result to the candidate',
  QUESTIONS_ORDER: 'Random order of questions',
  CLIPBOARD: 'Copied to the clipboard',
  GENERATING_TEST: 'Generating the test',
};

export const LIST_FINISHED_TESTS_VIEW = {
  TEST: 'Test',
  CREATED_BY: 'Created by',
  CANDIDATE: 'Candidate',
  COMPLETION_DATE: 'Completion date',
  EXPIRE_TIME: 'Expire time',
  RESULT: 'Result',
  PREVIEW: 'Preview',
  DELETE: 'Delete',
  CONFIRM: 'Are you sure of your decision?',
  YES: 'Yes',
  NO: 'No',
};

export const LIST_FINISHED_TEST_DETAILS = {
  INSTRUCTIONS: 'Instructions',
  TIME: 'Time',
  STATUS: 'Status',
  NO_ANSWER: 'No answer was given',
  UNTESTED: 'Untested',
  CORRECT: 'Correct',
  INCORRECT: 'Incorrect',
  CATEGORY: 'Category',
  DEPARTMENT: 'Department',
  MOUSE_LEAVES: 'Mouse leaves',
  TABS_LEAVES: 'Tabs leaves',
  COPIED_DATA: 'Copied data',
  CLIPBOARD_DATA: 'Data from clipboard',
  EXAMINED_BY: 'Examined by',
  NOTE: 'Note',
  RATE: 'Rate',
  CHECKER_NOTE: 'Checker note',
  CLOSE: 'Close',
  NOTE_AUTHOR: 'Author of the note',
  QUESTION_PREVIEW: 'Question preview',
  QUESTION_TYPE: 'Question type:',
  QUESTION_CONTENT: 'The content of the question:',
  ANSWER: 'Answer:',
  ANSWERS: 'Answers',
  DOWNLOAD: 'Download',
  FROM_FILE: 'From file',
  OPEN: 'Open',
  MULTIPLE_CHOICE: 'Multiple choice',
  SINGLE_ANSWER: 'Single answer',
  CANDIDATE: 'candidate:',
  CREATED_BY: 'Created by ',
  RECRUITED_PERSON: 'Contestant ',
  TITLE: 'Title ',
  LEVEL: 'Test level ',
  CREATION_DATE: 'Creation date: ',
  CLOSED_QUESTIONS: 'Closed questions',
  OPEN_QUESTIONS: 'Open questions',
  FILE_QUESTIONS: 'Questions from the file',
  RESPONSE_TIME: 'Response time',
  TIME_SPENT: 'Time spent:',
  OVERALL: 'Overall score ',
  AUTHOR: 'Author',
  TERMINATION_DATE: 'Date of completion',
  MISTAKES: 'Mistakes',
  UNANSWERED_QUESTIONS: 'Unanswered questions',
  EXPIRED_ON: 'Test expired on:',
  NO_SOLVED: 'The test has not yet been solved',
  CARD_EXIT_TIME: 'Copied data / Card exit time:',
  DATA_FROM_CLIPBOARD: 'Data from clipboard',
};

export const LIVE_VIEW = {
  LIVE: 'LIVE VIEW, WORK IN PROGRESS',
};

export const LOGIN_VIEW = {
  PLATFORM_LOGIN: 'Login to the platform',
  NOT_LOGIN: 'You are not logged in, click below to log in',
  LOGIN: 'Login',
};

export const MANAGE_QUESTION = {
  ANSWER: 'Answer',
  DELETE: 'Delete',
  ANSWER_FIELD: 'Answer field',
  ATTACH_FILE: 'At this point, the person completing the test will be able to attach a file with the answer.',
  ANSWER_WITHOUT_CODE:
    'The person completing the test will see a field under the question to enter an answer without a code.',
  ENTER_TEXT: 'Enter text ...',
  ENTER_ANSWER: 'Please enter your answer',
  CODE_EDITED: 'Code to be edited for the candidate',
  NEXT_ANSWER: 'Add next answer',
  PHOTO_TO: 'Photo to ',
  QUESTIONS: 'questions',
  ANSWERS: 'answers',
  ADD_PHOTO: 'Add photo to ',
  QUESTION_TITLE: 'Enter question title',
  QUESTION_CATEGORY: 'Question category',
  SELECT_TOPIC: 'Select topic',
  DEPARTMENTS_TITLE: 'Departments',
  SELECT_DEPARTMENT: 'Select department',
  QUESTION_LEVEL: 'Question level',
  SELECT_LEVEL: 'Select level',
  TIME_FOR_REPLY: 'Time for a reply',
  WRITING_INF: 'I am writing down the question...',
  ADDED_QUESTION: 'The question has been added',
  OVERWRITTEN: 'The question has been overwritten',
  ADDING: 'Adding',
  EDITING: 'Editing',
  QUESTION: 'question',
  QUESTION_CONTENT: 'The content of the question',
  ENTER_QUESTION: 'Enter the content of the question',
  SAVE: 'Save question',
  OOPS: 'Oops ... something went wrong',
  SELECT_QUESTION_TYPE: 'Select the type of question',
  QUESTION_TYPE: 'Question type ',
  CLOSED_QUESTION: 'Closed',
  OPEN_QUESTION: 'Open',
  ANSWER_TYPE: 'Select the type of answer',
  OPEN_QUESTION_INFO: 'Answer type (open-ended questions)',
  WITH_CODE: 'With code',
  WITHOUT_CODE: 'Without code',
  FROM_FILE: 'From file',
  PARAGRAPH_TITLE: 'Question title',
};

export const MANAGE_TEST_VIEW = {
  EDIT_FAILED: 'Failed to edit the test.',
  CONTACT_ADMINISTRATOR: 'Please contact the administrator.',
  CLOSE: 'Close',
  ACCEPT: 'Accept',
  QUESTION_RETURN: 'The option to return to the questions was selected',
  DURING_TEST: 'while taking the test.',
  DURATION: 'Select the test duration for this option.',
  SUGGESTED_TIME: 'Suggested time:',
  MINUTES: 'minutes',
  TEST_DURATION: 'Duration of the test [minutes]',
  ENTER_MINUTES: 'Enter the number of minutes',
  CANCEL: 'Cancel',
  SAVE_TEST: 'Save test',
  SELECT: 'Select',
  QUIT_EDITING: 'Do you want to quit editing the test',
  WITHOUT_SAVING: 'without saving it?',
  BACK: 'Back',
  ENTER_TEST_NAME: 'Enter a name for the test',
  PREVIEW: 'Preview',
  READ_REGULATIONS: 'Read the regulations [PDF]',
  TEST_TYPE: 'Type of test',
  SAVE: 'Save',
  OOPS: 'Ooops... no questions here',
  PLEASE_SET_CRITERIA: 'Set the search criteria at the top of the screen',
  CONTEST_DESCRIPTION: 'Contest description',
  TEXTAREA_PLACEHOLDER: 'Write contest description (displayed on welcome page)',
  ADD_REGULATIONS_FILE: 'Add a regulations file [PDF]',
  REPLACE_REGULATIONS_FILE: 'Replace a regulations file [PDF]',
  UPLOAD_FIELD_TEXT: 'Upload file [Max: 3MB]',
};

export const NO_ACCESS = {
  PENDING_REQUEST: 'No access, your request is pending',
  ACCESS_DENIED: 'Access denied, click below to request access',
  MADE_REQUEST: 'A request has been made',
  REQUEST_ACCESS: 'Request access',
};

export const QUESTIONS_VIEW = {
  TITLE: 'Title',
  CREATED_BY: 'Created by',
  CATEGORY: 'Category',
  LEVEL: 'Level',
  EDIT: 'Edit',
  DELETE: 'Delete',
  FAILED_DELETE: 'Failed to delete the question',
  CLOSED_QUESTION: 'Closed',
  OPEN_QUESTION: 'Open',
};

export const SETTINGS_VIEW = {
  EDIT_CATEGORY_NAME: 'Edit the category name',
  NEW_NAME: 'New name',
  EDIT_CATEGORIES: 'Edit categories',
  MAKE_DECISION: 'Are you sure you want to delete this record?',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  YES: 'Yes',
  NO: 'No',
  SELECT_PERMISSIONS: 'Select permissions',
  TABS_LEAVES: 'Access to the overview of how many times the browser tab has been left',
  MOUSE_LEAVES: 'Access to the overview of how many times the browser window has been left with the mouse',
  COPIED_DATA: 'Access to copied data',
  HIDDEN_TESTS: 'Access to hidden tests',
  EDIT_PERMISSIONS: 'Edit permissions',
  EMAIL: 'Email',
  ROLE: 'Role',
  AVAILABLE_DEPARTMENTS: 'Departments available',
  ADD_USER: 'Add a user',
  ADD_MESSAGE: 'A message has been added',
  USERS: 'Users',
  DEPARTMENTS: 'Departments',
  SEARCH_USER: 'Search for a user',
  ENTER_DEPARTMENT_NAME: 'Please enter the name of the department.',
  DEPARTMENT_NAME: 'Department name',
  ADD: 'Add',
  MESSAGE: 'Message',
  ENTER_ROLE_NAME: 'Please enter a role name.',
  ROLE_NAME: 'Role name',
  ACCESS: 'Access',
  ADD_ACCESS: 'Add access',
  SELECT_ROLE: 'Select role',
  SELECT_DEPARTMENTS: 'Select departments',
  CATEGORIES: 'Categories',
  ENTER_CATEGORY_NAME: 'Please enter the name of the category.',
  CATEGORY_NAME: 'Category name',
};

export const TABLE_SHAPES_VIEW = {
  CLOSED_QUESTIONS: 'closed',
  OPEN_QUESTIONS: 'open',
  TEST_NAME: 'Test name',
  AUTHOR: 'Author',
  CREATION_DATE: 'Creation date',
  CATEGORY: 'Category',
  DEPARTMENTS: 'Departments',
  AVERAGE_SCORE: 'Average result',
  TEST: 'Test',
  CANDIDATE: 'Candidate',
  STATUS: 'Status',
  COMPLETED: 'Completed',
  EXPIRED: 'Expired',
  RESULTS: 'Results',
  MAKE_DECISION: 'Are you sure you want to delete this record?',
  DELETE: 'Delete',
  CANCEL: 'Cancel',
  YES: 'Yes',
  NO: 'No',
  QUESTION_TITLE: 'Question title',
  LEVEL: 'Level',
  TYPE: 'Type',
  INSTRUCTION: 'Instruction',
  DETAILS: 'Details',
  RATE: 'Rate',
  NUMBER_OF_QUESTIONS: 'Quantity',
  NO_TESTS: 'No tests',
};

export const TEST_EXPIRED_VIEW = {
  EXPIRED_INF: 'The test expired on the day ',
};

export const TEST_QUESTIONS_WRAPPER_VIEW = {
  SELECT_ONE_ANSWER: 'Select the answer',
  SELECT_MULTI_ANSWER: ' Select one or more answers',
  END_TEST_1: 'Whether to end the test without awarding it',
  END_TEST_2: 'answers to all questions?',
  END_TEST: 'End test',
  CANCEL: 'Cancel',
  RESTART: 'Restart to initial value',
  CONFIRMATION_MESSAGE_PART1:
    'After clicking the go to button, you will not be able to return to the currently displayed task.',
  CONFIRMATION_MESSAGE_PART2: 'The time of the current job continues.',
  TASK: 'Task',
  TASKS: 'Tasks',
  TEST_TIME: 'Test time: ',
  MINUTES: 'minutes',
  SECONDS: 'seconds',
  QUESTION_TIME: 'Time for a question: ',
  LEFT: 'Left',
  ERROR_OCCURED: 'Error occured',
  CONTACT_ADMINISTRATOR: 'Please contact the administrator',
  QUESTION: 'question',
  TEST: 'test',
  RELOADING_WARNING:
    'Warning! After reloading the page, the previous question was marked as unanswered and you were moved to the next one.',
};

export const TEST_RESULT_VIEW = {
  UNABLE_TEST_LOAD: 'Unable to load test.',
  NOT_FINISHED_TEST: 'Test not complete. End the test.',
  ERROR_OCCURED: 'Error occured',
  CONTACT_ADMINISTRATOR: 'Please contact the administrator',
  FINISHED_TEST: 'Test complete',
  FINISH: 'Finish',
  HR_INF: 'An HR specialist will contact you in connection with the next stage of recruitment.',
  CORRECT_ANSWERS: 'Correct answers',
  INCORRECT_ANSWERS: 'Incorrect answers',
};

export const TEST_START_VIEW = {
  BRITEPING: 'BritePing',
  TEST_START_PART1: 'To start your test',
  TEST_START_PART2: 'open the link sent in the email',
  TEST_START_PART3: 'using a desktop browser.',
  BACK: 'Back',
  TEST_INF_PART1: 'How to answer questions:',
  TEST_INF_PART2: 'Multiple answer questions can have more than one correct answer.',
  TEST_INF_PART3: 'To answer the question click the “Go next” button. After clicking "Go next" button',
  TEST_INF_PART3A:
    'To answer the question click the “Accept” button and confirm your choice by the “Go next” button.  After confirmation',
  TEST_INF_PART4: ' you cannot return to the question.',
  TEST_INF_PART5: 'Check the answer and go to the next question.',
  TEST_INF_PART6: 'The response time is limited.',
  TEST_INF_PART7:
    'In the upper right corner you will see a timer showing the remaining time. When the time is up - you will not be able to reply.',
  TEST_INF_PART8:
    'Exiting the test will result in termination of the unfinished contest. It is possible to return to the contest by receiving a link redirecting to the next question.',
  TEST_INF_PART8A:
    'Exiting the test will result in termination of the unfinished contest. It is possible to return to the contest by receiving a link redirecting to the next question. Reloading the page will result in moving to the next question with the previous question marked as unanswered.',
  TEST_INF_PART9:
    'In case of problems or questions, contact the person from the HR department who is responsible for your recruitment process.',
  TEST_INF_PART10: 'Good luck!',
  TEST_INF_PART11: 'Time to solve the test: ',
  TEST_INF_PART12: 'Technology: ',
  TEST_INF_PART13: 'Test expire date: ',
  TEST_INF_PART14: 'Start the test',
};

export const ACTIVATE_BUTTON = {
  ACTIVE: 'Activate',
  INACTIVE: 'Deactivate',
};

export const INITIAL_VIEW = {
  WELCOME_PART1: 'Welcome to the Britenet contest module.',
  WELCOME_PART2: 'We invite you to take part in our contest ',
  WELCOME_PART_3: 'At the moment, all contests are over. Please come back soon.',
  WELCOME_PART_4: 'If you are interested in working for Britenet, please visit:',
  BRITENET_CAREER: 'https://britenet.com.pl/en/career',
  TITLE_BRITENET_CAREER: 'Britenet - Career',
  JOIN: 'Join the contest',
  TO_TAKE_PART: 'To start the contest, click the button below, provide your details and answer the questions.',
  GOOD_LUCK: 'Good luck and have fun!',
};

export const DATABASE_ERROR = 'Sorry but there is a problem with database :(';
