import ManageTestContainer from 'views/ManageTest/ManageTestContainer';
import TestListContainer from 'views/TestList/TestListContainer';
import { v4 as uuidv4 } from 'uuid';

export const TESTS_ROUTES = [
  {
    path: '/tests',
    component: TestListContainer,
    key: uuidv4(),
  },
  {
    path: '/tests/add-test',
    component: ManageTestContainer,
    key: uuidv4(),
  },
  {
    path: '/tests/edit/:id',
    component: ManageTestContainer,
    key: uuidv4(),
  },
  {
    path: '/tests/add-test/:questionsDuplicatedJSON',
    component: ManageTestContainer,
    key: uuidv4(),
  },
];
