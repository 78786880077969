import { Col, CustomIcon, Paragraph, Row, Text, Title } from 'antd/index';
import { LogoIcon, TimerIcon } from 'assets/icons';
import CustomButton from 'components/CustomButton/CustomButton';
import {
  SECONDS_TO_MINUTES,
  TEST_KIND_OPTIONS,
  TEST_STATUSES,
  TEST_START_VIEW,
  CUSTOM_TIME_PICKER,
} from 'constants/index';
import { array, bool, func, object, string } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import { formatDateWithoutSeconds } from 'utils/formatDate';
import FinishTestWithNoResults from 'views/FinishTest/FinishTestWithNoResults';
import { updateStartTime } from '../TestQuestionsWrapper/TestSlice';

const { NO_RETURN_ACCEPT, NO_RETURN, POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const { CREATED, IN_PROGRESS } = TEST_STATUSES;
const {
  TEST_INF_PART1,
  TEST_INF_PART2,
  TEST_INF_PART3,
  TEST_INF_PART3A,
  TEST_INF_PART4,
  TEST_INF_PART5,
  TEST_INF_PART6,
  TEST_INF_PART7,
  TEST_INF_PART8,
  TEST_INF_PART8A,
  TEST_INF_PART11,
  TEST_INF_PART12,
  TEST_INF_PART14,
} = TEST_START_VIEW;
const { MIN, SEC } = CUSTOM_TIME_PICKER;
const TEN_MINUTES = 600000;

const TestStart = ({
  setStartTest,
  generatedId,
  generatedTest,
  isTestStarted,
  subjects,
  isTestFinished,
  isTestCompleted,
  testTitle,
}) => {
  const dispatch = useDispatch();
  const expireTime = formatDateWithoutSeconds(generatedTest?.expireTime);
  const minutes = Math.floor(generatedTest?.testTime / SECONDS_TO_MINUTES);
  const seconds = generatedTest?.testTime % SECONDS_TO_MINUTES;
  const testTimeMilliseconds = generatedTest.testTime * 1000;
  const updateTestData = async () => {
    if (await isTestCompleted()) {
      return;
    }
    setStartTest(true);
    if (generatedTest?.status === CREATED) {
      generatedTestsCollectionRef.doc(generatedId).update({ status: IN_PROGRESS }, { merge: true });
      generatedTestsCollectionRef
        .doc(generatedId)
        .set({ startedAt: Date.now(), expireTime: Date.now() + testTimeMilliseconds + TEN_MINUTES }, { merge: true });
      dispatch(updateStartTime(Date.now()));
    }
  };

  useEffect(() => {
    if (isTestStarted) {
      updateTestData();
    }
  }, []);

  if (isTestFinished) {
    return <FinishTestWithNoResults testTitle={testTitle} />;
  }

  return (
    <Col className="start-wrapper">
      <Col className="start-container">
        <Col className="start-container__logo-container">
          <CustomIcon component={LogoIcon} />
        </Col>
        <Col className="start-container__content">
          <Col className="start-container__instructions">
            <Title className="start-container__title" level={5}>
              {TEST_INF_PART1}
            </Title>
            <Paragraph>{TEST_INF_PART2}</Paragraph>
            {generatedTest?.testKind === NO_RETURN && (
              <Paragraph>
                {TEST_INF_PART3}
                <Text className="start-container__text--red">{TEST_INF_PART4}</Text>
              </Paragraph>
            )}
            {generatedTest?.testKind === NO_RETURN_ACCEPT && (
              <Paragraph>
                {TEST_INF_PART3A}
                <Text className="start-container__text--red">{TEST_INF_PART4}</Text>
              </Paragraph>
            )}
            {generatedTest?.testKind === POSSIBILITY_TO_RETURN && <Paragraph>{TEST_INF_PART5}</Paragraph>}
            <Paragraph>{TEST_INF_PART6}</Paragraph>
            <Paragraph>{TEST_INF_PART7}</Paragraph>
            <Paragraph>{generatedTest.testKind === POSSIBILITY_TO_RETURN ? TEST_INF_PART8 : TEST_INF_PART8A}</Paragraph>
          </Col>
        </Col>
        <Row align="bottom" justify="space-between" className="start-container__info">
          <Col className="start-container__info--column">
            <Paragraph>
              {TEST_INF_PART11}
              <Text className="start-container__text">
                <CustomIcon className="start-container__icon" component={TimerIcon} />
                <Text className="start-container__text--green">
                  {minutes} {MIN} {seconds} {SEC}
                </Text>
              </Text>
            </Paragraph>
            <Paragraph>
              {TEST_INF_PART12}
              <Text className="start-container__text">
                {subjects?.map((subject, index, array) => `${subject}${index === array.length - 1 ? '' : ', '}`)}
              </Text>
            </Paragraph>
          </Col>
          <Col>
            <Col>
              <CustomButton onClick={updateTestData} disabled={expireTime <= 0} className="custom-button--submit-start">
                {TEST_INF_PART14}
              </CustomButton>
            </Col>
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

TestStart.propTypes = {
  setStartTest: func,
  subjects: array,
  generatedId: string,
  generatedTest: object,
  isTestStarted: bool,
  isTestCompleted: func,
  isTestFinished: bool,
  testTitle: string,
};
export default TestStart;
