import { Form } from 'antd/index';
import { bool, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTitleAction,
  setSubjectsAction,
  setChoosedQuestionsAction,
  setFileLinkAction,
  setTestKindTagsAction,
  setTestTimeAction,
  setContestDescription,
} from 'views/ManageTest/ManageTestSlice';
import { useHistory } from 'react-router';
import handleRedirect from 'utils/handleRedirect';
import AddTestModalUI from './AddNewTestUI';
import getCollectionDocumentWithWhere from 'utils/collectionDocumentCRUD/getCollectionDocumentWithWhere';
import { testsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { infoMessage } from 'utils/infoMessage/infoMessage';
import { useEffect } from 'react';

const { TEST_WITH_TITLE_EXIST_MESSAGE } = infoMessage;

const AddNewTestContainer = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const subjects = useSelector(state => state.questions.subjects);
  const { hideModalTest, isModalVisible, record } = props;

  useEffect(() => {
    handleDispatchValue(dispatch, setContestDescription, '');
    handleDispatchValue(dispatch, setChoosedQuestionsAction, []);
    handleDispatchValue(dispatch, setFileLinkAction, '');
    handleDispatchValue(dispatch, setTestKindTagsAction, record?.testKind ? record?.testKind : []);
    handleDispatchValue(dispatch, setTestTimeAction, record?.testTime ? record?.testTime : 0);
  }, []);

  const handleOnFinish = async ({ title, subjects }) => {
    const tests = await getCollectionDocumentWithWhere(testsCollectionRef, {
      fieldPath: 'title',
      opStr: '==',
      status: title.trim(),
    });
    const isTestWithTitle = tests.length;
    if (isTestWithTitle) {
      showInfoMessage('error', TEST_WITH_TITLE_EXIST_MESSAGE);
    } else {
      let titleToSet = title;
      if (record?.title && !title) {
        titleToSet = record?.title;
      }
      handleDispatchValue(dispatch, setTitleAction, titleToSet);
      handleDispatchValue(dispatch, setSubjectsAction, record?.subjects ? record?.subjects : subjects);
      record?.questions && handleDispatchValue(dispatch, setChoosedQuestionsAction, record?.questions);
      record?.testKind && handleDispatchValue(dispatch, setTestKindTagsAction, record?.testKind);
      handleRedirect(history, '/tests/add-test');
    }
  };

  const subjectOptions = subjects
    ?.map(subject => {
      return { label: subject, value: subject };
    })
    .sort((a, b) => (a.label > b.label ? 1 : -1));
  const [form] = Form.useForm();

  return (
    <AddTestModalUI
      handleOnFinish={handleOnFinish}
      subjectOptions={subjectOptions}
      hideModalTest={hideModalTest}
      isModalVisible={isModalVisible}
      record={record}
      form={form}
    />
  );
};
AddNewTestContainer.propTypes = {
  hideModalTest: func,
  isModalVisible: bool,
  record: object,
};

export default AddNewTestContainer;
