import React, { useState } from 'react';
import { Col, Row, Spin } from 'antd/index';
import { array, bool, func, number, string, oneOfType } from 'prop-types';
import questionsListTableShape from 'views/TableShapes/questionsListTableShape';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import { sortArray } from 'utils/sortArray';
import { sortArrayFullName } from 'utils/sortArrayFullName';
import DeleteModal from 'components/DeleteModal/DeleteModal';

const QuestionsUI = ({
  levelFilterOption,
  levelFilter,
  handleOnClickAddNew,
  quizzes,
  setNumberOfItemsQuestionsPage,
  numberOfItemsQuestionsPage,
  handleOnEdit,
  handleOnDelete,
  role,
  onlevelFilterChange,
  onCategoryOptionsChange,
  setSearchFilter,
  filterTable,
  isQuestionsReadyToRender,
  currentPage,
  setCurrentPage,
  levels,
  handleClearFilters,
  categoryOptions,
  searchFilter,
  showModal,
}) => {
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idClicked, setIdClicked] = useState(0);
  const componentName = 'questions';
  const columnsHandlers = {
    sortArray,
    sortArrayFullName,
    handleOnEdit,
    role,
    quizzes,
    showModal,
    setIdClicked: setIdClicked,
    setIsModalDeleteVisible: setIsModalDeleteVisible,
  };

  const pagination = {
    defaultPageSize: numberOfItemsQuestionsPage,
    pageSizeOptions: [7, 14, 21],
    position: ['bottomCenter'],
    defaultCurrent: currentPage,
    onChange: current => setCurrentPage(current),
    showSizeChanger: true,
    locale: { items_per_page: '/ page' },
    onShowSizeChange: (_, size) => setNumberOfItemsQuestionsPage(size),
  };

  return (
    <Row>
      <DeleteModal
        isModalDeleteVisible={isModalDeleteVisible}
        setIsModalDeleteVisible={setIsModalDeleteVisible}
        deleteFunc={handleOnDelete}
        id={idClicked}
      />
      <Col md={24}>
        {isQuestionsReadyToRender ? (
          <Row justify="start" align="middle" className="container-row">
            <Col md={24} className="container-row__col">
              <CustomTableContainer
                classNameTable={'question-table'}
                columnsHandlers={columnsHandlers}
                setSearchFilter={setSearchFilter}
                numberOfItemsQuestionsPage={numberOfItemsQuestionsPage}
                currentPage={currentPage}
                handleClearFilters={handleClearFilters}
                levels={levels}
                handleOnClickAddNew={handleOnClickAddNew}
                onlevelFilterChange={onlevelFilterChange}
                categoryOptions={categoryOptions}
                levelFilter={levelFilter}
                levelFilterOption={levelFilterOption}
                setCurrentPage={setCurrentPage}
                onCategoryOptionsChange={onCategoryOptionsChange}
                searchFilter={searchFilter}
                setNumberOfItemsQuestionsPage={setNumberOfItemsQuestionsPage}
                dataToRender={filterTable}
                componentName={componentName}
                tableClass={'question-table'}
                tableShape={questionsListTableShape}
                locale={{ emptyText: 'No questions' }}
                pagination={pagination}
              />
            </Col>
          </Row>
        ) : (
          <Row justify="start" align="middle" className="spinner-container">
            <Spin size="large" />
          </Row>
        )}
      </Col>
    </Row>
  );
};

QuestionsUI.propTypes = {
  resetFilter: func,
  filterTable: array,
  setNumberOfItemsQuestionsPage: func,
  numberOfItemsQuestionsPage: number,
  role: string,
  onlevelFilterChange: func,
  levelFilterOption: oneOfType([string, array]),
  handleOnDelete: func,
  setSearchFilter: func,
  sortArray: func,
  sortArrayFullName: func,
  handleOnClickAddNew: func,
  quizzes: array,
  handleOnEdit: func,
  levelFilter: oneOfType([string, array]),
  setFilteredQuestions: func,
  filteredQuestions: array,
  questionsToRender: array,
  onCategoryOptionsChange: func,
  isQuestionsReadyToRender: bool,
  currentPage: number,
  setCurrentPage: func,
  levels: array,
  subjects: array,
  areResultsFiltered: bool,
  handleClearFilters: func,
  categoryOptions: array,
  searchFilter: string,
  showModal: func,
};

export default QuestionsUI;
