import React from 'react';
import { Row, Col, Paragraph, Image } from 'antd/index';
import { string } from 'prop-types';
import briteWinLogo from 'assets/BriteWinLogo.svg';
import CustomButton from 'components/CustomButton/CustomButton';
import { FINISH_TEST } from '../../constants';
import { useHistory } from 'react-router-dom';

const { COMPLETED_TEST_INF, CONTEST_CONTACT, GO_TO_CONTEST } = FINISH_TEST;

const FinishTestWithNoResults = ({ testTitle }) => {
  const history = useHistory();
  const handleButtonRedirection = () => {
    history.push('/initial');
  };
  return (
    <Row className="finish-background finish-container" align="middle" justify="center">
      <Col justify="center" align="middle" className="finish-container__col finish-container__col--no-result">
        <Paragraph className="header-logo">
          <Image src={briteWinLogo} preview={false} height={'2.9rem'} />
        </Paragraph>

        <Paragraph className="finish-text ">{COMPLETED_TEST_INF}</Paragraph>
        <Paragraph className="finish-text finish-text--lower">{testTitle}</Paragraph>

        <Paragraph className="finish-text finish-text--info">{CONTEST_CONTACT}</Paragraph>
        <CustomButton onClick={handleButtonRedirection} type="text" className="custom-button--finish-info">
          {GO_TO_CONTEST}
        </CustomButton>
      </Col>
    </Row>
  );
};
export default FinishTestWithNoResults;
FinishTestWithNoResults.propTypes = {
  testTitle: string,
};
