const getCollectionDocumentWithWhere = async (collectionRef, where) => {
  let response;

  if (where) {
    const { fieldPath, opStr, status } = where;
    response = await collectionRef.where(fieldPath, opStr, status).get();
  } else {
    response = await collectionRef.get();
  }

  return response.docs.map(document => document.data());
};
export default getCollectionDocumentWithWhere;
