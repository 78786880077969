import React, { useEffect, useState } from 'react';
import { Routes } from './Routes/Routes';
import { BrowserRouter as Routing } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './styles/main.scss';
import './scripts/CodeMirror';
import AuthProvider from './Auth';
import { setNumberOfItems } from 'utils/setNumberOfItems';
import { setLanguages, setLevels, setSubjects } from './views/Questions/QuestionsSlice';
import { setAllRoles, setPermissionsRoles } from 'views/Settings/SettingsSlice/SettingsSlice';
import { questionOptionsCollectionRef, rolesCollectionRef } from 'services/firestore/references';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';

const handleAsync = async () => {
  const questionOptions = await getAllDocumentsFromCollection(questionOptionsCollectionRef);
  const roles = await getAllDocumentsFromCollection(rolesCollectionRef);
  // need to change structrue of questionOptions collection
  return {
    languages: questionOptions[0].languages,
    levels: questionOptions[1].levels,
    subjects: questionOptions[2].subjects,
    roles,
  };
};

const App = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(state => state.auth.currentUser);
  const [roles, setLocalRoles] = useState([]);
  const [languages, setLocalLanguages] = useState([]);
  const [subjects, setLocalSubjects] = useState([]);
  const [levels, setLocalLevels] = useState([]);
  const userRole = useSelector(state => state?.auth?.currentUser?.role);
  const userRolePermissionsFilter = roles?.filter(role => role.roleName === userRole);
  const userRolePermissions = userRolePermissionsFilter[0];

  useEffect(async () => {
    if (!currentUser) {
      return;
    }
    const { languages, subjects, levels, roles } = await handleAsync();
    setLocalRoles(roles);
    setLocalLanguages(languages);
    setLocalSubjects(subjects);
    setLocalLevels(levels);
    setNumberOfItems('numberOfItemsQuestionsPage', '7');
    setNumberOfItems('numberOfItemsResultsPage', '7');
    setNumberOfItems('numberOfItemsTestsPage', '7');
  }, [currentUser]);

  useEffect(() => {
    if (subjects && levels && languages) {
      dispatch(setSubjects(subjects));
      dispatch(setLevels(levels));
      dispatch(setLanguages(languages));
    }
  }, [subjects, levels, languages]);
  useEffect(() => {
    dispatch(setAllRoles(roles));
    dispatch(setPermissionsRoles(userRolePermissions));
  }, [roles, userRolePermissions]);

  return (
    <Routing>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </Routing>
  );
};

export default App;
