/* eslint react/prop-types: 0 */

import { Item, CustomIcon } from 'antd/index';

import { PrismAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import MDEditor, { commands } from '@uiw/react-md-editor';
import remarkBreaks from 'remark-breaks';
import { MANAGE_QUESTION_FORM_ITEM_NAMES, QUESTION_TYPES, BB_CODE_EDITOR } from 'constants/index';
import { useEffect, useState } from 'react';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { questionOptionsCollectionRef } from 'services/firestore/references';

const { OPEN, CLOSED } = QUESTION_TYPES;
const { CODE } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { QUESTION, OPEN_CODE, ANSWER } = BB_CODE_EDITOR;
const { INSTRUCTIONS } = MANAGE_QUESTION_FORM_ITEM_NAMES;

const MyIcon = () => {
  return (
    <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.373829 5.8418C-0.0687829 6.73656 -0.0687827 7.79085 0.373829 8.68562C1.9386 11.8489 5.21319 14.026 8.9994 14.026C12.7856 14.026 16.0602 11.8489 17.625 8.68562C18.0676 7.79086 18.0676 6.73657 17.625 5.8418C16.0602 2.67852 12.7856 0.501465 8.9994 0.501465C5.21319 0.501465 1.9386 2.67852 0.373829 5.8418ZM1.96949 6.23931C1.61921 6.87655 1.61921 7.65008 1.96949 8.28732C3.32772 10.7583 5.96653 12.4344 8.99924 12.4344C12.032 12.4344 14.6708 10.7583 16.029 8.28733C16.3793 7.65009 16.3793 6.87655 16.029 6.23931C14.6708 3.76833 12.032 2.09221 8.99924 2.09221C5.96653 2.09221 3.32772 3.76832 1.96949 6.23931Z"
        fill="#A5A5A5"
      />
      <ellipse cx="9.01291" cy="7.26424" rx="2.80172" ry="2.7845" fill="#A5A5A5" />
    </svg>
  );
};

const EditIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.06975 11.3798L14.3751 6.08331C14.7753 5.68213 15 5.1386 15 4.57193C15 4.00526 14.7753 3.46173 14.3751 3.06055L12.9841 1.62496C12.5829 1.22475 12.0394 1 11.4727 1C10.9061 1 10.3626 1.22475 9.9614 1.62496L1.72258 9.8194C1.4886 10.0529 1.30406 10.3312 1.17997 10.6376C1.05588 10.944 0.994792 11.2722 1.00035 11.6027V13.6625C1.00035 14.0172 1.14126 14.3574 1.39208 14.6083C1.64291 14.8591 1.9831 15 2.33782 15H4.56694C4.84566 14.9993 5.12135 14.9421 5.3773 14.8317C5.63325 14.7213 5.86414 14.5602 6.05599 14.358V14.358C6.16298 14.2599 6.57314 13.8319 6.61772 13.7784C6.7926 13.6014 6.89718 13.3669 6.91197 13.1186C6.91703 12.9365 6.86623 12.7573 6.76638 12.6049C6.66653 12.4526 6.52242 12.3345 6.35345 12.2665C6.18448 12.1986 5.99875 12.184 5.82123 12.2247C5.64371 12.2654 5.48293 12.3596 5.3605 12.4944V12.4944L5.10192 12.753C4.80653 13.0491 4.40565 13.2159 3.98737 13.2167H2.91739C2.88192 13.2167 2.8479 13.2026 2.82282 13.1775C2.79773 13.1524 2.78364 13.1184 2.78364 13.0829V11.9505C2.78484 11.5699 2.93531 11.2049 3.20272 10.934L8.97167 5.19164C9.04516 5.11867 9.14452 5.07773 9.24808 5.07773C9.35164 5.07773 9.451 5.11867 9.52449 5.19164L10.8085 6.47564C10.8814 6.54914 10.9224 6.6485 10.9224 6.75206C10.9224 6.85562 10.8814 6.95498 10.8085 7.02848L7.76795 10.0869C7.5965 10.2595 7.50065 10.4932 7.50149 10.7365C7.50232 10.9798 7.59978 11.2128 7.77241 11.3843C7.94504 11.5557 8.1787 11.6516 8.422 11.6507C8.6653 11.6499 8.89831 11.5525 9.06975 11.3798V11.3798ZM10.7461 3.87197C10.6859 3.80622 10.6525 3.72033 10.6525 3.63122C10.6525 3.5421 10.6859 3.45622 10.7461 3.39047L11.2365 2.90005C11.3022 2.8399 11.3881 2.80654 11.4772 2.80654C11.5663 2.80654 11.6522 2.8399 11.7179 2.90005L13.1 4.29997C13.1601 4.36572 13.1935 4.45161 13.1935 4.54072C13.1935 4.62983 13.1601 4.71572 13.1 4.78147L12.5918 5.25406C12.526 5.31421 12.4401 5.34757 12.351 5.34757C12.2619 5.34757 12.176 5.31421 12.1103 5.25406L10.7461 3.87197Z"
        fill="#A5A5A5"
      />
      <path
        d="M14.1026 13H8.89744C8.65942 13 8.43115 13.1054 8.26285 13.2929C8.09455 13.4804 8 13.7348 8 14C8 14.2652 8.09455 14.5196 8.26285 14.7071C8.43115 14.8946 8.65942 15 8.89744 15H14.1026C14.3406 15 14.5688 14.8946 14.7371 14.7071C14.9054 14.5196 15 14.2652 15 14C15 13.7348 14.9054 13.4804 14.7371 13.2929C14.5688 13.1054 14.3406 13 14.1026 13Z"
        fill="#A5A5A5"
      />
    </svg>
  );
};

const customCodeCommand = {
  ...commands.code,
  execute: (state, api) => {
    let modifyText = `\`\`\`\n${state.selectedText}\n\`\`\``;

    if (!state.selectedText) {
      modifyText = '```\n```';
    }
    api.replaceSelection(modifyText);
  },
};
const customCommands = [commands.bold, commands.italic, commands.quote, customCodeCommand];
const customEditCommand = { ...commands.codeEdit, icon: <CustomIcon component={EditIcon} /> };
const customPreviewCommand = { ...commands.codePreview, icon: <CustomIcon component={MyIcon} /> };
const customExtraCommands = [customEditCommand, customPreviewCommand];

const getCode = () => {
  return {
    code({ children, ...props }) {
      return (
        <SyntaxHighlighter wrapLines wrapLongLines showLineNumbers style={vscDarkPlus} PreTag="div" {...props}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      );
    },
  };
};

const CustomBBCodeEditor = ({
  name,
  handleSetFieldsValue = () => {},
  handleSetAnswersState = () => {},
  type,
  letter,
  validationMessage,
  access = true,
  isHideToolbar = false,
  setEditorValue,
  subtype,
}) => {
  const [languages, setLanguages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const handleAsync = async () => {
        const { languages } = await getCollectionDocument(questionOptionsCollectionRef, 'languages');
        setLanguages(languages.map(language => ({ value: language, label: language })));
      };

      await handleAsync();
      setIsLoading(false);
    })();
  }, []);

  const handleClosedQuestionAnswer = (value, letter) => {
    handleSetAnswersState(value, letter);
  };

  const handleOpenQuestionAnswer = (value, name) => {
    handleSetFieldsValue(name, value);
    setEditorValue(prev => ({ ...prev, [name]: value }));
  };

  const handleOnChange = value => {
    type === CLOSED ? handleClosedQuestionAnswer(value, letter) : handleOpenQuestionAnswer(value, name);
  };

  return (
    !isLoading &&
    languages && (
      <Item
        name={name}
        validateTrigger="onSubmit"
        className="add-question-form__item add-question-form__item--bbcode "
        rules={[{ required: true, message: validationMessage, whitespace: true }]}
      >
        <MDEditor
          previewOptions={{ components: getCode() }}
          hideToolbar={isHideToolbar}
          aria-disabled={access}
          className="custom-mde-editor"
          preview="edit"
          onChange={handleOnChange}
          commands={subtype === CODE && type === OPEN ? [customCodeCommand] : [...customCommands]}
          extraCommands={[...customExtraCommands]}
          textareaProps={{
            maxLength: 10000,
            placeholder: name === INSTRUCTIONS ? QUESTION : ANSWER,
          }}
          remarkplugins={[remarkBreaks]}
        />
      </Item>
    )
  );
};

export default CustomBBCodeEditor;
