import React, { useEffect, useState } from 'react';
import { Content, Layout, Row, Text } from 'antd/index';
import PropTypes from 'prop-types';
import Sidebar from '../Sidebar/Sidebar';
import { messageCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { somethingWentWrong } = messages;

const LayoutUI = ({ children }) => {
  const [message, setMessage] = useState('');
  useEffect(() => {
    const messageRequestCleanup = messageCollectionRef.onSnapshot(
      snapshots => {
        snapshots.forEach(snap => {
          // setting it here cuz I know there will be always one document in collection
          const { message } = snap.data();
          setMessage(message);
        });
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );

    return () => {
      messageRequestCleanup();
    };
  }, []);
  return (
    <Layout className="layout">
      {message && <Text className="app-message">{message}</Text>}
      <Row className="row">
        <Sidebar className="sidebar" />
        <Content className="content-children">{children}</Content>
      </Row>
    </Layout>
  );
};
export default LayoutUI;

LayoutUI.propTypes = {
  children: PropTypes.node,
};
