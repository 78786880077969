import { StyleSheet, Font } from '@react-pdf/renderer';
import fontRegular from '../../fonts/SF-Pro-Display-Regular.otf';

Font.register({
  family: 'SF-Pro Display',
  src: fontRegular,
});

export const styles = StyleSheet.create({
  candidateDataContainer: {
    width: '100%',
    flexDirection: 'row',
  },
  candidateDataColumn: {
    flex: 50,
  },
  candidateDataColumnRight: {
    flex: 50,
    marginLeft: 20,
  },
  candidateDataHeader: {
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingHorizontal: 10,
    backgroundColor: '#f9f9f9',
  },
  candidateResultsColumn: {
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 10,
    paddingRight: 5,
    backgroundColor: '#f9f9f9',
  },
  candidateResultsHeaderScore: {
    flex: 20,
    borderLeft: '1px solid #bebebe',
    alignItems: 'center',
    height: 30,
    justifyContent: 'center',
  },
  candidateResultsHeaderPercentage: {
    flex: 20,
    borderLeft: '1px solid #bebebe',
    alignItems: 'center',
    height: 30,
    justifyContent: 'center',
  },
  candidateResultsName: {
    borderBottom: '1px solid #bebebe',
    borderLeft: '1px solid #bebebe',
    borderRight: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 10,
    paddingRight: 5,
    backgroundColor: '#ffffff',
  },
  candidateResultsPoints: {
    flex: 20,
    borderLeft: '1px solid #bebebe',
    alignItems: 'center',
    height: 30,
    justifyContent: 'center',
  },
  candidateDataHeaderContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  candidateDataRow: {
    borderRight: '1px solid #bebebe',
    borderLeft: '1px solid #bebebe',
    borderBottom: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingVertical: 5,
    paddingHorizontal: 10,
    backgroundColor: '#ffffff',
  },
  candidateRowContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  textStyled: {
    fontFamily: 'SF-Pro Display',
    fontSize: 10,
  },

  resultText: {
    fontSize: 12,
  },

  textStyledHeader: {
    fontFamily: 'SF-Pro Display',
    fontSize: 10,
    fontWeight: '500',
  },
  textStyled__grey: {
    fontFamily: 'SF-Pro Display',
    fontSize: 10,
    color: '#bebebe',
  },

  headerRowWrapper: {
    border: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 10,
    backgroundColor: '#f9f9f9',
  },

  headerRowContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  headerColumn: {
    justifyContent: 'center',
    borderLeft: '1px solid #bebebe',
    height: 30,
    alignItems: 'center',
  },

  answerRowWrapper: {
    borderBottom: '1px solid #bebebe',
    borderLeft: '1px solid #bebebe',
    borderRight: '1px solid #bebebe',
    justifyContent: 'center',
    height: 30,
    paddingLeft: 9,
    paddingRight: 0,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: '#ffffff',
  },

  answerRowContent: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  answerColumn: {
    borderRight: '1px solid #bebebe',
    height: 30,
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },

  page: {
    fontFamily: 'SF-Pro Display',
    backgroundColor: 'white',
    fontSize: 14,
    padding: 30,
  },
});

export const checkCorrectTextColor = percentage => {
  if (percentage < 30) {
    return {
      color: '#f34161',
      fontSize: 12,
    };
  }
  if (percentage >= 30 && percentage < 70) {
    return {
      color: '#e78f2d',
      fontSize: 12,
    };
  } else {
    return {
      color: '#0acf83',
      fontSize: 12,
    };
  }
};

export const checkCorrectBackground = correct => {
  if (correct === null) {
    return {
      backgroundColor: '#a3a9b7',
    };
  }
  if (correct) {
    return {
      backgroundColor: '#0acf83',
    };
  } else {
    return {
      backgroundColor: '#f34161',
    };
  }
};
