import testsCollectionRef from 'services/firestore/references/testsCollectionRef';
import { createSlice } from '@reduxjs/toolkit';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import getStorageFileLink from 'views/ManageQuestion/utils/getStorageFileLink';
import { STORAGE_PATH, METADATA } from 'constants/index';

const { testUpdated, testCreated } = messages;
export const ManageTestSlice = createSlice({
  name: 'manageTest',
  initialState: {
    title: '',
    subjects: [],
    testTime: 0,
    testKind: '',
    questions: [],
    fileLink: '',
    hiddenFor: false,
    loading: false,
    hasErrors: false,
    contestDescription: '',
  },
  reducers: {
    setHasErrors(state, { payload }) {
      state.hasErrors = payload;
    },
    setTitleAction(state, { payload }) {
      state.title = payload;
    },
    setSubjectsAction(state, { payload }) {
      state.subjects = payload;
    },
    setTestTimeAction(state, { payload }) {
      state.testTime = payload;
    },
    setChoosedQuestionsAction(state, { payload }) {
      state.questions = payload;
    },
    setContestDescription(state, { payload }) {
      state.contestDescription = payload;
    },
    setFileLinkAction(state, { payload }) {
      state.fileLink = payload;
    },
    setTestKindTagsAction(state, { payload }) {
      state.testKind = payload;
    },
    getTestData: state => {
      state.loading = true;
    },
    getTestDataSuccess: (state, { payload }) => {
      const { title, subjects, testTime, testKind, questions, fileLink, hiddenFor, contestDescription } = payload;
      state.title = title;
      state.subjects = subjects;
      state.testTime = testTime;
      state.testKind = testKind;
      state.questions = questions;
      state.fileLink = fileLink;
      state.hiddenFor = hiddenFor;
      state.loading = false;
      state.hasErrors = false;
      state.contestDescription = contestDescription;
    },
    postTestDataSuccess: (state, { payload }) => {
      state.loading = payload;
      state.hasErrors = payload;
    },
    getTestDataFailure: state => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

const updateTestInDatabase = async (
  id,
  file,
  { questions, testTime, testKind, title, contestDescription },
  history,
) => {
  let link;
  if (!(file === 'string')) {
    link = await getStorageFileLink(id, file, METADATA.PDF, id, STORAGE_PATH.CONTESTS);
  } else {
    link = file;
  }

  testsCollectionRef
    .doc(id)
    .update({
      id,
      questions,
      fileLink: link,
      testTime,
      testKind,
      title,
      contestDescription,
    })
    .then(() => {
      showInfoMessage('success', testUpdated);
      history.push('/tests');
    });
};

const addTestToDatabase = async (
  file,
  { title, subjects, questions, hiddenFor, testTime, testKind, contestDescription },
  history,
) => {
  const creationDate = new Date();
  const { id } = testsCollectionRef.doc();
  const link = await getStorageFileLink(id, file, METADATA.PDF, id, STORAGE_PATH.CONTESTS);

  await testsCollectionRef
    .doc(id)
    .set({
      id,
      title,
      creationDate,
      subjects,
      questions,
      fileLink: link,
      hiddenFor,
      testTime,
      testKind,
      contestDescription,
    })
    .then(() => {
      showInfoMessage('success', `${testCreated} ${title}`);
      history.push('/tests');
    });
};

export const fetchTestData = id => {
  return async dispatch => {
    dispatch(getTestData());
    try {
      const data = await getCollectionDocument(testsCollectionRef, id);
      dispatch(getTestDataSuccess(data));
    } catch (error) {
      dispatch(getTestDataFailure());
    }
  };
};

export const updateOrAddTestData = (id, file, state, history) => {
  return async dispatch => {
    dispatch(getTestData());
    try {
      if (id) {
        await updateTestInDatabase(id, state.fileLink ? state.fileLink : file, state, history);
      } else {
        await addTestToDatabase(file, state, history);
      }
      dispatch(postTestDataSuccess(false));
    } catch (error) {
      dispatch(getTestDataFailure());
    }
  };
};

const { actions, reducer } = ManageTestSlice;
export const {
  getTestData,
  getTestDataSuccess,
  getTestDataFailure,
  setTitleAction,
  setTestKindTagsAction,
  setSubjectsAction,
  setTestTimeAction,
  setChoosedQuestionsAction,
  setFileLinkAction,
  setContestDescription,
  setHasErrors,
  postTestDataSuccess,
} = actions;
export default reducer;
