/* eslint react/prop-types: 0 */

import { Button, CustomIcon, Upload } from 'antd/index';
import { MANAGE_QUESTION_FORM_ITEM_NAMES, QUESTION_SUBTYPES, MANAGE_QUESTION } from 'constants/index';
import { useEffect, useState } from 'react';

const { INSTRUCTIONS, QUESTION_IMAGE, CONTENT_IMAGE } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { CODE } = QUESTION_SUBTYPES;
const { PHOTO_TO, QUESTIONS, ANSWERS, ADD_PHOTO } = MANAGE_QUESTION;

const PlusIcon = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 0C5.44772 0 5 0.447715 5 1V5H1C0.447715 5 0 5.44771 0 6C0 6.55228 0.447715 7 1 7H5V11C5 11.5523 5.44772 12 6 12C6.55228 12 7 11.5523 7 11V7H11C11.5523 7 12 6.55228 12 6C12 5.44772 11.5523 5 11 5H7V1C7 0.447715 6.55228 0 6 0Z"
        fill="#3D6FF8"
      />
    </svg>
  );
};

const ImageUpload = ({ name, letter, setAnswers, handleSetFieldsValue, link, action }) => {
  const fileInitialState = {
    uid: name,
    name: `${PHOTO_TO} ${name === INSTRUCTIONS ? `${QUESTIONS}` : `${ANSWERS} ${letter || ''}`} `,
    status: 'done',
    thumbUrl: link,
  };

  useEffect(() => {
    if (name === INSTRUCTIONS) {
      handleSetFieldsValue(QUESTION_IMAGE, link || null);
    } else if (name === CODE) {
      handleSetFieldsValue(CONTENT_IMAGE, link || null);
    }
  }, []);

  const [state, setState] = useState(action === 'edit' && link ? [fileInitialState] : []);

  const handleOnChange = e => {
    setState(e.fileList);
    if (name === INSTRUCTIONS) {
      handleSetFieldsValue(QUESTION_IMAGE, e.file.originFileObj || null);
    } else if (name === CODE) {
      handleSetFieldsValue(CONTENT_IMAGE, e.file.originFileObj || null);
    } else {
      setAnswers(prevAnswersState =>
        prevAnswersState.map(answer =>
          answer.name === name ? { ...answer, link: e.file.originFileObj || null } : answer,
        ),
      );
    }
  };

  const handleOnRemove = () => {
    setState([]);
    if (name === INSTRUCTIONS) {
      handleSetFieldsValue(QUESTION_IMAGE, null);
    } else if (name === CODE) {
      handleSetFieldsValue(CONTENT_IMAGE, null);
    }
  };

  return (
    <>
      {state != [] ? (
        <Upload
          name={name}
          onRemove={e => handleOnRemove(e)}
          fileList={state}
          onChange={handleOnChange}
          maxCount={1}
          listType="picture"
          accept=".jpg, .jpeg, .png"
        >
          <Button className="add-image-button">
            <CustomIcon component={PlusIcon} />
            {`${ADD_PHOTO} ${name === INSTRUCTIONS ? `${QUESTIONS}` : `${ANSWERS}`}`}
          </Button>
        </Upload>
      ) : null}
    </>
  );
};

export default ImageUpload;
