import { v4 as uuidv4 } from 'uuid';
import ListFinishedTestsContainer from '../../views/ListFinishedTestsView/ListFinishedTestsContainer';

export const HOME_ROUTES = [
  {
    path: '/',
    component: ListFinishedTestsContainer,
    key: uuidv4(),
  },
];
