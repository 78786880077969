import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import TestResultUI from './TestResultUI';
import { fetchTestData, getMemoizedTestData } from '../TestQuestionsWrapper/TestSlice';
import redirectToCompanyPage from 'utils/redirectToCompanyPage';

const TestResult = () => {
  const { generatedId } = useParams();
  const dispatch = useDispatch();
  const { test, loading, hasErrors } = useSelector(getMemoizedTestData);
  const { numSubmitedCorrectClosedQuestion, numOfClosedQuestions } = test;

  useEffect(() => {
    dispatch(fetchTestData(generatedId));
  }, [dispatch]);

  return (
    <TestResultUI
      error={hasErrors}
      loading={loading}
      redirectToCompanyPage={redirectToCompanyPage}
      numSubmitedCorrectClosedQuestion={numSubmitedCorrectClosedQuestion}
      numOfClosedQuestions={numOfClosedQuestions}
    />
  );
};

export default TestResult;
