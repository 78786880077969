import { Row, Col, Spin } from 'antd/index';

const Loader = () => {
  return (
    <Row className="spinner-container">
      <Col md={24}>
        <Row className="spinner-container__internal-row" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Col>
    </Row>
  );
};

export default Loader;
