import { mergeArrays } from '../mergeArrays';

export const itemsFilter = (values, array, property) => {
  if (!values.length) {
    return array;
  } else {
    return array.reduce((acc, item) => {
      if (!Array.isArray(item[property])) {
        if (values.includes(item[property])) {
          return [...acc, item];
        }
      } else if (values.some(value => item[property].includes(value))) {
        return [...acc, item];
      }
      return acc;
    }, []);
  }
};

export const searchFilter = (value, array, property) => {
  if (value.trim().length === 0) {
    return array;
  }
  const text = value.toLowerCase();
  return array?.filter(item => {
    if (item[property]) {
      return item[property].toLowerCase().includes(text) ? item : null;
    }
  });
};

export const existingItemsFilter = (value, array, property) =>
  value ? array?.filter(item => item[property].length > 0) : array;

export const rangeFilter = (value, array = [], property = '') => {
  if (!value) {
    return array;
  }
  return array?.filter(item => item[property] > value[0] && item[property] < value[1]);
};

export const filter = (value, array, properties, func) => {
  let multipleArrays = [];
  if (typeof properties === 'string') {
    return func(value, array, properties);
  } else {
    properties.map(property => {
      multipleArrays = [...multipleArrays, func(value, array, property)];
    });
    return mergeArrays(...multipleArrays);
  }
};
