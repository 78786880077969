import React from 'react';
import { Button, Popconfirm, Text, Paragraph } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import classNames from 'classnames';
import { formatDate } from 'utils/formatDate';
import CustomButton from 'components/CustomButton/CustomButton';
import { TABLE_SHAPES_VIEW, BUTTON_TEXTS } from '../../constants';
import { sortArrayFullName } from 'utils/sortArrayFullName';

const { TEST, CANDIDATE, STATUS, COMPLETED, RESULTS, MAKE_DECISION, YES, NO } = TABLE_SHAPES_VIEW;
const { PREVIEW } = BUTTON_TEXTS;

const tableShape = ({
  handleOnClick,
  sortArray,
  isAdmin,
  handleHideTest,
  isRoot,
  setIsModalDeleteVisible,
  setIdClicked,
}) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--first">{TEST}</Paragraph>;
      },
      dataIndex: 'title',
      className: 'with-sorter',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
      render: function renderSubjects(_, { title, hiddenFor }) {
        return (
          <Paragraph
            className={`first-text 
        ${(isAdmin || isRoot) && !!hiddenFor && 'color-hidden-text'}`}
          >
            {title}
          </Paragraph>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{CANDIDATE}</Paragraph>;
      },
      dataIndex: 'recruitedPerson',
      key: 'recruitedPerson',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArrayFullName(a, b, 'recruitedPerson'),
      render: function renderCustomRecord(record) {
        return <Text className="record-custom-font">{record}</Text>;
      },
      align: 'left',
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{STATUS}</Paragraph>;
      },
      dataIndex: 'status',
      key: 'status',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'status'),
      render: function renderCustomRecord(record) {
        return <Text className="record-custom-font">{record}</Text>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{COMPLETED}</Paragraph>;
      },
      dataIndex: 'completionDate',
      key: 'completionDate',
      align: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'completionDate'),
      render: function renderCustomRecord(record) {
        return (
          <Text className="record-custom-font">
            {typeof record === 'string' ? record : formatDate(record, 'DD.MM.YYYY')}
          </Text>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{RESULTS}</Paragraph>;
      },
      dataIndex: 'result',
      key: 'result',
      align: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => a.result.percent - b.result.percent,
      render: function renderCustomRecord({ percent, rate }) {
        return (
          <>
            <Text
              className={classNames({
                'record-custom-font': true,
                'result-red': percent < 30,
                'result-orange': percent >= 30 && percent < 70,
                'result-green': percent >= 70,
              })}
            >
              {`${rate} | ${percent}%`}
            </Text>
          </>
        );
      },
    },
    {
      dataIndex: 'result',
      key: 'result',
      align: 'center',
      width: 130,
      render: function renderCustomRecord(record, { id }) {
        return (
          <CustomButton
            className="button custom-button--preview"
            onClick={() => {
              handleOnClick(id, record);
            }}
            data-id={id}
            type="primary"
          >
            {PREVIEW}
          </CustomButton>
        );
      },
    },
  ];
  const deleteButton = {
    align: 'left',
    key: 'delete',
    width: 80,
    render: function renderDelete(_, { id }) {
      return (
        <Button
          onClick={() => {
            setIsModalDeleteVisible(true);
            setIdClicked(id);
          }}
          className="button-delete"
          type="text"
          title="Delete"
          icon={<DeleteIcon classes={'delete-icon'} />}
        />
      );
    },
  };

  const hideRecordColumn = {
    align: 'right',
    key: 'hide',
    width: 60,
    render: function renderHide(record) {
      return (
        <Popconfirm
          title={MAKE_DECISION}
          onConfirm={() => {
            handleHideTest(record);
          }}
          okText={YES}
          cancelText={NO}
        >
          <Button className="button-delete" type="text" icon={<DeleteIcon classes={'delete-icon'} />} />
        </Popconfirm>
      );
    },
  };

  return [...columns, deleteButton];
};
export default tableShape;
