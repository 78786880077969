import * as React from 'react';

interface ICorrectIcon {
  width?: string;
  height?: string;
}

const CorrectIcon = ({ width = '20', height = '20' }: ICorrectIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width={width} height={height}>
      <g id="Warstwa_2" data-name="Warstwa 2">
        <g id="Warstwa_1-2" data-name="Warstwa 1">
          <rect fill="#1dd78f" width="20" height="20" rx="4" />
          <path
            fill="#2d2d2d"
            d="M8.54,11.83l4.24-4.24a1,1,0,0,1,1.41,0h0a1,1,0,0,1,0,1.41L10,13.24a1,1,0,0,1-1.41,0h0A1,1,0,0,1,8.54,11.83Z"
          />
          <path
            fill="#2d2d2d"
            d="M8.54,13.24,5.71,10.41A1,1,0,0,1,5.71,9h0A1,1,0,0,1,7.12,9L10,11.83a1,1,0,0,1,0,1.41h0A1,1,0,0,1,8.54,13.24Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default CorrectIcon;
