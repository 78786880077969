import SettingsContainer from 'views/Settings/SettingsContainer';
import { v4 as uuidv4 } from 'uuid';

export const SETTINGS_ROUTES = [
  {
    path: '/settings',
    component: SettingsContainer,
    key: uuidv4(),
  },
];
