import { useEffect, useState } from 'react';

export const useFetchAllDocumentsFromCollectionReload = (collectionRef, reloadData) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    const response = await collectionRef.get();
    const docArray = response.docs.map(doc => {
      const { id } = doc;
      return { id, ...doc.data() };
    });
    setData(docArray);
  };

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, [collectionRef, reloadData]);
  return { data, isLoading };
};
