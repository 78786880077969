/* eslint-disable react/prop-types */

import { FC } from 'react';
import { Row, Paragraph } from 'antd/index';
import CustomButton from 'components/CustomButton/CustomButton';
import { BUTTON_TEXTS, TEST_QUESTIONS_WRAPPER_VIEW } from '../../constants';

const { GO_NEXT, CANCEL } = BUTTON_TEXTS;
const { CONFIRMATION_MESSAGE_PART1, CONFIRMATION_MESSAGE_PART2 } = TEST_QUESTIONS_WRAPPER_VIEW;

interface IConfirmationProps {
  form: any;
  setIsConfirmationOpen: any;
}

const ShowConfirmationMessage: FC<IConfirmationProps> = ({ form, setIsConfirmationOpen }) => {
  const handleConfirm = () => {
    form.submit();
    setIsConfirmationOpen(false);
  };
  const handleClose = () => {
    setIsConfirmationOpen(false);
  };
  return (
    <Row className="confirmation">
      <Row className="confirmation__popup">
        <Paragraph className="confirmation__popup-info">
          {CONFIRMATION_MESSAGE_PART1}
          <br />
          <br />
          {CONFIRMATION_MESSAGE_PART2}
        </Paragraph>
        <CustomButton className="custom-button--resign" onClick={handleClose} disabled={false}>
          {CANCEL}
        </CustomButton>
        <CustomButton className="custom-button--submit" onClick={handleConfirm} disabled={false}>
          {GO_NEXT}
        </CustomButton>
      </Row>
    </Row>
  );
};

export default ShowConfirmationMessage;
