/* eslint-disable react/prop-types */
import { FC } from 'react';
import { IClosedQuestionContent } from '../QuestionsContentTypes';
import { QUESTION_CHOICE_TYPES } from 'constants/index';
import SingleQuestion from './SingleQuestion/SingleQuestion';
import MultiQuestion from './MultiQuestion/MultiQuestion';

const { MULTI } = QUESTION_CHOICE_TYPES;
const ClosedQuestionContent: FC<IClosedQuestionContent> = ({
  questionChoice,
  disableButtons,
  setFormState,
  answers,
  handleMultiCheckForm,
  questionStatus,
  questionId,
  form,
  possibilityChooseQuestions,
}) => {
  const content =
    questionChoice === MULTI ? (
      <MultiQuestion
        disableButtons={disableButtons}
        answers={answers}
        setFormState={setFormState}
        questionStatus={questionStatus}
        questionId={questionId}
        form={form}
        possibilityChooseQuestions={possibilityChooseQuestions}
      />
    ) : (
      <SingleQuestion
        disableButtons={disableButtons}
        answers={answers}
        handleMultiCheckForm={handleMultiCheckForm}
        questionStatus={questionStatus}
        questionId={questionId}
        form={form}
        possibilityChooseQuestions={possibilityChooseQuestions}
      />
    );
  return content;
};

export default ClosedQuestionContent;
