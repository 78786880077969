import { Row, Modal } from 'antd/index';
import React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { activeContestCollectionRef } from '../../services/firestore/references';
import { IActiveContestModal } from '../../types/interfaces';

const ActiveContestModal = ({
  activeContestId,
  isModalVisible,
  setIsModalVisible,
  selectedContestData,
}: IActiveContestModal) => {
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleToggleActive = async () => {
    await activeContestCollectionRef.doc('activeContest').set({
      contestId: activeContestId !== selectedContestData.id ? selectedContestData.id : '',
      contestTitle: activeContestId !== selectedContestData.id ? selectedContestData.title : '',
    });
    setIsModalVisible(false);
  };
  return (
    <Modal
      footer={null}
      title={`Are you sure you want to set "${selectedContestData.title}" contest as ${
        activeContestId !== selectedContestData.id ? 'ACTIVE' : 'INACTIVE'
      }?`}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="modal-active-contest"
      closable={false}
      centered
    >
      <Row className="action-container" justify="space-around">
        <CustomButton onClick={handleCancel} className="custom-button--resign">
          Cancel
        </CustomButton>
        <CustomButton onClick={handleToggleActive} className="custom-button--green">
          Yes
        </CustomButton>
      </Row>
    </Modal>
  );
};
export default ActiveContestModal;
