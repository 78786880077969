import QuestionsContainer from 'views/Questions/QuestionsContainer';
import ManageQuestion from 'views/ManageQuestion/ManageQuestion';
import { v4 as uuidv4 } from 'uuid';

export const QUESTIONS_ROUTES = [
  {
    path: '/questions',
    component: QuestionsContainer,
    key: uuidv4(),
  },
  {
    path: '/question/edit/:id',
    component: ManageQuestion,
    key: uuidv4(),
  },
  {
    path: '/question/add',
    component: ManageQuestion,
    key: uuidv4(),
  },
];
