import { TEST_STATUSES } from 'constants/index';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import handleRedirect from './handleRedirect';

const { EXPIRED, CREATED, IN_PROGRESS } = TEST_STATUSES;
const testNotFinishedStatuses = [CREATED, IN_PROGRESS];

const checkIfTestHasExpiredStatus = (status, expireTime, generatedId, history) => {
  const currentDate = new Date().getTime();
  const isTestNotFinished = currentDate >= expireTime && testNotFinishedStatuses.includes(status);

  if (isTestNotFinished) {
    updateCollectionDocument(generatedTestsCollectionRef, generatedId, { status: EXPIRED });
    history && handleRedirect(history, `/test-expired/${generatedId}`);
  }
};

export default checkIfTestHasExpiredStatus;
