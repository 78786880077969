/* eslint-disable react/prop-types */

import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'Auth';
import LayoutUI from 'components/Layout/Layout';
import { PATH_APP } from 'constants/index';

const { INITIAL } = PATH_APP;

const PrivateRoute = ({ component: C, ...props }) => {
  const { isAuthenticated } = useAuth();

  return (
    <Route
      {...props}
      render={routeProps =>
        isAuthenticated ? (
          <LayoutUI>
            <C {...routeProps} />
          </LayoutUI>
        ) : (
          <Redirect to={INITIAL} />
        )
      }
    />
  );
};

export default PrivateRoute;
