import { useEffect, useRef } from 'react';

/**
 * Gets previous props or state.
 *
 * [See React docs](https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state)
 */
const usePrevious = <T>(value: T) => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current!;
};

export default usePrevious;
