/* eslint react/prop-types: 0 */

import { Row, Input, Col, Paragraph, Item } from 'antd/index';
import FormSelect from 'components/FormSelect/FormSelect';
import CustomTimePicker from 'components/CustomTimePicker/CustomTimePicker';
import { useEffect, useState } from 'react';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';
import { questionOptionsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import handleRedirect from 'utils/handleRedirect';
import { useHistory } from 'react-router';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { MANAGE_QUESTION_FORM_ITEM_NAMES, MANAGE_QUESTION } from 'constants/index';

const { TITLE, LEVEL, SUBJECTS } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { somethingWentWrong } = messages;
const {
  QUESTION_TITLE,
  QUESTION_CATEGORY,
  SELECT_TOPIC,
  QUESTION_LEVEL,
  SELECT_LEVEL,
  TIME_FOR_REPLY,
  PARAGRAPH_TITLE,
} = MANAGE_QUESTION;

const InputSection = ({ form, handleGetFormFieldValue, wasFormSubmitted, handleSetFieldsValue, action }) => {
  const [levelOptions, setLevelOptions] = useState([]);
  const [subjectOptions, setSubjectOptions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const levelsPromise = getCollectionDocument(questionOptionsCollectionRef, 'levels');
        const subjectsPromise = getCollectionDocument(questionOptionsCollectionRef, 'subjects');
        const [{ levels }, { subjects }] = await Promise.all([levelsPromise, subjectsPromise]);
        setLevelOptions(levels);
        setSubjectOptions(subjects.sort());
      } catch (e) {
        showInfoMessage('error', somethingWentWrong);
        handleRedirect(history, '/questions');
      }
    })();
  }, []);

  return (
    <>
      <Item
        name={TITLE}
        rules={[{ required: true, message: `${QUESTION_TITLE}`, whitespace: true }]}
        className="add-question-form__item"
        label={<Paragraph className="add-question-form__label">{PARAGRAPH_TITLE}</Paragraph>}
      >
        <Input placeholder={QUESTION_TITLE} className="add-question-form__input sf-pro-text" />
      </Item>
      <Row gutter={[40, 0]}>
        <Col span={24}>
          <FormSelect
            handleGetFormFieldValue={handleGetFormFieldValue}
            handleSetFieldsValue={handleSetFieldsValue}
            message={QUESTION_CATEGORY}
            name={SUBJECTS}
            mode="multiple"
            options={subjectOptions}
            wasFormSubmitted={wasFormSubmitted}
            validationMessage={SELECT_TOPIC}
            action={action}
          />
        </Col>
      </Row>
      <Row gutter={[40, 0]}>
        <Col span={12}>
          <FormSelect
            handleGetFormFieldValue={handleGetFormFieldValue}
            handleSetFieldsValue={handleSetFieldsValue}
            message={QUESTION_LEVEL}
            name={LEVEL}
            mode={null}
            options={levelOptions}
            validationMessage={SELECT_LEVEL}
            wasFormSubmitted={wasFormSubmitted}
            action={action}
          />
        </Col>
        <Col span={12}>
          <CustomTimePicker message={TIME_FOR_REPLY} form={form} />
        </Col>
      </Row>
    </>
  );
};
export default InputSection;
