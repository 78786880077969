import QuestionsPanelUI from './QuestionsPanelUI';
import { useEffect, useState } from 'react';
import { updateOrAddTestData, setChoosedQuestionsAction, setFileLinkAction } from './ManageTestSlice';
import QuestionTimeForm from './QuestionTimeForm';
import { func } from 'prop-types';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { SCROLLING_TYPE, TEST_KIND_OPTIONS, FILE_EXTENSION, SECONDS_TO_MINUTES } from 'constants/index';
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionsTime } from 'utils/getQuestionsTime';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { handleDispatchValue } from 'utils/handleDispatchValue';

const { invalidFilePDF } = messages;
const { PREV, NEXT } = SCROLLING_TYPE;
const { NO_RETURN, NO_RETURN_ACCEPT, POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;

const QuestionsPanelContainer = ({ handleRemoveQuestionFromList, showModal }) => {
  const state = useSelector(state => state.managedTest);
  const [settingTimeActive, setSettingTimeActive] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [uploadedFile, setUploadedFile] = useState([]);
  const [suggestTimeInSeconds, setsuggestTimeInSeconds] = useState();
  const [isModalAcive, setIsModalActie] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const testKindOptions = [
    {
      value: NO_RETURN,
      label: NO_RETURN,
    },
    {
      value: NO_RETURN_ACCEPT,
      label: NO_RETURN_ACCEPT,
    },
    {
      value: POSSIBILITY_TO_RETURN,
      label: POSSIBILITY_TO_RETURN,
    },
  ];
  const { questions, testTime, fileLink } = state;
  const minutes = Math.floor(testTime / SECONDS_TO_MINUTES);

  useEffect(() => {
    setsuggestTimeInSeconds(getQuestionsTime(questions));
  }, [questions]);
  useEffect(() => {
    const { subjects } = state;
    if (!subjects.length && !id) {
      history.push({
        pathname: '/tests',
        state: {
          isModalVisible: true,
        },
      });
    }
  }, []);

  useEffect(() => {
    setSelectedItems(questions);
  }, [questions]);

  const handleGoBack = () => {
    history.push('/tests');
  };

  const handleChangePosition = event => {
    const type = event.target.value;
    const index = Number.parseInt(event.target.getAttribute('index'));
    const selected = [...selectedItems];
    if (type === NEXT && index < selected.length - 1) {
      selected.splice(index, 2, selectedItems[index + 1], selectedItems[index]);
    } else if (type === PREV && index) {
      selected.splice(index - 1, 2, selectedItems[index], selectedItems[index - 1]);
    }
    dispatch(setChoosedQuestionsAction(selected));
    setSelectedItems(selected);
  };

  const handleUploadedFile = info => {
    const file = info.file;
    const fileList = info.fileList;
    const fileSize = file.size / 1024 / 1024 < 3;
    const fileExtension = file?.name.split('.').pop();

    if (!file) {
      return;
    }

    if (fileExtension === FILE_EXTENSION.PDF && fileSize) {
      if (fileLink) {
        handleDispatchValue(dispatch, setFileLinkAction, '');
      }

      if (fileList.length) {
        setUploadedFile([file]);
      } else if (!fileList.length) {
        setUploadedFile([]);
      }
    } else {
      showInfoMessage('error', invalidFilePDF);
      return;
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(selectedItems, result.source.index, result.destination.index);
    dispatch(setChoosedQuestionsAction(quotes));
    setSelectedItems(quotes);
  };

  const handleOnFinish = () => {
    setIsFormSubmit(true);
    const file = uploadedFile[0];
    if (state.testKind === POSSIBILITY_TO_RETURN) {
      setSettingTimeActive(true);
    } else {
      dispatch(updateOrAddTestData(id, uploadedFile[0] ? file.originFileObj : fileLink, state, history));
    }
  };

  return settingTimeActive ? (
    <QuestionTimeForm
      suggestTimeInSeconds={suggestTimeInSeconds}
      setSettingTimeActive={setSettingTimeActive}
      uploadedFile={uploadedFile}
      setIsFormSubmit={setIsFormSubmit}
      testTimeSaving={testTime}
    />
  ) : (
    <QuestionsPanelUI
      handleOnFinish={handleOnFinish}
      isFormSubmit={isFormSubmit}
      testKindOptions={testKindOptions}
      suggestTimeInSeconds={suggestTimeInSeconds}
      selectedItems={selectedItems}
      uploadedFile={uploadedFile}
      fileLink={fileLink}
      handleUploadedFile={handleUploadedFile}
      showModal={showModal}
      handleRemoveQuestionFromList={handleRemoveQuestionFromList}
      title={state.title}
      testKind={state.testKind}
      handleChangePosition={handleChangePosition}
      isModalAcive={isModalAcive}
      setIsModalActie={setIsModalActie}
      handleGoBack={handleGoBack}
      onDragEnd={onDragEnd}
      testTime={testTime}
      minutes={minutes}
    />
  );
};
QuestionsPanelContainer.propTypes = {
  handleRemoveQuestionFromList: func,
  showModal: func,
};
export default QuestionsPanelContainer;
