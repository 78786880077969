import { Row, Col, Modal, Input, Checkbox, Form, Item } from 'antd/index';
import React, { useState, useEffect } from 'react';
import { bool, func, object } from 'prop-types';
import { isRoot, isAdmin, isRecruiter, isTechnical } from 'utils/checkUserRoles';
import CustomButton from 'components/CustomButton/CustomButton';
import { BUTTON_TEXTS, SETTINGS_VIEW } from '../../../constants';

const { SAVE } = BUTTON_TEXTS;
const { SELECT_PERMISSIONS, TABS_LEAVES, COPIED_DATA } = SETTINGS_VIEW;

const backgroundMask = 'rgba(22, 22, 22, 0.4)';

export const ModalRole = ({ isModalVisible, setIsModalVisible, updateRole, dataRole }) => {
  const [form] = Form.useForm();
  const { id, roleName, isAccessTabsLeaves, isAccessWindowLeaves, isAccessCopiedData, isPossibleHiddenTest } = dataRole;
  const [previousRoleName, setPreviousRoleName] = useState(roleName);
  const isDisabledRole = isTechnical(roleName) || isRecruiter(roleName) || isAdmin(roleName) || isRoot(roleName);
  useEffect(() => {
    form.setFieldsValue({
      id,
      roleName,
      isAccessTabsLeaves,
      isAccessWindowLeaves,
      isAccessCopiedData,
      isPossibleHiddenTest,
    });
    setPreviousRoleName(roleName);
  }, [dataRole]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleOnFinishUpdateRole = values => {
    updateRole(values, previousRoleName, setIsModalVisible);
  };

  return (
    <Modal
      footer={null}
      title={SELECT_PERMISSIONS}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="modal-in-settings"
      closable={false}
      centered
      maskStyle={{ background: backgroundMask }}
    >
      <Form
        form={form}
        requiredMark="optional"
        layout="vertical"
        className="container__form form"
        onFinish={handleOnFinishUpdateRole}
        autoComplete="off"
      >
        <Row align="middle" justify="space-between">
          <Col className="roles-id-in-modal">
            <Form.Item initialValue={id} name="id" noStyle>
              <Input placeholder="id" />
            </Form.Item>
          </Col>
          <Col className="roles-name-in-modal">
            <Form.Item initialValue={roleName} name="roleName" noStyle>
              <Input disabled={isDisabledRole} />
            </Form.Item>
          </Col>
        </Row>
        <Row className="form-item form__item form__item--checkbox">
          <Form.Item initialValue={isAccessTabsLeaves} valuePropName="checked" name="isAccessTabsLeaves" noStyle>
            <Checkbox>{TABS_LEAVES}</Checkbox>
          </Form.Item>
        </Row>
        <Row className="form-item form__item form__item--checkbox">
          <Item initialValue={isAccessCopiedData} valuePropName="checked" name="isAccessCopiedData" noStyle>
            <Checkbox>{COPIED_DATA}</Checkbox>
          </Item>
        </Row>
        <Row className="action-container action-container--center">
          <CustomButton type="primary" htmlType="submit" className="custom-button--green">
            {SAVE}
          </CustomButton>
        </Row>
      </Form>
    </Modal>
  );
};
ModalRole.propTypes = {
  isModalVisible: bool,
  updateRole: func,
  setIsModalVisible: func,
  dataRole: object,
};
