/* eslint-disable react/prop-types */
import { Image, Space, CustomIcon } from 'antd/index';
import { SOLVE_TEST_IMAGE } from '../../constants';

const { PREVIEW } = SOLVE_TEST_IMAGE;

const CloseUpIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.72"
        d="M10.1117 6.28251H7.67247V3.96665C7.66247 3.83224 7.59966 3.7062 7.49635 3.61325C7.40179 3.51837 7.27293 3.4605 7.13531 3.4511C7.06057 3.44832 6.98599 3.45962 6.91592 3.48434C6.84585 3.50906 6.78169 3.5467 6.72718 3.59508C6.67268 3.64345 6.62891 3.70159 6.59845 3.7661C6.56798 3.8306 6.55141 3.9002 6.54972 3.97081V6.2742H4.11048C4.03692 6.27375 3.96401 6.28713 3.89598 6.31354C3.82795 6.33995 3.76616 6.37887 3.71421 6.42803C3.66073 6.47631 3.6183 6.53442 3.58952 6.59877C3.56075 6.66313 3.54624 6.73237 3.5469 6.80223C3.5469 6.87212 3.56147 6.94132 3.5898 7.00589C3.61812 7.07046 3.65963 7.12913 3.71197 7.17855C3.7643 7.22796 3.82643 7.26716 3.8948 7.29391C3.96318 7.32066 4.03647 7.33442 4.11048 7.33442H6.54972V9.65444C6.55829 9.78423 6.61613 9.90675 6.71263 9.99953C6.80892 10.0933 6.93982 10.1484 7.07807 10.1534C7.15224 10.1578 7.22661 10.1483 7.29688 10.1255C7.36716 10.1026 7.43196 10.0669 7.48755 10.0203C7.54368 9.97131 7.58893 9.91226 7.62068 9.84659C7.65242 9.78093 7.67003 9.70996 7.67247 9.63781V7.34274H10.1117C10.2608 7.34165 10.4035 7.28523 10.509 7.18566C10.6144 7.08609 10.6741 6.95136 10.6753 6.81055C10.6723 6.6702 10.6124 6.53629 10.508 6.43635C10.456 6.38718 10.3942 6.34826 10.3262 6.32185C10.2582 6.29544 10.1853 6.28207 10.1117 6.28251Z"
        fill="#F0F0F0"
      />
      <path
        opacity="0.72"
        d="M15.7781 13.8454L12.696 10.935C13.8297 9.596 14.3797 7.89894 14.2337 6.19009C14.0877 4.48124 13.2567 2.88939 11.9103 1.73932C10.5639 0.589245 8.80357 -0.0323697 6.98841 0.00129964C5.17325 0.034969 3.44009 0.721385 2.14255 1.9205C0.845011 3.11961 0.0808794 4.74105 0.00606107 6.454C-0.0687573 8.16694 0.551376 9.8423 1.73993 11.1382C2.92849 12.4342 4.5959 13.253 6.40196 13.4278C8.20802 13.6025 10.0166 13.1199 11.4587 12.0784L14.5408 14.9888C14.6195 15.0629 14.7129 15.1216 14.8157 15.1615C14.9184 15.2015 15.0285 15.2219 15.1396 15.2217C15.2519 15.2224 15.3631 15.2018 15.4667 15.1611C15.5704 15.1204 15.6643 15.0604 15.7428 14.9847C15.9012 14.8347 15.9931 14.6334 15.9996 14.4217C16.0062 14.2099 15.9269 14.0039 15.7781 13.8454ZM10.9348 10.3654L10.882 10.4111L10.8423 10.4527C9.82105 11.3941 8.4468 11.9156 7.01971 11.9033C5.59262 11.8911 4.22862 11.3461 3.2256 10.3874C2.22258 9.42874 1.66202 8.13421 1.66633 6.78654C1.67065 5.43888 2.2395 4.14757 3.24865 3.19463C4.25779 2.24168 5.62525 1.70452 7.0524 1.70044C8.47954 1.69636 9.85042 2.2257 10.8657 3.17287C11.8809 4.12003 12.458 5.40806 12.471 6.75567C12.4839 8.10329 11.9317 9.40099 10.9348 10.3654Z"
        fill="#F0F0F0"
      />
    </svg>
  );
};

const SolveTestImage = ({ src, className }) => {
  return (
    <Image
      className={['question-content__image', className].join(' ')}
      width={320}
      preview={{
        mask: (
          <Space direction="horizontal" align="center">
            <CustomIcon style={{ marginRight: '5px' }} component={CloseUpIcon} />
            {PREVIEW}
          </Space>
        ),
      }}
      src={src}
    />
  );
};

export default SolveTestImage;
