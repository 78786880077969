import React, { useEffect, useState, useRef } from 'react';
import { Button, Row, Col, Paragraph, PlusOutlined, Popconfirm } from 'antd/index';
import useUpdateDocumentInFirestore from 'hooks/useUpdateDocumentInFirestore';
import { isRoot, isAdmin } from 'utils/checkUserRoles';
import { sortArray } from 'utils/sortArray';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import DeleteIcon from '../../TableShapes/TableShapeCustomIcons/DeleteIcon';
import CustomButton from 'components/CustomButton/CustomButton';
import { usersRef } from '../../../services/firestore/references';
import { SETTINGS_VIEW } from '../../../constants';

const { MAKE_DECISION, YES, NO, ADD_USER, EMAIL, ROLE } = SETTINGS_VIEW;

const tableShapeUsers = (
  roleCurrentUser,
  setReloadData,
  reloadData,
  deleteUser,
  userRolesForAdmin,
  userRolesForRoot,
  setIsLoading,
  setIsVisibleModalAddUser,
) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{EMAIL}</Paragraph>;
      },
      dataIndex: 'email',
      width: '30%',
      key: 'email',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'email'),
    },
    {
      title: function renderRole() {
        return <Paragraph className="text-title">{ROLE}</Paragraph>;
      },
      key: 'changeRole',
      showSorterTooltip: false,
      width: '40%',
      sorter: (a, b) => sortArray(a, b, 'role'),
      render: function renderSelectRoles(_, { id, role }) {
        const [selectedValue, setSelectedValue] = useState('');

        const didMount = useRef(false);
        useEffect(() => {
          if (didMount.current) {
            useUpdateDocumentInFirestore(id, usersRef, { role: selectedValue }, reloadData, setReloadData);
            return;
          }
          didMount.current = true;
        }, [selectedValue]);
        const containerRefRole = useRef(null);

        return (
          <Row justify="space-between" ref={containerRefRole}>
            <Col md={24} className="role-column">
              <CustomSelect
                onChange={value => {
                  setSelectedValue(value);
                }}
                labels={role}
                value={role}
                options={isRoot(roleCurrentUser) ? userRolesForRoot : userRolesForAdmin}
                disabledSelectInUsersTable={
                  (isRoot(role) && isRoot(roleCurrentUser)) || (isAdmin(role) && isAdmin(roleCurrentUser))
                }
                isSingle={true}
                isUsersTable={true}
                containerRef={containerRefRole}
              />
            </Col>
          </Row>
        );
      },
    },
    {
      title: function renderAddUserButton() {
        return (
          <CustomButton
            type="primary"
            className="custom-button--green button--add-user"
            onClick={() => setIsVisibleModalAddUser(true)}
          >
            <PlusOutlined />
            {ADD_USER}
          </CustomButton>
        );
      },
      key: 'deleteUser',
      align: 'right',
      render: function deleteRenderedUser(_, { id, role }) {
        return (
          <>
            {!isRoot(role) && isRoot(roleCurrentUser) && (
              <Popconfirm
                title={MAKE_DECISION}
                onConfirm={() => {
                  setIsLoading(true);
                  deleteUser(id);
                }}
                okText={YES}
                cancelText={NO}
              >
                <Button
                  className="conditional-delete button-delete"
                  type="text"
                  icon={<DeleteIcon classes="delete-icon" />}
                />
              </Popconfirm>
            )}
          </>
        );
      },
    },
  ];

  return columns;
};
export default tableShapeUsers;
