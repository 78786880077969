/* eslint react/prop-types: 0 */
import { Row, Col, Modal, Paragraph, Space, Text } from 'antd/index';
import { TEST_STATUSES, TEST_RESULT_MODAL } from 'constants/index';
import CustomButton from 'components/CustomButton/CustomButton';
import { FC } from 'react';
import { ITestResultModalUIProps } from './TestResultModalTypes';
import handleRedirect from 'utils/handleRedirect';
import { useHistory } from 'react-router';
import GeneratePDFFromTemplate from 'components/ResultsPDFTemplate/DetailsPdfTemplate';
import CustomTag from './CustomTag';
import TestResultModalRightColumn from './TestResultModalRightColumn';
import QuestionCard from './QuestionCard';

const { FINISHED } = TEST_STATUSES;
const { CANDIDATE, TEST_AUTHOR, FULL_ANALYSIS, WAITING, GENERATE_PDF } = TEST_RESULT_MODAL;
const renderActionButtons = (status: string): boolean => status === FINISHED;

const TestResultModalUI: FC<ITestResultModalUIProps> = ({
  visible,
  onOk,
  onCancel,
  testResultData,
  time,
  score,
  clipboardState,
  setClipboardState,
  specificLanguagesResult,
  answers,
}) => {
  const history = useHistory();
  return (
    <Modal
      className="results-modal"
      width="1000px"
      visible={visible}
      bodyStyle={{ height: '600px' }}
      onOk={onOk}
      onCancel={onCancel}
      closable={false}
      footer={null}
      centered
    >
      <Row className="results-modal__content-container content-container">
        <Col className="content-container__left-section left-section">
          <Row align="middle">
            <Paragraph className="left-section__test-title">{testResultData.title}</Paragraph>
            <CustomTag status={testResultData.status} />
          </Row>
          <Col className="left-section__initial-info initial-info">
            <Row gutter={[100, 0]}>
              <Col>
                <Paragraph className="initial-info__text">{CANDIDATE}</Paragraph>
                <Paragraph>{testResultData.candidate}</Paragraph>
              </Col>
            </Row>
          </Col>
          <Row className="content-container__cards-container cards-container" gutter={[0, 24]}>
            {testResultData.answers.map(({ correct, isExamined, questionId }, index) => (
              <QuestionCard
                key={`${questionId} ${index}`}
                counter={index + 1}
                status={{ isCorrect: correct, isExamined }}
              />
            ))}
          </Row>
        </Col>
        <Col className="content-container__right-section right-section">
          <TestResultModalRightColumn
            time={time}
            expireTime={testResultData.expireTime}
            finishedAt={testResultData.finishedAt}
            status={testResultData.status}
            score={score}
            clipboardState={clipboardState}
            setClipboardState={setClipboardState}
            id={testResultData.id}
            specificLanguagesResult={specificLanguagesResult}
          />

          <Row justify="space-between" className="button-container">
            {renderActionButtons(testResultData.status) ? (
              <>
                <CustomButton
                  onClick={() => handleRedirect(history, `/results/${testResultData.id}`)}
                  className="custom-button--green"
                  disabled={false}
                >
                  {FULL_ANALYSIS}
                </CustomButton>
                <CustomButton disabled={false} className="custom-button--pdf">
                  <GeneratePDFFromTemplate
                    resultData={testResultData}
                    score={score}
                    specificLanguagesResult={specificLanguagesResult}
                    answers={answers}
                  />
                </CustomButton>
              </>
            ) : (
              <Space direction="vertical">
                <Text type="secondary">{WAITING}</Text>
                <Row justify="space-between" className="button-container">
                  <CustomButton className="custom-button--disabled" disabled={true}>
                    {FULL_ANALYSIS}
                  </CustomButton>
                  <CustomButton className="custom-button--disabled" disabled={true}>
                    {GENERATE_PDF}
                  </CustomButton>
                </Row>
              </Space>
            )}
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
export default TestResultModalUI;
