import { Paragraph, Text } from '../../antd';
import { sortArray } from '../../utils/sortArray';
import { sortArrayFullName } from 'utils/sortArrayFullName';
import classNames from 'classnames';

export const getContestResultsColumns = () => {
  return [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--participant">Participant</Paragraph>;
      },
      dataIndex: 'recruitedPerson',
      key: 'recruitedPerson',
      showSorterTooltip: false,
      sorter: (a, b) => sortArrayFullName(a, b, 'recruitedPerson'),
      className: 'recruited-name-column',
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--email">Email</Paragraph>;
      },
      dataIndex: 'emailRecruitedPerson',
      key: 'emailRecruitedPerson',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'emailRecruitedPerson'),
      className: 'recruited-email-column',
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--phone">Phone</Paragraph>;
      },
      dataIndex: 'phoneRecruitedPerson',
      key: 'phoneRecruitedPerson',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'phoneRecruitedPerson'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--time">Solution Time</Paragraph>;
      },
      dataIndex: 'solutionTime',
      key: 'solutionTime',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'solutionTime'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--result">Result</Paragraph>;
      },
      dataIndex: 'result',
      key: 'result',
      showSorterTooltip: false,
      sorter: (a, b) => a.result.percent - b.result.percent,
      render: function renderCustomRecord({ percent, rate }) {
        return (
          <>
            <Text
              className={classNames({
                'record-custom-font': true,
                'result-red': percent < 30,
                'result-orange': percent >= 30 && percent < 70,
                'result-green': percent >= 70,
              })}
            >
              {`${rate} | ${percent}%`}
            </Text>
          </>
        );
      },
    },
  ];
};
