import { Row, Paragraph, Form } from 'antd/index';
import { ClockCircleOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { number, func, array } from 'prop-types';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setTestTimeAction, updateOrAddTestData } from './ManageTestSlice';
import { useHistory, useLocation } from 'react-router';
import { SECONDS_TO_MINUTES, MANAGE_TEST_VIEW } from 'constants/index';
import { useState, useEffect } from 'react';
import CustomButton from 'components/CustomButton/CustomButton';

const {
  QUESTION_RETURN,
  DURING_TEST,
  DURATION,
  SUGGESTED_TIME,
  MINUTES,
  TEST_DURATION,
  ENTER_MINUTES,
  CANCEL,
  SAVE_TEST,
} = MANAGE_TEST_VIEW;

const QuestionTimeForm = ({
  suggestTimeInSeconds,
  setSettingTimeActive,
  uploadedFile,
  setIsFormSubmit,
  testTimeSaving,
}) => {
  const { Item } = Form;
  const { enterTestTime, correct } = messages;
  const { id } = useParams();
  const history = useHistory();
  const { pathname } = useLocation();

  const state = useSelector(state => state.managedTest);
  const dispatch = useDispatch();
  const [testTime, setTestTime] = useState();
  const { fileLink } = state;
  const [isTestTimeReady, setIsTestTimeReady] = useState(false);
  const isContestEdited = pathname.includes('/edit/');
  const testTimeInMinutes = Math.round(suggestTimeInSeconds / SECONDS_TO_MINUTES);
  const testSavingTimeInMinutes = Math.round(testTimeSaving / SECONDS_TO_MINUTES);

  useEffect(() => {
    setTestTime(state.testTime);
    setIsTestTimeReady(true);
  }, []);

  const handleFormFinish = () => {
    const file = uploadedFile[0];
    dispatch(updateOrAddTestData(id, uploadedFile[0] ? file.originFileObj : fileLink, state, history));
  };

  return (
    <Row justify="center" align="middle" className="background">
      <Row justify="center" align="middle" className="container">
        <Form autoComplete="off" layout="vertical" className="container__form form" onFinish={handleFormFinish}>
          <Paragraph className="container__form-paragraph">{QUESTION_RETURN}</Paragraph>
          <Paragraph className="container__form-paragraph">{DURING_TEST}</Paragraph>
          <Paragraph className="container__form-paragraph">{DURATION}</Paragraph>
          <Paragraph className="container__form-paragraph">
            {SUGGESTED_TIME}
            <Paragraph className="container__form-paragraph-sug">
              <ClockCircleOutlined className="question-time__icon" />
              {testTimeInMinutes} {MINUTES}
            </Paragraph>
          </Paragraph>
          {isTestTimeReady && (
            <Item
              initialValue={isContestEdited ? testSavingTimeInMinutes : null}
              className="form-item form__item"
              label={<Paragraph className="form-item__label">{TEST_DURATION}</Paragraph>}
              required
              rules={[
                () => ({
                  validator(_, value) {
                    if (!value) {
                      return Promise.reject();
                    }
                    if (isNaN(value) || value < 1 || value > 1000 || !Number.isInteger(Number.parseFloat(value))) {
                      dispatch(setTestTimeAction(testTime));
                      return Promise.reject(correct);
                    }
                    dispatch(setTestTimeAction(value * SECONDS_TO_MINUTES));
                    return Promise.resolve();
                  },
                  message: enterTestTime,
                }),
              ]}
              hasFeedback
              name="testTime"
            >
              {isContestEdited ? (
                <Input name="testTime" className="form-item__input" placeholder={ENTER_MINUTES} autocomplete="off" />
              ) : (
                <Input className="form-item__input" placeholder={ENTER_MINUTES} autocomplete="off" />
              )}
            </Item>
          )}
          <Row className="action-container" justify="space-around">
            <CustomButton
              onClick={() => {
                setSettingTimeActive(false);
                setIsFormSubmit(false);
                dispatch(setTestTimeAction(testTime));
              }}
              className="custom-button--resign"
            >
              {CANCEL}
            </CustomButton>
            <CustomButton htmlType="submit" className="custom-button--submit">
              {SAVE_TEST}
            </CustomButton>
          </Row>
        </Form>
      </Row>
    </Row>
  );
};

QuestionTimeForm.propTypes = {
  suggestTimeInSeconds: number,
  setSettingTimeActive: func,
  uploadedFile: array,
  setIsFormSubmit: func,
  testTimeSaving: number,
};

export default QuestionTimeForm;
