import { FILE_EXTENSION, DOCUMENT_TYPE } from 'constants/index';

const getDocumentTypeByFileExtenstion = (extension: string) => {
  switch (extension.toLowerCase()) {
    case FILE_EXTENSION.JPEG:
    case FILE_EXTENSION.JPG:
    case FILE_EXTENSION.PNG:
      return DOCUMENT_TYPE.IMAGE;
    case FILE_EXTENSION.JS:
    case FILE_EXTENSION.XML:
    case FILE_EXTENSION.DOCX:
    case FILE_EXTENSION.DOC:
    case FILE_EXTENSION.PDF:
      return DOCUMENT_TYPE.DOCUMENT;
    default:
      return DOCUMENT_TYPE.OTHER;
  }
};

export default getDocumentTypeByFileExtenstion;
