import Login from 'views/Login/Login';
import NoAccess from 'views/NoAccess/NoAccess';
import { v4 as uuidv4 } from 'uuid';
import ContestModal from '../../views/ContestModal/ContestModal';

export const AUTH_ROUTES = [
  {
    path: '/login',
    component: Login,
    key: uuidv4(),
  },
  {
    path: '/no-access',
    component: NoAccess,
    key: uuidv4(),
  },
  {
    path: '/contest',
    component: ContestModal,
    key: uuidv4(),
  },
  {
    path: '/results',
    component: Login,
    key: uuidv4(),
  },
];
