import { Tag } from 'antd/index';
import capitalizeWordFirstLetter from 'utils/capitalizeWordFirstLetter';

const STATUS_COLOR_PATTERN = {
  created: 'left-section__test-status--green',
  'in progress': 'left-section__test-status--yellow',
  'finished unfully': 'left-section__test-status--red',
  finished: 'left-section__test-status--red',
  expired: 'left-section__test-status--red',
  filled: 'left-section__test-status--red',
};

const CustomTag = ({ status }: { status: string }) => (
  <Tag className={` left-section__test-status ${STATUS_COLOR_PATTERN[status]}`}>
    {capitalizeWordFirstLetter(status)}
  </Tag>
);

export default CustomTag;
