import FinishedTest from '../../views/FinishedTest/FinishedTest.tsx';
import { v4 as uuidv4 } from 'uuid';

export const FINISHED_ROUTES = [
  {
    path: '/finished',
    component: FinishedTest,
    key: uuidv4(),
  },
];
