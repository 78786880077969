import React from 'react';
import { Button, Paragraph, Popconfirm, Tag, Tooltip } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import DuplicateIcon from './TableShapeCustomIcons/DuplicateIcon';
import { useHistory } from 'react-router-dom';
import { TABLE_SHAPES_VIEW, BUTTON_TEXTS, ACTIVATE_BUTTON } from 'constants/index';
import { isAdmin, isRoot } from 'utils/checkUserRoles';
import classNames from 'classnames';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { setTitleAction, setTestKindTagsAction } from 'views/ManageTest/ManageTestSlice';
import { useDispatch } from 'react-redux';
import CustomButton from 'components/CustomButton/CustomButton';

const { TEST, MAKE_DECISION, YES, NO, NUMBER_OF_QUESTIONS, TYPE, CATEGORY } = TABLE_SHAPES_VIEW;
const { EDIT } = BUTTON_TEXTS;

const { ACTIVE, INACTIVE } = ACTIVATE_BUTTON;

const testsListTableShape = ({
  idsOfValidTestsToDelete,
  sortArray,
  handleOnClickActivate,
  role,
  handleHideTest,
  setIsDuplicateVisible,
  setRecord,
  activeContestId,
  setIsModalDeleteVisible,
  setIdClicked,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--first">{TEST}</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: 350,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
      render: function renderTitle(title, { hiddenFor }) {
        return (
          <Paragraph
            className={`first-text 
            ${(isAdmin(role) || isRoot(role)) && !!hiddenFor && 'color-hidden-text'}`}
          >
            {title}
          </Paragraph>
        );
      },
    },
    {
      title: <Paragraph className="text-title">{CATEGORY}</Paragraph>,
      dataIndex: 'subjects',
      key: 'subjects',
      align: 'left',
      width: 250,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a.subjects, b.subjects, '0'),
      render: function renderCustomSubject(_, { subjects }) {
        const renderData = subjects.map((item, index) => {
          return (
            <Tag
              key={index}
              className={classNames({
                cat: true,
                // 'no-ellipsis':true,
                'cat--js': item === 'JavaScript',
                'cat--jv': item === 'Java',
                'cat--php': item === 'Php',
                'cat--c': item === 'C',
                'cat--c-sh': item === 'C#',
                'cat--cpp': item === 'C++',
                'cat--db': item === 'Database',
                'cat--ai': item === 'AI',
                'cat--distributed-sys': item === 'Distributed systems',
                'cat--salesforce': item === 'Salesforce',
                'cat--python': item === 'Python',
                'cat--sql': item === 'SQL',
                'cat--css': item === 'CSS',
              })}
            >
              {item}
            </Tag>
          );
        });
        return (
          <Tooltip
            overlayClassName="categoryTooltip"
            placement="rightTop"
            title={renderData.length > 2 ? renderData : null}
            color={'#2d2d2d'}
          >
            <Paragraph
              className="categoryTooltip"
              ellipsis={{
                rows: 1,
              }}
            >
              {renderData}
            </Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title">{TYPE}</Paragraph>;
      },
      dataIndex: 'testKind',
      key: 'testKind',
      align: 'left',
      width: 200,
      showSorterTooltip: false,
      sorter: (a, b) => a.testKind.localeCompare(b.testKind),
      render: function renderCustomDepartment(testKind) {
        return <Paragraph className="regular-text">{testKind}</Paragraph>;
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--quantity">{NUMBER_OF_QUESTIONS}</Paragraph>;
      },
      dataIndex: 'questions',
      key: 'questions',
      align: 'center',
      width: 80,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return a.questions.length - b.questions.length;
      },
      render: function renderCustomDepartment(questions) {
        return <Paragraph className="regular-text regular-text--number">{questions.length}</Paragraph>;
      },
    },
  ];

  const activateButton = {
    align: 'right',
    key: 'activate',
    width: 130,
    render: function renderActions({ id, title }) {
      return (
        <CustomButton
          className={classNames(
            {
              'active-button': activeContestId === id,
              'inactive-button': activeContestId !== id,
            },
            'button custom-button--generate',
          )}
          onClick={() => handleOnClickActivate(id, title)}
          type="primary"
        >
          {`${activeContestId === id ? INACTIVE : ACTIVE}`}
        </CustomButton>
      );
    },
  };

  const deleteButton = {
    align: 'left',
    key: 'delete',
    width: 80,
    render: function renderDelete(_, { id }) {
      return (
        <Button
          onClick={() => {
            setIsModalDeleteVisible(true);
            setIdClicked(id);
          }}
          disabled={idsOfValidTestsToDelete.includes(id)}
          className="button-delete"
          type="text"
          title="Delete"
          icon={
            <DeleteIcon classes={`delete-icon ${idsOfValidTestsToDelete.includes(id) && 'delete-icon--disabled'}`} />
          }
        />
      );
    },
  };
  const editButton = {
    align: 'right',
    key: 'edit',
    width: 130,
    render: function renderActions({ id, testKind }) {
      return (
        <CustomButton
          className="button custom-button--edit"
          onClick={() => {
            history.push(`/tests/edit/${id}`);
            handleDispatchValue(dispatch, setTitleAction, '');
            handleDispatchValue(dispatch, setTestKindTagsAction, testKind);
          }}
          type="primary"
        >
          {EDIT}
        </CustomButton>
      );
    },
  };
  const duplicateButton = {
    align: 'left',
    key: 'edit',
    width: 60,
    render: function renderActions(record) {
      return (
        <Button
          className="button-duplicate"
          type="text"
          title="Duplicate"
          icon={<DuplicateIcon className="duplicate-icon" />}
          onClick={() => {
            setRecord(record);
            setIsDuplicateVisible(true);
            handleDispatchValue(dispatch, setTitleAction, '');
            handleDispatchValue(dispatch, setTestKindTagsAction, '');
          }}
        />
      );
    },
  };
  const hideButton = {
    align: 'right',
    key: 'hide',
    width: 60,
    render: function renderHide(_, row) {
      return (
        <Popconfirm
          title={MAKE_DECISION}
          onConfirm={() => {
            handleHideTest(row);
          }}
          okText={YES}
          cancelText={NO}
        >
          <Button className="button-delete" type="text" title="Delete" icon={<DeleteIcon classes={'delete-icon'} />} />
        </Popconfirm>
      );
    },
  };
  return [...columns, editButton, activateButton, duplicateButton, deleteButton];
};
export default testsListTableShape;
