import { string } from 'prop-types';

const DeleteIcon = ({ classes }) => {
  return (
    <svg className={classes} width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 1C3 0.447715 3.44772 0 4 0H12C12.5523 0 13 0.447715 13 1C13 1.55228 12.5523 2 12 2H4C3.44772 2 3 1.55228 3 1Z"
        fill="#AFAFAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 4.44772 0.447715 4 1 4H15C15.5523 4 16 4.44772 16 5C16 5.55228 15.5523 6 15 6H14L14 14C14 16.2091 12.2091 18 10 18H6C3.79086 18 2 16.2091 2 14V6H1C0.447715 6 0 5.55228 0 5ZM12 14L12 6H4L4 14C4 15.1046 4.89543 16 6 16H10C11.1046 16 12 15.1046 12 14Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};
export default DeleteIcon;
DeleteIcon.propTypes = {
  classes: string,
};
