import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useHistory } from 'react-router-dom';
import { LogoIcon } from 'assets/icons';
import {
  activeContestCollectionRef,
  testsCollectionRef,
  generatedTestsCollectionRef,
} from '../../services/firestore/references';
import { TEST_STATUSES, EMAIL_REGEX, DATABASE_ERROR, PHONE_REGEX, FULL_NAME_REGEX } from 'constants/index';
import _ from 'lodash';
import { Col, CustomIcon } from '../../antd';

const { CREATED } = TEST_STATUSES;
const firstCheckboxText1 = 'I declare that I have read the ';
const firstCheckboxText2 = 'Contest Regulations';
const firstCheckboxText3 = ' and accept all the terms and conditions contained therein.';
const secondCheckboxText1 = 'I have familiarized myself with the ';
const secondCheckboxText2 = 'privacy policy';
const secondCheckboxText3 = ' and the ';
const secondCheckboxText4 = 'information clause.';
const secondCheckboxText5 = ' I consent to the processing of my personal data for the purpose of the contest.';

const ContestModal = () => {
  const [activeContestId, setActiveContestId] = useState();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const history = useHistory();
  const [isGenerating, setIsGenerating] = useState(false);
  const [dataToContest, setDataToContest] = useState({});
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    const activeContestCleanup = () => {
      activeContestCollectionRef
        .doc('activeContest')
        .get()
        .then(snap => {
          if (snap.exists) {
            setActiveContestId(snap.data().contestId);
          } else {
            console.log('No such document!');
          }
        })
        .catch(e => console.log('Error getting document:', e));
    };
    activeContestCleanup();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    isMounted.current = true;
    const testDataCleanup = () => {
      if (activeContestId) {
        testsCollectionRef
          .doc(activeContestId)
          .get()
          .then(dataOfContest => {
            if (dataOfContest.exists) {
              setDataToContest(dataOfContest.data());
            } else {
              console.log('No such document!');
            }
          })
          .catch(e => console.log('Error getting document:', e));
      }
    };
    testDataCleanup();
    return () => {
      isMounted.current = false;
    };
  }, [activeContestId]);

  const handleFormTestGenerated = async dataFromForm => {
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);
    const { id: testId, questions, subjects, testKind, testTime, title } = dataToContest;

    const { name, email, number, acceptRegulations, acceptGDPR } = dataFromForm;

    const subjectsUniqueSet = new Set(subjects);
    const subjectsUnique = [...subjectsUniqueSet];

    const createdAt = Date.now();
    const { id } = generatedTestsCollectionRef.doc();
    const shuffledQuestions = _.shuffle(questions);
    await generatedTestsCollectionRef
      .doc(id)
      .set({
        title,
        hiddenFor: false,
        recruitedPerson: name,
        testTime,
        testKind,
        subjects: subjectsUnique,
        emailRecruitedPerson: email,
        testId,
        numberOfQuestions: questions.length,
        testIdGenerated: id,
        status: CREATED,
        createdAt,
        isTestStarted: false,
        presentQuestionIndex: 0,
        answers: [],
        questionsFromInstance: shuffledQuestions,
        isActive: false,
        phoneRecruitedPerson: number,
        acceptRegulations: acceptRegulations,
        acceptGDPR: acceptGDPR,
      })
      .then(() => {
        history.push(`test-to-solve/${id}`);
      });
    setIsGenerating(false);
  };

  const onFinish = dataFromForm => {
    if (isGenerating) {
      return;
    }
    handleFormTestGenerated(dataFromForm);
  };

  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.name !== undefined &&
      allValues.email !== undefined &&
      allValues.number !== undefined &&
      allValues.name !== '' &&
      allValues.email !== '' &&
      allValues.number !== '' &&
      allValues.acceptRegulations === true &&
      allValues.acceptGDPR === true &&
      allValues.email.match(EMAIL_REGEX) &&
      allValues.number.match(PHONE_REGEX) &&
      allValues.name.match(FULL_NAME_REGEX) &&
      dataToContest.fileLink !== undefined
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const handleClick = () => {
    if (!dataToContest.fileLink) {
      alert(DATABASE_ERROR);
    }
  };

  return (
    <Col className="start-wrapper start-wrapper__padding">
      <Col className="start-container">
        <Col className="start-container__logo-container">
          <CustomIcon component={LogoIcon} />
        </Col>
        <Col className="start-container__content">
          <Form
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            className="contestModalForm"
            autoComplete="off"
          >
            <Form.Item
              label={<Paragraph className="form-item__label">Full name</Paragraph>}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your full name',
                  pattern: new RegExp(FULL_NAME_REGEX),
                },
                { whitespace: true },
              ]}
            >
              <Input className="form-item__input" placeholder="Write your full name" autoComplete="off" />
            </Form.Item>

            <Form.Item
              label={<Paragraph className="form-item__label">Email</Paragraph>}
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please enter correct email',
                  pattern: new RegExp(EMAIL_REGEX),
                },
              ]}
            >
              <Input className="form-item__input" placeholder="Write your email" autoComplete="off" />
            </Form.Item>

            <Form.Item
              label={<Paragraph className="form-item__label">Phone number</Paragraph>}
              name="number"
              rules={[
                {
                  required: true,
                  pattern: new RegExp(PHONE_REGEX),
                  message: 'Please enter valid Polish phone number',
                },
                { whitespace: false },
              ]}
            >
              <Input
                type="number"
                id="phone-number"
                className="form-item__input inputNumber"
                placeholder="Write your phone number"
                autoComplete="off"
              />
            </Form.Item>

            <Form.Item
              name="acceptRegulations"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject('To proceed, you need to agree with our terms and conditions'),
                },
              ]}
            >
              <Checkbox onClick={handleClick}>
                {firstCheckboxText1}
                <a href={dataToContest.fileLink} className="termsLink" target="_blank" rel="noreferrer">
                  {firstCheckboxText2}
                </a>
                {firstCheckboxText3} <span className="redStar">*</span>
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="acceptGDPR"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject('To proceed, you need to agree with GDPR'),
                },
              ]}
            >
              <Checkbox>
                {secondCheckboxText1}
                <a href="https://britenet.com.pl/en/policy" className="termsLink" target="_blank" rel="noreferrer">
                  {secondCheckboxText2}
                </a>
                {secondCheckboxText3}
                <a href="https://britenet.com.pl/en/rodoinf" className="termsLink" target="_blank" rel="noreferrer">
                  {secondCheckboxText4}
                </a>
                {secondCheckboxText5}
                <span className="redStar">*</span>
              </Checkbox>
            </Form.Item>

            <Form.Item className="contestModalButtonContainer">
              <Button disabled={btnDisabled} type="primary" htmlType="submit" className="contestModalButton">
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Col>
    </Col>
  );
};

export default ContestModal;
