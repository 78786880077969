import React from 'react';
import { Row, Col, Form, Modal } from 'antd/index';
import { object, bool, func, string, oneOfType } from 'prop-types';
import GeneratedTestFormUI from './GeneratedTestForm/GeneratedTestFormUI';
import GenerateTestFormUI from './GenerateTestForm/GenerateTestFormUI';
import { GENERATE_TEST } from '../../constants';

const { GENERATING_TEST } = GENERATE_TEST;

const GenerateTestUI = ({
  form,
  generated,
  handleFormTestGenerated,
  isModalGeneratedTestVisible,
  setIsModalGeneratedTestVisible,
  nameInput,
  setNameInput,
  validatedEmail,
  checkValidate,
  ...restProps
}) => {
  return (
    <Modal
      footer={null}
      title={generated ? '' : `${GENERATING_TEST}`}
      visible={isModalGeneratedTestVisible}
      onCancel={() => {
        setIsModalGeneratedTestVisible(false);
      }}
      className="generate-test-modal"
      closable={false}
      centered
    >
      <Row justify="center" align="middle" className="modal-generate-container">
        <Col className="modal-generate-container-col">
          <Form autoComplete="off" form={form} onFinish={handleFormTestGenerated}>
            <Row justify="center" align="middle">
              {generated === false ? (
                <GenerateTestFormUI
                  form={form}
                  {...restProps}
                  setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
                  nameInput={nameInput}
                  setNameInput={setNameInput}
                  validatedEmail={validatedEmail}
                  checkValidate={checkValidate}
                />
              ) : (
                <GeneratedTestFormUI
                  form={form}
                  {...restProps}
                  setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
                  nameInput={nameInput}
                  setNameInput={setNameInput}
                  validatedEmail={validatedEmail}
                  checkValidate={checkValidate}
                />
              )}
            </Row>
          </Form>
        </Col>
      </Row>
    </Modal>
  );
};

GenerateTestUI.propTypes = {
  form: object,
  generated: bool,
  handleFormTestGenerated: func,
  isModalGeneratedTestVisible: bool,
  setIsModalGeneratedTestVisible: func,
  nameInput: string,
  setNameInput: func,
  validatedEmail: oneOfType([string, bool]),
  checkValidate: func,
};

export default GenerateTestUI;
