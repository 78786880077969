import InitialView from '../../views/InitialView/InitialView.tsx';
import { v4 as uuidv4 } from 'uuid';

export const INITIAL_VIEW = [
  {
    path: '/initial',
    component: InitialView,
    key: uuidv4(),
  },
];
