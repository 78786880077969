const messages = {
  // common
  answer: 'Please complete the answer!',
  chooseSth: 'Select',
  correct: 'Please enter a valid value',
  instructions: 'Please enter text!',
  imageNotExist: 'The image does not exist in the database!',
  level: 'Please select a level!',
  noPermissions: 'You have no permissions',
  noCorrectAnswerCheck: 'No correct answer selected',
  noEmptyField: 'The field cannot be empty',
  question: 'Please complete the text!',
  subject: 'Select category',
  testCreated: 'Added test with title:',
  testInvalidData: 'Please complete all data and / or add at least two questions',
  testUpdated: 'Test updated',
  time: 'Please specify a time!',
  title: 'Enter test name',
  tooLongText: 'The field cannot contain more than 100 characters',
  usingDepartment: 'The selected department is currently in use',
  usingCategory: 'The selected category is currently in use',
  invalidFile: 'Invalid file!',
  invalidFilePDF: 'Error: invalid file. Add file in PDF format.',
  // select
  selectDepartment: 'Select department',
  selectLanguage: 'Select a programming language',
  selectRole: 'Select roles',
  selectUser: 'Select User',
  selectUsers: 'Select Users',
  // valid
  validAddCategory: 'Category has been added',
  validDeleteCategory: 'Categories Deleted',
  validUpdateCategory: 'Categories updated',
  validAddDepartment: 'The department has been added',
  validDeleteDepartment: 'Department Deleted',
  validUpdateDepartments: 'Updated departments',
  validUpdateNotification: 'Notifications have been updated',
  validAddRole: 'The role has been added',
  validUpdateRole: 'The role has been updated',
  validAddUser: 'User added',
  validDeleteUser: 'User Deleted',
  // invalid
  invalidAddCategory: 'Failed to add category',
  invalidUpdateCategory: 'Failed to update category',
  invalidDeleteDepartment: 'Failed to delete department',
  invalidUpdateDepartments: 'Failed to update departments',
  invalidUpdateNotification: 'Failed to update notifications',
  invalidAddRole: 'Failed to add role',
  invalidUpdateRole: 'Failed to Update Role',
  invalidAddUser: 'Failed to add user',
  invalidDeleteUser: 'Failed to delete user',
  invalidQuestionTime: 'Invalid query time',
  // existing
  existCategoryName: 'The category with the selected name already exists',
  existDepartmentName: 'Department with the selected name already exists',
  existEmailUser: 'User with this e-mail address already exists',
  existRoleName: 'Role with the selected name already exists',
  existsTemplateName: 'The template with the selected name already exists',
  // non-existent values
  nonExistentUsers: 'No users found',
  // enter
  enterCategory: 'Please enter categories',
  enterDepartment: 'Please enter department',
  enterEmailAddress: 'Please enter a valid email address',
  enterFullName: 'Please enter candidates first and last name',
  enterName: 'Enter Name',
  enterNewName: 'Enter new name',
  enterRole: 'Please enter roles',
  enterTestTime: 'Enter the correct test duration',
  enterQuestion: 'Enter unique question content',
  // errors
  somethingWentWrong: 'Something went wrong!',
  deleteQuestionError: 'Unable to delete used question',
  errorSendingEmail: 'Sending email failed',
  serverError: 'A server error has occurred',
  deleteContestError: 'The Contest could not be removed',
  // Success
  addAccessTemplateSuccess: 'Accesses added',
  deleteContestSuccess: 'Contest deleted',
};
export default messages;
