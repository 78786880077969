const DuplicateIcon = () => {
  return (
    <svg className="duplicate-icon" width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 0H14C15.1046 0 16 0.895432 16 2V12C16 12.5523 15.5522 13 15 13C14.4478 13 14 12.5523 14 12V3C14 2.44772 13.5522 2 13 2H6C5.44775 2 5 1.55228 5 1C5 0.447716 5.44775 0 6 0Z"
        fill="#AFAFAF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 4.89551 0.895508 4 2 4H10C11.1045 4 12 4.89551 12 6V16C12 17.1045 11.1045 18 10 18H2C0.895508 18 0 17.1045 0 16V6ZM2 7C2 6.44775 2.44775 6 3 6H9C9.20508 6 9.396 6.06201 9.55469 6.16797C9.82324 6.34717 10 6.65283 10 7V15C10 15.5522 9.55225 16 9 16H3C2.44775 16 2 15.5522 2 15V7Z"
        fill="#AFAFAF"
      />
    </svg>
  );
};
export default DuplicateIcon;
