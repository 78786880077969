import { createSlice } from '@reduxjs/toolkit';

const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    roles: [],
    permissions: {},
  },
  reducers: {
    setAllRoles(state, action) {
      state.roles = action.payload;
    },
    setPermissionsRoles(state, action) {
      state.permissions = action.payload;
    },
  },
});

const { actions, reducer } = settingsSlice;
export const { setAllRoles, setPermissionsRoles } = actions;
export default reducer;
