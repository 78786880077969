/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Col, CustomIcon, Paragraph, Row, Title, Button } from 'antd/index';
import { LogoIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import CustomButton from 'components/CustomButton/CustomButton';
import MarkdownPreview from 'components/MarkdownPreview/MarkdownPreview';
import { INITIAL_VIEW } from '../../constants';
import { activeContestCollectionRef, testsCollectionRef } from '../../services/firestore/references';

const {
  WELCOME_PART1,
  WELCOME_PART2,
  WELCOME_PART_3,
  WELCOME_PART_4,
  BRITENET_CAREER,
  TITLE_BRITENET_CAREER,
  JOIN,
  TO_TAKE_PART,
  GOOD_LUCK,
} = INITIAL_VIEW;

const InitialView = () => {
  const [activeContestId, setActiveContestId] = useState('');
  const [isActiveTest, setIsActiveTest] = useState(false);
  const [activeContestTitle, setActiveContestTitle] = useState();
  const [activeContestDescription, setActiveContestDescription] = useState();

  const getActiveContest = async () => {
    await activeContestCollectionRef
      .doc('activeContest')
      .get()
      // @ts-ignore
      .then(active => setActiveContestId(active.data().contestId));
  };

  useEffect(() => {
    getActiveContest();
  }, []);

  useEffect(() => {
    if (activeContestId) {
      setIsActiveTest(true);
      testsCollectionRef
        .doc(activeContestId)
        .get()
        // @ts-ignore
        .then(activetitle => setActiveContestTitle(activetitle.data().title));
    }

    if (activeContestId) {
      setIsActiveTest(true);
      testsCollectionRef
        .doc(activeContestId)
        .get()
        // @ts-ignore
        .then(activedesc => setActiveContestDescription(activedesc.data().contestDescription));
    }
  }, [activeContestId]);

  return (
    <Col className="start-wrapper">
      <Col className={isActiveTest ? 'start-container' : 'start-container not-active-test'}>
        <Col className="start-container__logo-container">
          <CustomIcon component={LogoIcon} />
        </Col>
        <Col className="start-container__content">
          <Col className="start-container__instructions">
            <Title className="start-container__initial" level={5}>
              {WELCOME_PART1}
            </Title>
            <Paragraph>
              {isActiveTest ? WELCOME_PART2 : WELCOME_PART_3}
              {isActiveTest && (
                <span className="start-container__instructions__contesttitle">{activeContestTitle}</span>
              )}
            </Paragraph>
            {isActiveTest && (
              <Paragraph className="start-container__instructions__contestdescription">
                <MarkdownPreview className="preview" content={activeContestDescription} />
              </Paragraph>
            )}
            <Paragraph>{isActiveTest ? TO_TAKE_PART : WELCOME_PART_4}</Paragraph>
            {!isActiveTest && (
              <Button type="link" className="career-link" href={BRITENET_CAREER} title={TITLE_BRITENET_CAREER}>
                {BRITENET_CAREER}
              </Button>
            )}
          </Col>
        </Col>
        {isActiveTest && (
          <Row align="bottom" justify="space-between" className="start-container__info">
            <Col className="start-container__info--column">
              <Paragraph>{GOOD_LUCK}</Paragraph>
            </Col>
            <Col>
              <Col>
                <Link to={{ pathname: '/contest' }}>
                  <CustomButton>{JOIN}</CustomButton>
                </Link>
              </Col>
            </Col>
          </Row>
        )}
      </Col>
    </Col>
  );
};

export default InitialView;
