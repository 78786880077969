import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Col, Row, Search, SearchOutlined, Spin, Table, Text } from '../../antd';
import { NUMBER_OF_ITEMS_PER_PAGE, PAGE_SIZE_OPTION, PLACEHOLDER_TEXTS } from '../../constants';
import { setSearchFilter } from 'views/TableShapes/filtersSlice';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import OverviewContestModal from '../../components/OverviewContestModal/OverviewContestModal';
import { IContestData, ICurrentUser } from '../../types/interfaces';
import { getContestsColumns } from './contestsColumnScheme';
import { RootState } from '../../App/store';
import { activeContestCollectionRef, generatedTestsCollectionRef } from '../../services/firestore/references';
import _ from 'lodash';
import { showInfoMessage } from '../../utils/showInfoMessage';
import messages from '../../utils/validationSchemaOptions/validationSchemaOptions';

const { somethingWentWrong } = messages;

const Contests = () => {
  const dispatch = useDispatch();
  const filterContests: string = useSelector((state: RootStateOrAny) => state.filters.searchFilter);
  const currentUser = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const [isContestModalVisible, setIsContestModalVisible] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<IContestData[]>([] as IContestData[]);
  const [activeContestId, setActiveContestId] = useState<string>('');
  const [activeContestTitle, setActiveContestTitle] = useState<string>('');
  const [selectedContestId, setSelectedContestId] = useState<string>('');
  const [selectedContestTitle, setSelectedContestTitle] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const activeContestIdCleanup = activeContestCollectionRef.doc('activeContest').onSnapshot(
      snapshot => {
        setIsLoading(false);
        setActiveContestId(snapshot.data()?.contestId);
        setActiveContestTitle(snapshot.data()?.contestTitle);
        setIsLoading(true);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );
    const dataSourceCleanup = generatedTestsCollectionRef.onSnapshot(
      snapshots => {
        const tempDataArray: IContestData[] = [];
        snapshots.forEach(snap => {
          const { title, testId } = snap.data();
          tempDataArray.push({ title, testId } as IContestData);
        });
        setDataSource(tempDataArray);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );
    return () => {
      activeContestIdCleanup();
      dataSourceCleanup();
    };
  }, []);

  const groupedContests = useMemo(() => Object.values(_.groupBy(dataSource, test => test.testId)), [dataSource]);
  const dataToDisplay = useMemo(() => {
    const activeFirstContests = groupedContests.map(resultGroup => {
      const singleResult = resultGroup[0];
      const attemptsProperty = { numOfAttempts: resultGroup.length };
      return { ...singleResult, ...attemptsProperty };
    });
    return [
      ...activeFirstContests.filter(({ testId }) => testId === activeContestId),
      ...activeFirstContests.filter(({ testId }) => testId !== activeContestId),
    ];
  }, [groupedContests]);

  const handleFilterContests = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchFilter(e.target.value));
  };

  const filteredContestsNames = useMemo(
    () =>
      dataToDisplay.filter(filteredContestName =>
        filteredContestName.title.toLowerCase().includes(filterContests.toLowerCase()),
      ),
    [filterContests, dataSource],
  );

  const handleOnClickOverview = (testId, title) => {
    setIsContestModalVisible(true);
    setSelectedContestId(testId);
    setSelectedContestTitle(title);
  };

  const itemsNumberStorage = localStorage.getItem('numberOfItemsContestsPage');
  const initialState: number | null = itemsNumberStorage && JSON.parse(itemsNumberStorage);

  const [numberOfItemsContestsPage, setNumberOfItemsContestsPage] = useState<number>(
    initialState || NUMBER_OF_ITEMS_PER_PAGE,
  );
  useEffect(() => {
    localStorage.setItem('numberOfItemsContestsPage', JSON.stringify(numberOfItemsContestsPage));
  }, [numberOfItemsContestsPage]);

  return isLoading ? (
    <>
      <Row
        justify="center"
        align="middle"
        className="contest-container settings-container table technical-view tech-table"
      >
        <Col className="settings-container__col">
          <OverviewContestModal
            isContestModalVisible={isContestModalVisible}
            handleOnCancel={() => setIsContestModalVisible(false)}
            activeContestId={activeContestId}
            selectedContestId={selectedContestId}
            selectedContestTitle={selectedContestTitle}
          />
          <Row>
            <Search
              placeholder={PLACEHOLDER_TEXTS.SEARCH_CONTEST}
              className="input-search"
              value={filterContests}
              maxLength={100}
              onChange={handleFilterContests}
              prefix={<SearchOutlined />}
            />
          </Row>
          <Row className="overview-details">
            <Text className="active-contest-info">
              {activeContestTitle.length ? (
                <>
                  <Text className="active-contest-info--green">Active Contest: </Text>
                  {activeContestTitle}
                </>
              ) : (
                'There are no active contests'
              )}
            </Text>
          </Row>
          <Row className="overview-table">
            <Table
              rowKey="testId"
              dataSource={filteredContestsNames}
              columns={getContestsColumns(currentUser.id, activeContestId, handleOnClickOverview)}
              pagination={{
                defaultPageSize: numberOfItemsContestsPage,
                showSizeChanger: true,
                pageSizeOptions: PAGE_SIZE_OPTION,
                locale: { items_per_page: '/ page' },
                position: ['bottomCenter'],
                onShowSizeChange: (_, size) => setNumberOfItemsContestsPage(size),
              }}
            />
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Row justify="center" align="middle" className="spinner-container">
      <Spin size="large" />
    </Row>
  );
};
export default Contests;
