import { Modal } from 'antd/index';
import React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { TABLE_SHAPES_VIEW } from '../../constants';
import { IDeleteModal } from '../../types/interfaces';

const { MAKE_DECISION, CANCEL, DELETE } = TABLE_SHAPES_VIEW;
const DeleteModal = ({ setIsLoading, deleteFunc, id, isModalDeleteVisible, setIsModalDeleteVisible }: IDeleteModal) => {
  return (
    <Modal
      className="delete-modal"
      visible={isModalDeleteVisible}
      title={MAKE_DECISION}
      maskStyle={{ background: 'rgba(22, 22, 22, 0.9)' }}
      centered={true}
      footer={[
        <CustomButton key={CANCEL} onClick={() => setIsModalDeleteVisible(false)} className="custom-button--cancel">
          {CANCEL}
        </CustomButton>,
        <CustomButton
          key={DELETE}
          onClick={() => {
            if (setIsLoading) {
              setIsLoading(true);
            }
            setIsModalDeleteVisible(false);
            deleteFunc(id);
          }}
          className="custom-button--delete"
        >
          {DELETE}
        </CustomButton>,
      ]}
      closable={false}
    />
  );
};
export default DeleteModal;
