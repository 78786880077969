/* eslint-disable indent */
/* eslint-disable no-extra-semi */

import { Row, Title, Col, Form, Spin, message } from 'antd/index';
import CustomBBCodeEditor from 'components/CustomBBCodeEditor/CustomBBCodeEditor';
import {
  QUESTION_CHOICE_TYPES,
  QUESTION_TYPES,
  QUESTION_SUBTYPES,
  ASYNC_STATUSES,
  MANAGE_QUESTION_ACTION_TYPES,
  MANAGE_QUESTION_FORM_ITEM_NAMES,
  TEST_KIND_OPTIONS,
  MANAGE_QUESTION,
  STORAGE_PATH,
  BUTTON_TEXTS,
} from 'constants/index';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { questionsCollectionRef, testsCollectionRef } from 'services/firestore/references';
import { showInfoMessage } from 'utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import AnswerSection from './AnswerSection';
import setCollectionDocument from 'utils/collectionDocumentCRUD/setCollectionDocument';
import InputSection from './InputSection';
import RadioSection from './RadioSection';
import _ from 'lodash';
import useAsync from 'hooks/useAsync';
import formatTimeToMiliSeconds from 'utils/formatTimeToMiliSeconds';
import handleFirestoreFetch from 'utils/handleFirestoreFetch';
import initialState from './initialState';
import handleSetInitialFormValues from './utils/handleSetInitialFormValues';
import { useSelector } from 'react-redux';
import ImageUpload from './ImageUpload';
import getStorageFileLink from './utils/getStorageFileLink';
import getAnswersFileUrls from './utils/getAnswersFileUrls';
import { getQuestionsTime } from 'utils/getQuestionsTime';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import handleRedirect from 'utils/handleRedirect';
import CustomButton from 'components/CustomButton/CustomButton';

const { noCorrectAnswerCheck, invalidQuestionTime } = messages;
const { MULTI, SINGLE } = QUESTION_CHOICE_TYPES;
const { CLOSED } = QUESTION_TYPES;
const { CODE, FILE } = QUESTION_SUBTYPES;
const { SUCCESS, PENDING, ERROR } = ASYNC_STATUSES;

const { ADD, EDIT } = MANAGE_QUESTION_ACTION_TYPES;
const { INSTRUCTIONS, QUESTION_IMAGE, SUBTYPE, TYPE } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const {
  OOPS,
  SAVE,
  ENTER_QUESTION,
  QUESTION_CONTENT,
  EDITING,
  ADDING,
  OVERWRITTEN,
  ADDED_QUESTION,
  WRITING_INF,
  QUESTION,
} = MANAGE_QUESTION;
const { CANCEL } = BUTTON_TEXTS;

const ManageQuestion = () => {
  const { id } = useParams();
  const editId = id;
  const history = useHistory();
  const { currentUser } = useSelector(state => state.auth);
  const [form] = Form.useForm();
  const [answers, setAnswers] = useState(initialState);
  // eslint-disable-next-line
  const [render, setRender] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [wasFormSubmitted, setWasFormSubmitted] = useState('');
  const { execute, status, value } = useAsync(handleFirestoreFetch, false, questionsCollectionRef, id);
  const [addLoading, setAddLoading] = useState(true);
  const handleSetFieldsValue = (field, value) => form.setFieldsValue({ [field]: value });
  const handleGetFormFieldValue = field => form.getFieldValue(field);
  const action = id !== undefined ? EDIT : ADD;
  const [pictureLink, setPictureLink] = useState('');
  const [contentImageLink, setContentImageLink] = useState('');
  const [editorValue, setEditorValue] = useState({
    instructions: '',
    content: '',
    code: '',
  });
  useEffect(() => {
    if (!id) {
      handleSetFieldsValue(SUBTYPE, CODE);
      setAddLoading(false);
      return;
    }
    execute();
  }, []);

  useEffect(() => {
    (async () => {
      if (status === SUCCESS) {
        const { answers, pictureLink, contentImageLink } = value;

        handleSetInitialFormValues(handleSetFieldsValue, value, answers, setAnswers);
        setPictureLink(pictureLink ? pictureLink : '');
        setContentImageLink(contentImageLink ? contentImageLink : '');
        setIsLoading(false);
      }
    })();
  }, [status]);

  const handleOnFinish = async ({
    questionImage,
    title,
    time,
    code,
    subjects,
    instructions,
    level,
    type,
    subtype,
    ...rest
  }) => {
    const { length } = answers.filter(answer => answer.isCorrect);
    if (!length && handleGetFormFieldValue(TYPE) === CLOSED) {
      showInfoMessage('error', noCorrectAnswerCheck);
      return;
    } else if (formatTimeToMiliSeconds(time) <= 0) {
      showInfoMessage('error', invalidQuestionTime);
      return;
    }

    const choice = type !== CLOSED ? null : length > 1 ? MULTI : SINGLE;
    const { id } = questionsCollectionRef.doc();

    const hide = message.loading(`${WRITING_INF}`, 0);

    const questionImageLink = await getStorageFileLink(id, questionImage, null, id, STORAGE_PATH.QUESTION_IMAGES);
    const urls = await getAnswersFileUrls(answers, STORAGE_PATH.QUESTION_IMAGES, action === ADD ? id : editId);

    const question = {
      instructions: editorValue[INSTRUCTIONS] || instructions,
      code: editorValue[CODE] || code || null,
      title,
      time: formatTimeToMiliSeconds(time),
      subjects,
      level,
      hasPicture: !!questionImage,
      type,
      isWithCode: subtype === CODE,
      hasAnswerFile: subtype === FILE,
      pictureLink: questionImageLink || null,
      choice,
      id: editId || id,
      createdAt: Date.now(),
      createdBy: currentUser.name,
    };
    if (_.isEmpty(rest)) {
      delete question['answers'];
      if (action === ADD) {
        await setCollectionDocument(questionsCollectionRef, question, id);
        handleRedirect(history, '/questions');
      } else {
        const updatedQuestion = {
          ...question,
          answers: urls
            ? answers.map(({ letter, isCorrect, firestoreContent, content }, index) => ({
                letter,
                isCorrect,
                content: firestoreContent || content,
                link: urls[index] || '',
                id: index + 1,
              }))
            : answers.map(({ letter, isCorrect, firestoreContent, content }, index) => ({
                letter,
                isCorrect,
                content: firestoreContent || content,
                id: index + 1,
              })),
        };
        if (type === 'open') {
          delete updatedQuestion['answers'];
        }

        await updateCollectionDocument(questionsCollectionRef, editId, updatedQuestion);
        const allQuizzesCollectionRef = await testsCollectionRef.get();
        const allQuizzes = allQuizzesCollectionRef.docs.map(quizz => {
          return quizz.data();
        });
        const quizzWithQuestionToUpdate = allQuizzes?.filter(({ questions }) => {
          return questions.some(({ id }) => {
            return id === editId;
          });
        });
        quizzWithQuestionToUpdate.map(async ({ questions, id, testKind, testTime }) => {
          const updatedQuestions = [];
          questions?.map(q => {
            if (q?.id !== editId) {
              updatedQuestions.push(q);
            } else {
              updatedQuestions.push(updatedQuestion);
            }
          });

          const suggestTimeInSeconds =
            testKind === TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN ? testTime : getQuestionsTime(updatedQuestions);

          await updateCollectionDocument(testsCollectionRef, id, {
            questions: updatedQuestions,
            testTime: suggestTimeInSeconds,
          });
        });

        handleRedirect(history, '/questions');
      }
      handleRedirect(history, '/questions');
    } else {
      if (action === ADD) {
        await setCollectionDocument(
          questionsCollectionRef,
          {
            ...question,
            answers: urls
              ? answers.map(({ letter, isCorrect, firestoreContent }, index) => ({
                  letter,
                  isCorrect,
                  content: firestoreContent,
                  link: urls[index] || '',
                  id: index + 1,
                }))
              : answers.map(({ letter, isCorrect, firestoreContent }, index) => ({
                  letter,
                  isCorrect,
                  content: firestoreContent || '',
                  id: index + 1,
                })),
          },
          id,
        );
        handleRedirect(history, '/questions');
      } else {
        const updatedQuestion = {
          ...question,
          answers: urls
            ? answers.map(({ letter, isCorrect, firestoreContent, content }, index) => ({
                letter,
                isCorrect,
                content: firestoreContent || content,
                link: urls[index] || '',
                id: index + 1,
              }))
            : answers.map(({ letter, isCorrect, firestoreContent, content }, index) => ({
                letter,
                isCorrect,
                content: firestoreContent || content,
                id: index + 1,
              })),
        };

        if (type === 'open') {
          delete updatedQuestion['answers'];
        }
        await updateCollectionDocument(questionsCollectionRef, editId, updatedQuestion);
        const allQuizzesCollectionRef = await testsCollectionRef.get();
        const allQuizzes = allQuizzesCollectionRef.docs.map(quizz => {
          return quizz.data();
        });
        const quizzWithQuestionToUpdate = allQuizzes?.filter(({ questions }) => {
          return questions.some(({ id }) => {
            return id === editId;
          });
        });
        quizzWithQuestionToUpdate.map(async ({ questions, id, testTime, testKind }) => {
          const updatedQuestions = [];
          questions?.map(q => {
            if (q?.id !== editId) {
              updatedQuestions.push(q);
            } else {
              updatedQuestions.push(updatedQuestion);
            }
          });

          const suggestTimeInSeconds =
            testKind === TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN ? testTime : getQuestionsTime(updatedQuestions);

          await updateCollectionDocument(testsCollectionRef, id, {
            questions: updatedQuestions,
            testTime: suggestTimeInSeconds,
          });
        });

        handleRedirect(history, '/questions');
      }
    }

    hide();
    showInfoMessage('success', action === ADD ? `${ADDED_QUESTION}` : `${OVERWRITTEN}`, 1500);
    setAnswers(prev => prev.map(answer => ({ ...answer, content: '', isCorrect: false })));
    setWasFormSubmitted(id);
    form.resetFields();
  };

  const handleSetAnswersState = (value, letter, language) => {
    setAnswers(prevAnswersState => {
      const key = 'letter';
      const searchedAnswer = prevAnswersState.find(answer => answer.letter === letter);
      const newAnswersState = [
        ...prevAnswersState,
        { ...searchedAnswer, firestoreContent: value, language: language ? language : '' },
      ];
      return [...new Map(newAnswersState.map(item => [item[key], item])).values()];
    });
  };

  useEffect(() => {
    answers.forEach(({ name, content }) => handleSetFieldsValue(name, content));
  }, [answers]);

  return (
    <>
      {(status === PENDING && isLoading && (
        <Row className="spinner-container">
          <Col md={24}>
            <Row className="spinner-container__internal-row" justify="center" align="middle">
              <Spin size="large" />
            </Row>
          </Col>
        </Row>
      )) ||
        (((status === 'idle' && !addLoading) || (status === 'success' && !isLoading)) && (
          <Row className="add-question">
            <Col className="add-question__content-container">
              <Title className="add-question__title">
                {action === ADD ? `${ADDING}` : `${EDITING}`} {QUESTION}
              </Title>
              <Col className="add-question__form-container">
                <Form
                  preserve={false}
                  onFieldsChange={() => {
                    setRender(prev => !prev);
                  }}
                  requiredMark={false}
                  className="add-question__form add-question-form"
                  layout="vertical"
                  onFinish={handleOnFinish}
                  form={form}
                  autoComplete="off"
                >
                  <InputSection
                    wasFormSubmitted={wasFormSubmitted}
                    form={form}
                    handleGetFormFieldValue={handleGetFormFieldValue}
                    handleSetFieldsValue={handleSetFieldsValue}
                    action={action}
                  />
                  <Row gutter={[40, 0]}>
                    <RadioSection
                      handleGetFormFieldValue={handleGetFormFieldValue}
                      handleSetFieldsValue={handleSetFieldsValue}
                      wasFormSubmitted={wasFormSubmitted}
                      action={action}
                    />
                    <Col className="instruction-container" span={24}>
                      <CustomBBCodeEditor
                        name={INSTRUCTIONS}
                        message={QUESTION_CONTENT}
                        handleSetFieldsValue={handleSetFieldsValue}
                        handleSetAnswersState={handleSetAnswersState}
                        validationMessage={ENTER_QUESTION}
                        setEditorValue={setEditorValue}
                      />
                      <Form.Item name={QUESTION_IMAGE} noStyle>
                        <ImageUpload
                          setAnswers={setAnswers}
                          handleSetFieldsValue={handleSetFieldsValue}
                          name={INSTRUCTIONS}
                          link={pictureLink}
                          action={EDIT}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <AnswerSection
                        handleSetFieldsValue={handleSetFieldsValue}
                        setAnswers={setAnswers}
                        handleGetFormFieldValue={handleGetFormFieldValue}
                        answers={answers}
                        handleSetAnswersState={handleSetAnswersState}
                        action={action}
                        contentImageLink={contentImageLink}
                        setEditorValue={setEditorValue}
                      />
                    </Col>
                  </Row>
                  <Row className="action-container--save">
                    <Link to={{ pathname: '/questions' }}>
                      <CustomButton className="custom-button--resign">{CANCEL}</CustomButton>
                    </Link>
                    <CustomButton htmlType="submit" className="custom-button--submit-question">
                      {SAVE}
                    </CustomButton>
                  </Row>
                </Form>
              </Col>
            </Col>
          </Row>
        )) ||
        (status === ERROR && (
          <Row className="spinner-container">
            <Col md={24}>
              <Row className="spinner-container__internal-row" justify="center" align="middle">
                <Title>{OOPS}</Title>
              </Row>
            </Col>
          </Row>
        ))}
    </>
  );
};

export default ManageQuestion;
