/* eslint-disable indent */
import { Form, Spin } from 'antd/index';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import axios from 'axios';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { usePageVisibility } from 'react-page-visibility';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { storage } from 'services/firebase';
import { generatedTestsCollectionRef, usersRef } from 'services/firestore/references';
import {
  updateTestClipboardData,
  updateTestDataAnswers,
  updateTestOnFinish,
  updateTestTabLeaves,
} from 'services/firestore/updateTestCollectionRef';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import { handlePageReload } from 'utils/handlePageReload';
import { setQuestionsStatus } from 'utils/setQuestionStatus';
//commented according to BB-826
// import FinishTest from '../../FinishTest/FinishTest'
import {
  QUESTION_CHOICE_TYPES,
  QUESTION_TYPES,
  SECONDS_TO_MINUTES,
  TEST_KIND_OPTIONS,
  STORAGE_PATH,
  sendNotificationEndpoint,
  TEST_QUESTIONS_WRAPPER_VIEW,
} from '../../../constants/index';
import { useStopwatch } from 'hooks/useTimer';
import { bool, func, string } from 'prop-types';
import {
  answersSummary,
  //commented according to BB-826
  // getResultCondition,
  checkIsOpenQuestion,
  checkPossibilityChoosingQuestions,
  getQuestionFromInstanceInfo,
  getQuestionInfo,
  getRemainingTime,
  getStartTime,
  getTestTime,
  getMemoizedTestData,
  goToNextQuestion,
  goToSelectQuestion,
  showAllQuestions,
  showQuestion,
  updateAnswer,
  getIsReloading,
} from '../TestSlice';
import SolvingTestUI from './SolvingTestUI';
import FinishedTest from '../../FinishedTest/FinishedTest';
import { showInfoMessage } from '../../../utils/showInfoMessage';

const { POSSIBILITY_TO_RETURN, NO_RETURN_ACCEPT } = TEST_KIND_OPTIONS;
const { CLOSED, OPEN } = QUESTION_TYPES;
const { MULTI, SINGLE } = QUESTION_CHOICE_TYPES;
const { SELECT_ONE_ANSWER, SELECT_MULTI_ANSWER, RELOADING_WARNING } = TEST_QUESTIONS_WRAPPER_VIEW;

const handleFileAnswer = async (hasAnswerfile, generatedId, questionId) => {
  if (!hasAnswerfile) {
    return;
  }
  const storageFileRef = storage.ref(`${STORAGE_PATH.TEST_TO_SOLVE}/${generatedId}/${questionId}`);

  try {
    return await storageFileRef.getDownloadURL();
  } catch (errorMessage) {
    return '';
  }
};

const SolvingTest = ({ isTestFinished, setIsTestFinished, startTest, isTestCompleted }) => {
  const possibilityChooseQuestions = useSelector(checkPossibilityChoosingQuestions);
  const testTime = useSelector(getTestTime);
  const startTime = useSelector(getStartTime) || Date.now();
  const remainingTimeFromDB = useSelector(getRemainingTime);
  const isReloadingFromDB = useSelector(getIsReloading);
  const remainingTimeTestWithOptionChooseQuestion = testTime * 60000 - (Date.now() - startTime);
  const isOpenQuestion = useSelector(checkIsOpenQuestion);
  const { correctAnswers, answers } = useSelector(answersSummary);
  const [answerFileExtension, setAnswerFileExtension] = useState('');
  const [answerFileName, setAnswerFileName] = useState('');
  const confirmAnswer = possibilityChooseQuestions === NO_RETURN_ACCEPT;
  //commented according to BB-826
  // const resultCondition = useSelector(getResultCondition)
  const { presentQuestionIndex, numberOfQuestions } = useSelector(getQuestionInfo);
  const { instructions, subjects, title, type, choice, hasAnswerFile, isWithCode } = useSelector(
    getQuestionFromInstanceInfo,
  );
  const isLastQuestion = presentQuestionIndex === numberOfQuestions - 1;
  const isVisible = usePageVisibility();
  const [mouseLeaves, setMouseLeaves] = useState(0);
  const [dataFromClipboardToAllTest, setFromClipboardToAllTest] = useState([]);
  const [leaveTabsCount, setLeaveTabCount] = useState(0);
  const [disableButtons, setDisableButtons] = useState(false);
  const [hasFile, setHasFile] = useState(false);
  const [formState, setFormState] = useState();
  const [dataFromClipboard, setDataFromClipboard] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFinishWarning, setIsFinishWarning] = useState(false);
  const currentQuestion = useSelector(showQuestion);
  const allQuestions = useSelector(showAllQuestions);
  const {
    time: questionTime,
    type: questionType,
    answers: questionAnswers,
    choice: questionChoice,
    id: questionId,
  } = currentQuestion;
  const [remainingTime, setRemainingTime] = useState(
    possibilityChooseQuestions === POSSIBILITY_TO_RETURN
      ? remainingTimeFromDB
        ? remainingTimeFromDB
        : remainingTimeTestWithOptionChooseQuestion / SECONDS_TO_MINUTES
      : questionTime,
  );
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { generatedId } = useParams();
  const clockRef = useRef();
  const handleStart = () => clockRef.current?.start();
  const handlePause = () => clockRef.current?.pause();
  const { elapsedTime, startTimer, stopTimer, resetTimer } = useStopwatch();

  const { test } = useSelector(getMemoizedTestData);

  const fetchData = async () => {
    const response = await usersRef.where('notification', '==', true).where('role', 'in', ['Admin', 'Technical']).get();
    return response.docs.map(document => document.data());
  };

  //NOTE: navigator.clipboard.writeText to refactor, in current form due to the Safari browser

  useEffect(() => {
    if (isTestFinished) {
      window.onbeforeunload = function () {
        return null;
      };
    }
  }, [isTestFinished]);
  useEffect(() => {
    possibilityChooseQuestions !== POSSIBILITY_TO_RETURN && setRemainingTime(questionTime);
    setMouseLeaves(0);
    setLeaveTabCount(0);
    setDataFromClipboard([]);
    navigator.clipboard.writeText('').then(
      () => {
        /* Resolved - text copied to clipboard */
      },
      () => {
        /* Rejected - clipboard failed */
      },
    );
  }, [currentQuestion]);

  useEffect(() => {
    navigator.clipboard.writeText('').then(
      () => {
        /* Resolved - text copied to clipboard */
      },
      () => {
        /* Rejected - clipboard failed */
      },
    );
    handlePageReload(generatedId, onFinish, setIsLoading);

    if (isReloadingFromDB && possibilityChooseQuestions !== POSSIBILITY_TO_RETURN) {
      showInfoMessage('warning', RELOADING_WARNING);
    }
  }, []);

  window.onbeforeunload = () => {
    updateCollectionDocument(generatedTestsCollectionRef, generatedId, { remainingTime, reloading: true });
  };
  const onFocus = () => {
    if (startTest) {
      navigator.clipboard
        .readText()
        .then(
          dataClipboard => {
            /**  checks if value already exists in clipboard  **/
            const cliboardItem = dataFromClipboard.filter(({ data }) => data === dataClipboard);
            if (startTest && dataClipboard.length && !cliboardItem.length) {
              setFromClipboardToAllTest(prev => [...prev, { data: dataClipboard, time: elapsedTime }]);
              setDataFromClipboard(prev => [...prev, { data: dataClipboard, time: elapsedTime }]);
            }
          },
          () => {
            /* Resolved - text copied to clipboard */
          },
        )
        .then(() => {
          stopTimer();
          navigator.clipboard.writeText('').then(
            () => {
              /* Resolved - text copied to clipboard */
            },
            () => {
              /* Rejected - clipboard failed */
            },
          );
        });
    }
  };
  useEffect(() => {
    startTest && !isVisible && closeTabHandle();
  }, [isVisible]);

  useEffect(() => {
    const uniqueDataFromClipBoard = dataFromClipboardToAllTest
      .filter(({ data }) => data.length > 0)
      .reduce((unique, item) => (unique.includes(item) ? unique : [...unique, item]), []);
    updateTestClipboardData(generatedId, uniqueDataFromClipBoard, resetTimer);
  }, [dataFromClipboard]);

  const sendmail = () => {
    fetchData().then(data => {
      axios.post(sendNotificationEndpoint, {
        link: `${window.location.origin}/verification?search=${encodeURIComponent(test.recruitedPerson?.trim())}`,
        emails: data.map(userEmail => userEmail?.email),
        candidateFullName: test?.recruitedPerson,
      });
    });
  };

  const closeTabHandle = () => {
    setLeaveTabCount(prev => prev + 1);
    updateTestTabLeaves(generatedId, leaveTabsCount);
  };

  const handleCancelClick = () => {
    setIsFinishWarning(false);
  };

  const handleFinishClick = () => {
    let allQuestionsAnswers = [...answers];
    if (answers.length < numberOfQuestions) {
      const questionsWihtAnswerids = answers.map(answer => answer.questionId);
      allQuestions.forEach(question => {
        if (!questionsWihtAnswerids.includes(question.id)) {
          const dataToUpdate = {
            answers: '',
            correct: false,
            time: question.time,
            dataFromClipboard,
            instructions: question.instructions,
            leaveTabsCount: 0,
            mouseLeaves: 0,
            code: question.code,
            isWithCode: question.isWithCode,
            subjects: question.subjects,
            title: question.title,
            type: question.type,
            isExamined: true,
            questionId: question.id,
            hasAnswerFile: question.hasAnswerFile,
            hasFile: false,
          };
          dispatch(updateAnswer(dataToUpdate));
          updateTestDataAnswers(
            generatedId,
            presentQuestionIndex,
            allQuestionsAnswers,
            dataToUpdate,
            possibilityChooseQuestions,
          );
          allQuestionsAnswers = [...allQuestionsAnswers, dataToUpdate];
        }
      });
    }
    const endTime = Date.now() - startTime;
    updateTestOnFinish(
      generatedId,
      correctAnswers,
      numberOfQuestions,
      isOpenQuestion,
      allQuestionsAnswers,
      [],
      endTime,
      sendmail,
    );
    setIsTestFinished(true);
  };

  const handleOnMouseLeave = () => {
    setMouseLeaves(prevState => prevState + 1);
  };

  const handleFinishQuiz = (answers, dataToUpdate) => {
    const endTime = Date.now() - startTime;
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      handleFinishClick();
    } else {
      updateTestOnFinish(
        generatedId,
        correctAnswers,
        numberOfQuestions,
        isOpenQuestion,
        answers,
        dataToUpdate,
        endTime,
        sendmail,
      );
      setIsTestFinished(true);
    }
  };

  const handleSelectQuestion = questionIndex => {
    dispatch(goToSelectQuestion(questionIndex));
  };

  const questionStatus = useMemo(() => setQuestionsStatus(allQuestions, answers, presentQuestionIndex), [answers]);
  const onFinish = async (values, isEnd) => {
    if (isEnd) {
      if (
        answers.length < numberOfQuestions &&
        remainingTime > 1000 &&
        possibilityChooseQuestions === POSSIBILITY_TO_RETURN
      ) {
        setIsFinishWarning(true);
      } else {
        handleFinishQuiz(answers, []);
        form.resetFields();
        setFormState();
      }
    } else {
      const fileAnswerValue = await handleFileAnswer(hasAnswerFile, generatedId, questionId);
      let answersValue;
      let correctValue = false;
      let correctAnswer;
      switch (questionType) {
        case OPEN:
          answersValue = values?.openQuestionCodeAnswer || values?.openQuestionAnswer || '';
          correctValue = null;
          break;
        case CLOSED:
          if (values?.answers) {
            switch (questionChoice) {
              case SINGLE:
                correctAnswer = questionType !== OPEN && questionAnswers.find(({ isCorrect }) => isCorrect);
                correctValue = values?.answers === correctAnswer.id;
                answersValue = [values.answers];
                break;
              case MULTI:
                correctAnswer =
                  questionType !== OPEN && questionAnswers.filter(({ isCorrect }) => isCorrect).map(({ id }) => id);
                correctValue =
                  correctAnswer.every(item => values?.answers.includes(item)) &&
                  correctAnswer.length === values?.answers.length;
                answersValue = [{ ...values.answers }];
                break;
              default:
                break;
            }
          } else {
            answersValue = [''];
          }
          form.resetFields();
          break;
        default:
          break;
      }
      setDisableButtons(false);
      const acualQuestionAnswer = hasAnswerFile
        ? typeof values?.uploadItem === 'undefined' || values?.uploadItem?.fileList?.length === 0
          ? ''
          : [{ fileAnswerValue, answerFileName, answerFileExtension }]
        : answersValue;

      if (
        (!acualQuestionAnswer ||
          acualQuestionAnswer === [''] ||
          acualQuestionAnswer === '' ||
          !acualQuestionAnswer.length) &&
        type === OPEN
      ) {
        correctValue = false;
      }
      const dataToUpdate = {
        answers: acualQuestionAnswer,
        correct: correctValue,
        hasAnswerFile,
        answerFileExtension: hasAnswerFile ? answerFileExtension : '',
        time: questionTime - remainingTime,
        dataFromClipboard,
        instructions,
        isWithCode,
        leaveTabsCount,
        mouseLeaves,
        subjects,
        title,
        type,
        isExamined: type === CLOSED ? true : !(acualQuestionAnswer !== '' && acualQuestionAnswer.length),
        questionId,
        hasFile: !!(fileAnswerValue && hasAnswerFile),
      };
      if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
        delete dataToUpdate.time;
      }
      dispatch(updateAnswer(dataToUpdate));
      updateTestDataAnswers(generatedId, presentQuestionIndex, answers, dataToUpdate, possibilityChooseQuestions);

      if (possibilityChooseQuestions !== POSSIBILITY_TO_RETURN) {
        form.resetFields();
        setFormState();
        if (presentQuestionIndex !== numberOfQuestions - 1) {
          dispatch(goToNextQuestion());
          handleStart();
        } else {
          handleFinishQuiz(answers, [dataToUpdate]);
        }
      }
    }
  };
  const handleConfirm = () => {
    setDisableButtons(true);
    handlePause();
  };

  const handleMultiCheckForm = useCallback(event => {
    setFormState(event.target.value);
  }, []);

  if (!currentQuestion) {
    return null;
  }

  if (isTestFinished) {
    //commented according to BB-826
    // if (resultCondition) {
    //   return <FinishTest generatedId={generatedId} />
    // }
    return (
      <FinishedTest
        numberOfQuestions={numberOfQuestions}
        correctAnswers={correctAnswers.length}
        generatedId={generatedId}
        startTime={startTime}
      />
    );
  }

  window.onblur = () => {
    startTimer();
  };
  window.onfocus = () => {
    onFocus();
    isTestCompleted();
  };

  if (isLoading) {
    return <Spin className="spinner-container" />;
  }
  const handleComplete = () => {
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      onFinish(null, true);
    } else {
      form.submit();
    }
  };
  const checkForMultipleAnswers = ({ answers }) => {
    let counter = 0;
    answers?.forEach(answer => (answer.isCorrect ? counter++ : counter));
    return counter < 2 ? SELECT_ONE_ANSWER : SELECT_MULTI_ANSWER;
  };

  return (
    <SolvingTestUI
      handleOnMouseLeave={handleOnMouseLeave}
      areMultipleValuesCorrect={checkForMultipleAnswers}
      currentQuestion={currentQuestion}
      setRemainingTime={setRemainingTime}
      remainingTime={remainingTime}
      handleComplete={handleComplete}
      clockRef={clockRef}
      setAnswerFileExtension={setAnswerFileExtension}
      setAnswerFileName={setAnswerFileName}
      hasFile={hasFile}
      setHasFile={setHasFile}
      onFinish={onFinish}
      disableButtons={disableButtons}
      setFormState={setFormState}
      handleMultiCheckForm={handleMultiCheckForm}
      confirmAnswer={confirmAnswer}
      presentQuestionIndex={presentQuestionIndex}
      numberOfQuestions={numberOfQuestions}
      possibilityChooseQuestions={possibilityChooseQuestions}
      form={form}
      handleConfirm={handleConfirm}
      isLastQuestion={isLastQuestion}
      choice={choice}
      formState={formState}
      type={type}
      handleSelectQuestion={handleSelectQuestion}
      questionStatus={questionStatus}
      testTime={testTime}
      isFinishWarning={isFinishWarning}
      handleCancelClick={handleCancelClick}
      handleFinishQuiz={handleFinishClick}
    />
  );
};
SolvingTest.propTypes = {
  isTestFinished: bool,
  setIsTestFinished: func,
  startTest: bool,
  setIsDataFetched: func,
  testTitle: string,
  isTestCompleted: func,
};
export default SolvingTest;
