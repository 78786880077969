// Some stuff is left because it is unknown how this table will look in nearest future
//@ts-nocheck
import { ClockCircleOutlined, Col, Modal, Paragraph, Row, Spin, Text } from 'antd/index';
import { RootState } from 'App/store';
import classNames from 'classnames';
import CustomTableContainer from 'components/CustomTable/CustomTableContainer';
import {
  QUESTION_TYPES,
  SECONDS_TO_MINUTES,
  TEST_STATUSES,
  TEST_KIND_OPTIONS,
  LIST_FINISHED_TEST_DETAILS,
  CUSTOM_TIME_PICKER,
} from 'constants/index';
import { useFetchCollectionDocumentFromFirestoreOnSnapshot } from 'hooks/useFetchCollectionDocumentFromFirestore';
import moment from 'moment';
import { FC, SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { DocumentData } from 'services/firebase';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import checkIfTestHasExpiredStatus from 'utils/checkIfTestHasExpiredStatus';
import { formatDate } from 'utils/formatDate';
import { getFilteredAnswersData } from 'utils/getFilteredAnswersData';
import { percentageOfNumber } from 'utils/getPercentageResult';
import { getTestCategories } from 'utils/getTestSpecificLanguagesResult';
import DetailedOpenQuestionInfoModal from './DetailedOpenQuestionInfoModal';
import DetailedQuestionInfoModal from './DetailedQuestionInfoModal';
import { TAnswerClassName } from './DetailedQuestionInfoModal.types';
import questionsTableShape from './tableShape/questionsTableShape';

const { FINISHED, FILLED, EXPIRED } = TEST_STATUSES;
const { OPEN, CLOSED, ANSWER_FROM_FILE } = QUESTION_TYPES;
const finishTestStatuses = [FINISHED, FILLED];

const cancelButton = { style: { display: 'none' } };
const {
  CLOSED_QUESTIONS,
  OPEN_QUESTIONS,
  FILE_QUESTIONS,
  MISTAKES,
  UNANSWERED_QUESTIONS,
  EXPIRED_ON,
  NO_SOLVED,
  CARD_EXIT_TIME,
  TIME_SPENT,
  OVERALL,
  RECRUITED_PERSON,
  AUTHOR,
  STATUS,
  CLIPBOARD_DATA,
  CLOSE,
  CATEGORY,
  RESPONSE_TIME,
  TERMINATION_DATE,
  // MOUSE_LEAVES,
  TABS_LEAVES,
} = LIST_FINISHED_TEST_DETAILS;
const { MIN, SEC } = CUSTOM_TIME_PICKER;

const ListFinishTestDetails: FC = () => {
  const { resultId } = useParams<{ resultId: string }>();
  const history = useHistory();
  const resultData: DocumentData | undefined = useFetchCollectionDocumentFromFirestoreOnSnapshot(
    generatedTestsCollectionRef,
    resultId,
  );

  const [previewResultModalData, setPreviewResultModalData] = useState<any>();
  const {
    finishedAt,
    status,
    answers,
    numberOfQuestions,
    expireTime,
    startedAt,
    createdBy,
    recruitedPerson,
    time,
    testKind,
  }: DocumentData = resultData || {};
  const role = useSelector((state: RootState) => state.auth.currentUser!.role);

  const [isLoading, setIsLoading] = useState<SetStateAction<boolean>>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isNoteModalVisible, setIsNoteModalVisible] = useState<boolean>(false);
  const [dataFromClipboard, setDataFromClipboard] = useState([]);
  const [isClosedQuestionInfoVisible, setIsClosedQuestionInfoVisible] = useState<boolean>(false);
  const [selectedClosedQuestion, setSelectedClosedQuestion] = useState<string>('');
  const [isQuestionPreviewExpanded, setIsQuestionPreviewExpanded] = useState<boolean>(false);

  const toggleQuestionPreview = () => setIsQuestionPreviewExpanded(prevState => !prevState);

  const getAnswerClassName: TAnswerClassName = (userChoice, isCorrect) =>
    `candidate-answer__item candidate-answer__answers ${
      userChoice ? (isCorrect ? 'candidate-answer__answers--correct' : 'candidate-answer__answers--incorrect') : ''
    }`;

  const {
    closedAnswers,
    openAnswers,
    answersFromFile,
    closedAnswersWithNum,
    openAnswersWithNum,
    answersFromFileWithNum,
    numberOfCorrectAnswers,
    numberOfEmptyAnswers,
  } = getFilteredAnswersData(answers) || {};

  const handleOk = () => {
    setIsNoteModalVisible(false);
    setIsClosedQuestionInfoVisible(false);
    setIsQuestionPreviewExpanded(false);
  };

  const handleCancel = () => {
    setIsNoteModalVisible(false);
    setIsClosedQuestionInfoVisible(false);
    setIsQuestionPreviewExpanded(false);
  };

  const percentageGeneralResult = percentageOfNumber(numberOfCorrectAnswers, numberOfQuestions);

  const isTestCompleted = finishTestStatuses.includes(status);
  const undoneAnswers = `${numberOfEmptyAnswers} / ${numberOfQuestions}`;
  const emptyToAllRatio = Math.round((numberOfEmptyAnswers / numberOfQuestions) * 100);

  const numOfCorrectClosedQuestionsAnswers = closedAnswers?.filter(({ correct }) => correct === true).length;
  const numOfAllClosedQuestionsAswers = closedAnswers?.length;
  const percentageOfCorrectClosedQuestionsAnswers = percentageOfNumber(
    numOfCorrectClosedQuestionsAnswers,
    numOfAllClosedQuestionsAswers,
  );
  const numOfCorrectOpenQuestionsAnswers = openAnswers?.filter(({ correct }) => correct === true).length;

  const numOfAllOpenQuestionsAnswers = openAnswers?.length;
  const percentageOfCorrectOpenQuestionsAnswers = percentageOfNumber(
    numOfCorrectOpenQuestionsAnswers,
    numOfAllOpenQuestionsAnswers,
  );
  const numOfCorrectFromFileQuestionsAnswers = answersFromFile?.filter(({ correct }) => correct === true).length;
  const numOfAllFromFileQuestionsAswers = answersFromFile?.length;
  const percentageOfCorrectFromFileQuestionsAnswers = percentageOfNumber(
    numOfCorrectFromFileQuestionsAnswers,
    numOfAllFromFileQuestionsAswers,
  );

  useEffect(() => {
    checkIfTestHasExpiredStatus(status, expireTime, resultId);
    if (resultData) {
      getTestCategories(answers);
      setIsLoading(false);
    }
  }, [resultData]);

  if (isLoading) {
    return <Spin className="spinner-container" />;
  }

  const testTime = `${Math.floor(time / 1000 / SECONDS_TO_MINUTES)} ${MIN} ${Math.round(
    (time % (SECONDS_TO_MINUTES * 1000)) / 1000,
  )} ${SEC}`;

  const handleOnClick = () => {
    handleOk();
    setPreviewResultModalData('');
  };

  const closedQuestionsColumnsHandlers = {
    history,
    role,
    resultId,
    setDataFromClipboard,
    setIsModalVisible,
    setIsNoteModalVisible,
    questionType: CLOSED,
    testKind,
    setSelectedClosedQuestion,
    setIsClosedQuestionInfoVisible,
  };
  const openQuestionsColumnsHandlers = {
    history,
    role,
    resultId,
    setDataFromClipboard,
    setIsModalVisible,
    setIsNoteModalVisible,
    setPreviewResultModalData,
    questionType: OPEN,
    testKind,
  };
  const fromFileQuestionsColumnsHandlers = {
    history,
    role,
    resultId,
    setDataFromClipboard,
    setIsModalVisible,
    setIsNoteModalVisible,
    setPreviewResultModalData,
    questionType: ANSWER_FROM_FILE,
    testKind,
  };
  const tableHeaderQlosedQuestions = (percentageResult, questionType) => {
    return (
      <Row className="table-header">
        <Paragraph
          className={`table-header__title ${
            testKind !== TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN ? 'first' : 'withoutQuestionTime'
          }`}
        >
          <Text>
            {questionType === CLOSED && `${CLOSED_QUESTIONS}`}
            {questionType === OPEN && { OPEN_QUESTIONS }}
            {questionType === ANSWER_FROM_FILE && { FILE_QUESTIONS }}
          </Text>
        </Paragraph>
        {(questionType === CLOSED || questionType === OPEN || questionType === ANSWER_FROM_FILE) && (
          <>
            <Paragraph className="table-header__title second">
              <Text>{CATEGORY}</Text>
            </Paragraph>
            {testKind !== TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN && (
              <Paragraph className="table-header__title third">
                <Text>{RESPONSE_TIME}</Text>
              </Paragraph>
            )}
          </>
        )}

        {/*<Paragraph className="table-header__title fourth">*/}
        {/*  {questionType === CLOSED && <Text>{MOUSE_LEAVES}</Text>}*/}
        {/*</Paragraph>*/}
        <Paragraph className="table-header__title fifth">
          {questionType === CLOSED && <Text>{TABS_LEAVES}</Text>}
        </Paragraph>

        {/*<Paragraph*/}
        {/*  className={classNames({*/}
        {/*    'table-header__title sixth': true,*/}
        {/*    percentage: true,*/}
        {/*    warning: percentageResult >= 30 && percentageResult < 70,*/}
        {/*    success: percentageResult >= 70,*/}
        {/*    danger: percentageResult < 30,*/}
        {/*  })}*/}
        {/*>*/}
        {/*  <Text>{percentageResult}%</Text>*/}
        {/*</Paragraph>*/}
      </Row>
    );
  };

  return (
    <>
      <Row className="col-margin details">
        <Col md={24} className="details__header">
          <div className="left-box">
            <div className="test-insides">
              <Paragraph className="header-text header-text--subjects">
                {' '}
                {resultData && resultData.subjects.join(', ')}
              </Paragraph>
              <Paragraph className="header-text">{resultData && resultData.level} </Paragraph>
              <Paragraph className="header-text test-insides__status">{status}</Paragraph>
            </div>
            <div className="test-time-taken">
              <Paragraph className="header-text header-text--time">{TIME_SPENT}</Paragraph>
              <Paragraph className="header-text">
                <ClockCircleOutlined className="test-time-taken__icon" />{' '}
              </Paragraph>
              <Paragraph className="header-text test-time-taken__time">{testTime}</Paragraph>
            </div>
          </div>
          <div
            className={classNames({
              'right-box': true,
              'right-box--danger': percentageGeneralResult < 30,
              'right-box--warning': percentageGeneralResult >= 30 && percentageGeneralResult < 70,
              'right-box--success': percentageGeneralResult >= 70,
            })}
          >
            <Paragraph>{OVERALL}</Paragraph>
            <Paragraph
              className={classNames({
                succes: percentageGeneralResult >= 70,
                warning: percentageGeneralResult >= 30 && percentageGeneralResult < 70,
                danger: percentageGeneralResult < 30,
              })}
            >
              {percentageGeneralResult}%
            </Paragraph>
          </div>
        </Col>
        <Col md={24} className="details__summary">
          <div className="left-box">
            <div className="box-container">
              <Paragraph className="box-container__title">{RECRUITED_PERSON}</Paragraph>
              <Paragraph className="box-container__text">{recruitedPerson}</Paragraph>
            </div>
            <div className="box-container">
              <Paragraph className="box-container__title">{TERMINATION_DATE}</Paragraph>
              <Paragraph className="box-container__text box-container__text--data">
                {moment(finishedAt).format('DD.MM.YYYY')}
                <Text className="time">
                  {moment(startedAt).format('HH:mm')} - {moment(finishedAt).format('HH:mm')}
                </Text>
              </Paragraph>
            </div>
          </div>
          <div className="right-box">
            <div className="right-box__result">
              <Paragraph className="right-box__result--title">{MISTAKES}</Paragraph>
              <div className="result-bottom">
                <Paragraph className="result-bottom--description">{UNANSWERED_QUESTIONS}</Paragraph>
                <Paragraph className="result-bottom--result">
                  {undoneAnswers}{' '}
                  <Text
                    className={classNames({
                      percent: true,
                      'percent--danger': emptyToAllRatio >= 70,
                      'percent--warning': emptyToAllRatio >= 30 && emptyToAllRatio < 70,
                      'percent--success': emptyToAllRatio < 30,
                    })}
                  >
                    {emptyToAllRatio}%
                  </Text>
                </Paragraph>
              </div>
            </div>
          </div>
        </Col>
        {isTestCompleted ? (
          <Col md={24}>
            {!!closedAnswers.length && (
              <CustomTableContainer
                headerTitle={tableHeaderQlosedQuestions}
                questionType={CLOSED}
                classNameTable={'analysis-question-table'}
                columnsHandlers={closedQuestionsColumnsHandlers}
                dataToRender={closedAnswersWithNum}
                locale={{ emptyText: 'No Tests' }}
                tableShape={questionsTableShape}
                pagination={false}
                percentageResult={percentageOfCorrectClosedQuestionsAnswers}
                testKind={testKind}
              />
            )}
            {!!openAnswers.length && (
              <>
                <CustomTableContainer
                  headerTitle={tableHeaderQlosedQuestions}
                  questionType={OPEN}
                  classNameTable={'analysis-question-table'}
                  columnsHandlers={openQuestionsColumnsHandlers}
                  dataToRender={openAnswersWithNum}
                  locale={{ emptyText: 'No Tests' }}
                  tableShape={questionsTableShape}
                  pagination={false}
                  percentageResult={percentageOfCorrectOpenQuestionsAnswers}
                  testKind={testKind}
                />
              </>
            )}
            {!!answersFromFile.length && (
              <CustomTableContainer
                headerTitle={tableHeaderQlosedQuestions}
                questionType={ANSWER_FROM_FILE}
                classNameTable={'analysis-question-table'}
                columnsHandlers={fromFileQuestionsColumnsHandlers}
                dataToRender={answersFromFileWithNum}
                locale={{ emptyText: 'No Tests' }}
                tableShape={questionsTableShape}
                pagination={false}
                percentageResult={percentageOfCorrectFromFileQuestionsAnswers}
                testKind={testKind}
              />
            )}
          </Col>
        ) : (
          <>
            <Row className="test-results-row " justify="start" align="middle">
              <Col md={12} className="test-results-modal__summary-title">
                <Row justify="start">{STATUS}</Row>
              </Col>
              <Col md={12} className="test-results-modal__summary-value">
                <Row justify="end">{status}</Row>
              </Col>
            </Row>
            <Row justify="center" align="middle" className="details-title__info">
              {status === EXPIRED ? `${EXPIRED_ON} ${formatDate(expireTime, 'DD.MM.YYYY')}` : `${NO_SOLVED}`}
            </Row>
          </>
        )}
      </Row>
      <Row>
        <Modal
          cancelButtonProps={cancelButton}
          title={CLIPBOARD_DATA}
          visible={isModalVisible}
          onOk={() => setIsModalVisible(false)}
          okText={CLOSE}
          onCancel={() => setIsModalVisible(false)}
          className="answer-details__modal"
          destroyOnClose
          centered
        >
          {dataFromClipboard?.map(({ data, time }, index) => (
            <Row key={index} className="element_top_margin_minimal marg-left-1rem" justify="start" align="middle">
              <Row justify="start">{CARD_EXIT_TIME}</Row>
              <Row justify="end">{`${data} / ${time} s`}</Row>
            </Row>
          ))}
        </Modal>
        {isNoteModalVisible && (
          <DetailedOpenQuestionInfoModal
            cancelButton={cancelButton}
            isNoteModalVisible={isNoteModalVisible}
            handleOk={handleOk}
            handleCancel={handleCancel}
            handleOnClick={handleOnClick}
            previewResultModalData={previewResultModalData}
            setDataFromClipboard={setDataFromClipboard}
            setIsModalVisible={setIsModalVisible}
            toggleQuestionPreview={toggleQuestionPreview}
            isQuestionPreviewExpanded={isQuestionPreviewExpanded}
            getAnswerClassName={getAnswerClassName}
          />
        )}
        {isClosedQuestionInfoVisible && (
          <DetailedQuestionInfoModal
            cancelButton={cancelButton}
            isClosedQuestionInfoVisible={isClosedQuestionInfoVisible}
            selectedClosedQuestion={selectedClosedQuestion}
            handleOk={handleOk}
            handleCancel={handleCancel}
            getAnswerClassName={getAnswerClassName}
          />
        )}
      </Row>
    </>
  );
};

export default ListFinishTestDetails;
