import { useEffect, useState } from 'react';
import { db, DocumentData } from 'services/firebase';

const useFetchCollectionFromFirestore = collectionRef => {
  const [data, setData] = useState<DocumentData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    const response = await db.collection(collectionRef).get();
    const collection = response.docs.map(document => {
      return document.data();
    });
    setData(collection);
  };

  useEffect(() => {
    fetchData().then(() => setIsLoading(false));
  }, [collectionRef]);
  return { data, isLoading };
};
export default useFetchCollectionFromFirestore;

export const useFetchTestCollectionFromFirestore = collectionRef => {
  const [data, setData] = useState<DocumentData[]>();
  const [isFetchedData, setIsFetchedData] = useState(false);
  async function fetchData() {
    const response = await db.collection(collectionRef).get();
    const collection = response.docs.map(document => {
      const documentId = document.id;
      const item = document.data();
      const newItem = { ...item, generatedId: documentId };
      return newItem;
    });
    setData(collection);
  }

  useEffect(() => {
    fetchData().then(() => setIsFetchedData(true));
  }, [collectionRef]);
  return { data, isFetchedData };
};

export const useFetchTestCollectionFromFirestoreOnSnapshot = collectionRef => {
  const [data, setData] = useState<DocumentData[]>();
  const [isFetchedData, setIsFetchedData] = useState(false);
  async function fetchData() {
    await collectionRef.onSnapshot(querySnapshot => {
      setData(querySnapshot.docs.map(change => change.data()));
    });
  }

  useEffect(() => {
    fetchData().then(() => setIsFetchedData(true));
  }, [collectionRef]);
  return { data, isFetchedData };
};
