//@ts-nocheck

import PropTypes from 'prop-types';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useTableChange from 'hooks/useTableChange';
import { clearFilters } from 'views/TableShapes/filtersSlice';
import PrivateRoute from './PrivateRoute';
import { AUTH_ROUTES } from './auth';
import { HOME_ROUTES } from './home';
import { QUESTIONS_ROUTES } from './questions';
import { RESULTS_ROUTES } from './results';
import { TESTS_ROUTES } from './tests';
import { SETTINGS_ROUTES } from './settings';
import { CANDIDATE_ROUTES } from './candidate';
import { CONTESTS_ROUTES } from './contests';
import { RedirectHandler } from 'components/RedirectHandler/RedirectHandler';
import { FINISHED_ROUTES } from './finished-test';
import { INITIAL_VIEW } from './initial-view';
import { PATH_APP } from 'constants/index';

const { RESULTS } = PATH_APP;

// Combining all of the auth routes modules into single element
// whenever You want to add a new route to the app, You need to add redirect rule for it in RedirectHandler.jsx file
const AUTH_APP_ROUTES = [].concat(
  HOME_ROUTES,
  QUESTIONS_ROUTES,
  RESULTS_ROUTES,
  TESTS_ROUTES,
  SETTINGS_ROUTES,
  CONTESTS_ROUTES,
);

export const Routes = () => {
  const dispatch = useDispatch();
  // Clear filters on table view change
  useTableChange(() => dispatch(clearFilters()));
  // Check if logged-in user and adjust /results route
  const currentUser = useSelector(state => state.auth.currentUser);

  const AUTH_ROUTES_NO_RESULTS = AUTH_ROUTES.filter(route => route.path !== RESULTS);
  const NON_AUTH_APP_ROUTES = [].concat(
    !currentUser ? AUTH_ROUTES : AUTH_ROUTES_NO_RESULTS,
    CANDIDATE_ROUTES,
    FINISHED_ROUTES,
    INITIAL_VIEW,
  );

  return (
    <Router>
      <Switch>
        {NON_AUTH_APP_ROUTES.map(({ component, path, key }) => (
          <Route path={path} exact component={component} key={key} />
        ))}
        <RedirectHandler>
          {AUTH_APP_ROUTES.map(({ component, path, key }) => (
            <PrivateRoute path={path} exact component={component} key={key} />
          ))}
        </RedirectHandler>
      </Switch>
    </Router>
  );
};

Routes.propTypes = {
  onRouteChange: PropTypes.func,
};
