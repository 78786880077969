/* eslint-disable react/prop-types */
//@ts-nocheck
import React, { FC, useState, useEffect, useMemo } from 'react';
import CustomTextArea from 'components/CustomTextArea/CustomTextArea';
import { IOpenQuestionContent } from '../QuestionsContentTypes';
import { Item } from 'antd/index';
import 'codemirror/theme/material-darker.css';

import { getSelectedAnswer } from 'utils/selectedAnswer';
import { Controlled as CodeMirror } from 'react-codemirror2';
import FileQuestion from '../ClosedQuestionContent/FileQuestion/FileQuestion';
import { shallowEqual, useSelector } from 'react-redux';
import { showQuestion } from 'views/TestQuestionsWrapper/TestSlice';
import { TEST_KIND_OPTIONS } from 'constants/index';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;

const OpenQuestionContent: FC<IOpenQuestionContent> = ({
  isWithCode,
  questionStatus,
  questionId,
  form,
  setAnswerFileExtension,
  setAnswerFileName,
  hasAnswerFile,
  hasFile,
  questionsId,
  setHasFile,
  possibilityChooseQuestions,
  disableButtons,
  handleResetValueOpenQuestion,
}) => {
  const { language, code } = useSelector(showQuestion, shallowEqual);
  const selectedAnswer = useMemo(() => getSelectedAnswer(questionStatus, questionId), [questionStatus, questionId]);
  const [textAreaCodeValue, setTextAreaCodeValue] = useState(code ? code.replaceAll('```', '') : '');
  const [textAreaValue, setTextAreaValue] = useState('');

  const handleResetCode = (): void => {
    setTextAreaCodeValue(code ? code.replaceAll('```', '') : '');
  };

  useEffect(() => {
    handleResetValueOpenQuestion.current = handleResetCode;
  }, [code]);

  useEffect(() => {
    if (!hasAnswerFile) {
      if (isWithCode) {
        setTextAreaCodeValue(!selectedAnswer ? (code ? code.replaceAll('```', '') : '') : selectedAnswer);
        form.setFieldsValue({
          openQuestionCodeAnswer: !selectedAnswer ? '' : selectedAnswer,
        });
      } else {
        setTextAreaValue(selectedAnswer);
        form.setFieldsValue({ openQuestionAnswer: selectedAnswer });
      }
    }
  }, [questionId]);

  return (
    <>
      {isWithCode ? (
        <>
          <CodeMirror
            value={textAreaCodeValue}
            options={{
              mode: language ? language.toLowerCase() : '',
              theme: 'material-darker',
              inputStyle: 'textarea',
              lineNumbers: true,
              lineWrapping: true,
              viewportMargin: Infinity,
              readOnly: !!disableButtons,
            }}
            onBeforeChange={(editor, data, value) => {
              if (value.length > 10000) {
                return;
              }
              setTextAreaCodeValue(value);
              form.setFieldsValue({ openQuestionCodeAnswer: value });
              if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
                form.submit();
              }
            }}
          />
          <Item name="openQuestionCodeAnswer" />
        </>
      ) : hasAnswerFile ? (
        <FileQuestion
          disabled={disableButtons}
          questionsId={questionsId}
          hasFile={hasFile}
          setHasFile={setHasFile}
          setAnswerFileExtension={setAnswerFileExtension}
          setAnswerFileName={setAnswerFileName}
          form={form}
          possibilityChooseQuestions={possibilityChooseQuestions}
          selectedAnswer={selectedAnswer}
        />
      ) : (
        <CustomTextArea
          disabled={disableButtons}
          name="openQuestionAnswer"
          value={textAreaValue}
          className="open-question-text-area"
          maxHeight={184}
          setTextAreaValue={setTextAreaValue}
          form={form}
          possibilityChooseQuestions={possibilityChooseQuestions}
        />
      )}
    </>
  );
};

export default React.memo(OpenQuestionContent);
