import { storage } from 'services/firebase';

const getStorageFileLink = async (id, file, metadata, name, url) => {
  if (!file) {
    return;
  }
  return typeof file === 'object'
    ? await storage
        .ref(`${url}/${id}/${name}`)
        .put(file, metadata)
        .then(snapshot => {
          return snapshot.ref.getDownloadURL();
        })
        .catch(error => {
          throw new Error(error);
        })
    : file;
};

export default getStorageFileLink;
