/* eslint-disable react/prop-types */
import { Col, Row, Spin } from 'antd/index';
import { USER_ROLES } from '../../constants/index';
import { useDebounce } from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  generatedTestsCollectionRef,
  testsCollectionRef,
  activeContestCollectionRef,
} from 'services/firestore/references';
import checkIfFiltered from 'utils/checkIfFiltered';
import deleteCollectionDocument from 'utils/collectionDocumentCRUD/deleteCollectionDocument';
import getAllDocumentsFromCollection from 'utils/collectionDocumentCRUD/getAllDocumentsFromCollection';
import updateCollectionDocument from 'utils/collectionDocumentCRUD/updateCollectionDocument';
import { filter, itemsFilter, searchFilter as searchedFilter } from 'utils/filterAll/filterTests';
import GenerateTestContainer from '../GenerateTest/GenerateTestContainer';
import TestListUI from './TestListUI';
import { setCategoryOptions, setSearchFilter, setTestKindOptions } from 'views/TableShapes/filtersSlice';
import ActiveContestModal from '../../components/ActiveContestModal/ActiveContestModal';
import { showInfoMessage } from '../../utils/showInfoMessage';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';

const { somethingWentWrong } = messages;

const filterTests = (tests, debouncedSearchTerm, categoryOptions, levelFilter, testKindOptions, isHiddenFor) => {
  let filteredTests = [...tests];
  filteredTests = filter(debouncedSearchTerm, filteredTests, 'title', searchedFilter);
  filteredTests = filter(categoryOptions, filteredTests, 'subjects', itemsFilter);
  if (levelFilter !== 'All') {
    filteredTests = filter(levelFilter, filteredTests, 'level', itemsFilter);
  }
  if (isHiddenFor && isHiddenFor !== 'All') {
    if (isHiddenFor === 'Expired') {
      filteredTests = filteredTests.filter(({ hiddenFor }) => hiddenFor);
    } else {
      filteredTests = filteredTests.filter(({ hiddenFor }) => !hiddenFor);
    }
  }
  if (testKindOptions.length) {
    filteredTests = filteredTests.filter(({ testKind }) => testKindOptions.includes(testKind));
  }
  return filteredTests;
};

const { RECRUITER, TECHNICAL, ADMIN, ROOT } = USER_ROLES;
const TestListContainer = props => {
  const { categoryOptions, searchFilter, testKindOptions } = useSelector(state => state.filters);

  const history = useHistory();
  const dispatch = useDispatch();
  const [isModalGeneratedTestVisible, setIsModalGeneratedTestVisible] = useState(false);
  const [tests, setTests] = useState([]);
  const [filterTable, setFilterTable] = useState(null);
  const [isHiddenFor, setIsHiddenFor] = useState('');
  const [levelFilter, setLevelFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteCheckDone, setIsDeleteCheckDone] = useState(false);
  const [idsOfValidTestsToDelete, setIdsOfValidTestsToDelete] = useState([]);
  const debouncedSearchTerm = useDebounce(searchFilter);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDuplicateVisible, setIsDuplicateVisible] = useState(false);
  const [record, setRecord] = useState(null);
  const { role } = useSelector(state => state.auth.currentUser);
  const isDataUserLoaded = useSelector(state => state.auth.isDataUserLoaded);
  const isRecruiter = role === RECRUITER;
  const isAdmin = role === ADMIN;
  const isRoot = role === ROOT;
  const roleUser = useSelector(state => state.settings);
  const isTechnical = role === TECHNICAL;
  const filtersArray = [levelFilter, searchFilter, isHiddenFor, categoryOptions.length];
  const areResultsFiltered = checkIfFiltered(filtersArray);
  const [isModalActiveVisible, setIsModalActiveVisible] = useState(false);
  const [selectedContestData, setSelectedContestData] = useState({ id: '', title: '' });
  const [activeContestId, setActiveContestId] = useState('');
  const [sortedFilterTable, setSortedFilterTable] = useState([]);

  const handleClearFilters = () => {
    setIsHiddenFor('');
    dispatch(setSearchFilter(''));
    setLevelFilter('');
    dispatch(setCategoryOptions([]));
    dispatch(setTestKindOptions([]));
  };

  useEffect(() => {
    const activeContestIdCleanup = activeContestCollectionRef.doc('activeContest').onSnapshot(
      snapshot => {
        setActiveContestId(snapshot.data()?.contestId);
      },
      error => {
        showInfoMessage('error', somethingWentWrong);
        console.log(error);
      },
    );
    return () => {
      activeContestIdCleanup();
    };
  }, []);

  useEffect(() => {
    const isAddTestModalVisible = props.history?.location.state?.isModalVisible;
    if (isAddTestModalVisible) {
      setIsModalVisible(isAddTestModalVisible);
    }
  }, []);

  /**
   * Firebase `where()` argument
   * based on current user role.
   */

  useEffect(() => {
    getAllDocumentsFromCollection(testsCollectionRef).then(data => {
      setTests(data);
    });
  }, [roleUser]);

  useEffect(() => {
    setFilterTable(filterTests(tests, debouncedSearchTerm, categoryOptions, levelFilter, testKindOptions, isHiddenFor));
  }, [tests]);

  useEffect(async () => {
    const generatedTests = await getAllDocumentsFromCollection(generatedTestsCollectionRef);
    if (tests.length) {
      setIdsOfValidTestsToDelete(
        tests.map(({ id }) => id).filter(id => generatedTests.some(({ testId }) => testId === id && id)),
      );
    }
    setIsDeleteCheckDone(true);
  }, [tests, history]);

  useEffect(() => {
    setFilterTable(filterTests(tests, debouncedSearchTerm, categoryOptions, levelFilter, testKindOptions, isHiddenFor));
  }, [categoryOptions, levelFilter, debouncedSearchTerm, isHiddenFor, testKindOptions]);

  useEffect(() => {
    if (filterTable) {
      const sortedFilterTable = [
        ...filterTable.filter(({ id }) => id === activeContestId),
        ...filterTable.filter(({ id }) => id !== activeContestId),
      ];
      setSortedFilterTable(sortedFilterTable);
    }
  }, [filterTable, activeContestId]);

  const initialState = JSON.parse(localStorage.getItem('numberOfItemsTestsPage'));

  const [numberOfItemsTestsPage, setNumberOfItemsTestsPage] = useState(initialState || 7);
  useEffect(() => {
    localStorage.setItem('numberOfItemsTestsPage', JSON.stringify(numberOfItemsTestsPage));
  }, [numberOfItemsTestsPage]);

  const handleDeleteTest = idOfDeletedTest => {
    const currentTests = tests.filter(test => test.id !== idOfDeletedTest);
    setTests(currentTests);
    setFilterTable(currentTests);
    deleteCollectionDocument(testsCollectionRef, idOfDeletedTest);
  };

  const handleHideTest = ({ id }) => {
    updateCollectionDocument(testsCollectionRef, id, { hiddenFor: true }).then(() => {
      const testsAfterHide = tests.filter(test => test.id !== id);
      setTests(testsAfterHide);
      setFilterTable(testsAfterHide);
    });
  };

  const showModalTest = () => {
    setIsModalVisible(true);
  };

  const hideModalTest = () => {
    setIsModalVisible(false);
  };

  const handleOnClickActivate = (id, title) => {
    setIsModalActiveVisible(true);
    setSelectedContestData({ id, title });
  };

  const onCategoryOptionsChange = categoryOptions => {
    dispatch(setCategoryOptions(categoryOptions));
  };

  const onTestKindOptionsChange = testKindOptions => {
    dispatch(setTestKindOptions(testKindOptions));
  };

  const onHideOptionChange = hideOption => {
    setIsHiddenFor(hideOption);
  };

  const onlevelFilterChange = filterOptions => {
    setLevelFilter(filterOptions);
  };

  return isDeleteCheckDone && idsOfValidTestsToDelete ? (
    <>
      <GenerateTestContainer
        isModalGeneratedTestVisible={isModalGeneratedTestVisible}
        setIsModalGeneratedTestVisible={setIsModalGeneratedTestVisible}
      />
      <ActiveContestModal
        activeContestId={activeContestId}
        selectedContestData={selectedContestData}
        isModalVisible={isModalActiveVisible}
        setIsModalVisible={setIsModalActiveVisible}
      />
      <TestListUI
        filterTable={sortedFilterTable}
        handleDeleteTest={handleDeleteTest}
        tests={tests}
        numberOfItemsTestsPage={numberOfItemsTestsPage}
        setNumberOfItemsTestsPage={setNumberOfItemsTestsPage}
        role={role}
        roleUser={roleUser}
        setIsHiddenFor={setIsHiddenFor}
        idsOfValidTestsToDelete={idsOfValidTestsToDelete}
        record={record}
        setRecord={setRecord}
        isRoot={isRoot}
        isAdmin={isAdmin}
        isTechnical={isTechnical}
        onCategoryOptionsChange={onCategoryOptionsChange}
        onTestKindOptionsChange={onTestKindOptionsChange}
        testKindOptions={testKindOptions}
        onlevelFilterChange={onlevelFilterChange}
        levelFilterOption={levelFilter}
        setSearchFilter={setSearchFilter}
        isModalVisible={isModalVisible}
        isDataUserLoaded={isDataUserLoaded}
        handleHideTest={handleHideTest}
        isRecruiter={isRecruiter}
        handleOnClickAddNew={showModalTest}
        hideModalTest={hideModalTest}
        handleOnClickActivate={handleOnClickActivate}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        areResultsFiltered={areResultsFiltered}
        handleClearFilters={handleClearFilters}
        categoryOptions={categoryOptions}
        searchFilter={searchFilter}
        isHiddenFor={isHiddenFor}
        onHideOptionChange={onHideOptionChange}
        isDuplicateVisible={isDuplicateVisible}
        setIsDuplicateVisible={setIsDuplicateVisible}
        activeContestId={activeContestId}
      />
    </>
  ) : (
    <Row className="spinner-container">
      <Col md={24}>
        <Row className="spinner-container__internal-row" justify="center" align="middle">
          <Spin size="large" />
        </Row>
      </Col>
    </Row>
  );
};
export default TestListContainer;
