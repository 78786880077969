export default function paginationSetting(
  defaultSize,
  currentPage,
  setCurrentPage,
  isSizeChangerShow,
  setNumberOfItemsOnPage,
  pageSizeOptions = [7, 14, 21],
) {
  return {
    defaultPageSize: defaultSize,
    pageSizeOptions: pageSizeOptions,
    position: ['bottomCenter'],
    defaultCurrent: currentPage,
    onChange: current => setCurrentPage(current),
    showSizeChanger: isSizeChangerShow,
    locale: { items_per_page: '/ page' },
    onShowSizeChange: (_, size) => setNumberOfItemsOnPage(size),
  };
}
