/* eslint react/prop-types: 0 */

import { TimePicker, CaretDownOutlined, Text, Col, Row, Item, Paragraph } from 'antd/index';

import { MANAGE_QUESTION_FORM_ITEM_NAMES, PLACEHOLDERS, CUSTOM_TIME_PICKER } from 'constants/index';

const { TIME } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { SELECT_TIME, MINUTES, SECONDS } = CUSTOM_TIME_PICKER;

const format = 'mm:ss';
const CustomTimePicker = ({ form, message }) => {
  return (
    <Item
      rules={[{ required: true, message: `${SELECT_TIME}` }]}
      name={TIME}
      label={<Paragraph className="add-question-form__label"> {message} </Paragraph>}
    >
      <TimePicker
        onSelect={time =>
          form.setFieldsValue({
            [TIME]: time,
          })
        }
        format={format}
        placeholder={PLACEHOLDERS.TIME_PICKER}
        popupClassName="custom-timepicker-dropdown"
        className="custom-timepicker add-question-form__input sf-pro-text"
        suffixIcon={<CaretDownOutlined />}
        dropdownMatchSelectWidth={true}
        hideDisabledOptions={true}
        secondStep={5}
        dropdownAlign={{ offset: [0, 8] }}
        disabledMinutes={() => [0]}
        panelRender={panel => (
          <>
            <Row className="custom-timepicker-content">
              <Col className="left-column">
                <Text className="sf-pro-text timepicker-text">{MINUTES}</Text>
              </Col>
              <Col className="right-column">
                <Text className="sf-pro-text timepicker-text">{SECONDS}</Text>
              </Col>
            </Row>
            <Col className="custom-panel">{panel}</Col>
          </>
        )}
      />
    </Item>
  );
};

export default CustomTimePicker;
