import { Button, Row } from 'antd/index';
import React, { useState } from 'react';
import { ModalCategory } from './ModalCategory';
import DeleteIcon from '../../TableShapes/TableShapeCustomIcons/DeleteIcon';
import CustomButton from 'components/CustomButton/CustomButton';
import { SETTINGS_VIEW } from '../../../constants';

const { EDIT_CATEGORIES } = SETTINGS_VIEW;

const tableShapeCategories = (updateCategory, deleteCategory, categories, setIsModalDeleteVisible, setIdClicked) => {
  const [category, setCategory] = useState();
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  const showEditModal = row => {
    setIsEditModalVisible(true);
    setCategory(row);
  };

  const columns = [
    {
      key: 'categories',
      render: function renderSubjects(row) {
        return <Row>{row}</Row>;
      },
    },
    {
      key: 'modifyCategories',
      render: function renderEditButton(row) {
        return (
          <>
            <CustomButton className="custom-button--edit" onClick={() => showEditModal(row)}>
              {EDIT_CATEGORIES}
            </CustomButton>
            <ModalCategory
              isModalVisible={isEditModalVisible}
              updateCategory={updateCategory}
              category={category}
              setIsModalVisible={setIsEditModalVisible}
              categories={categories}
            />
          </>
        );
      },
    },
    {
      key: 'deleteCategories',
      align: 'end',
      render: function renderDeleteButton(row) {
        return (
          <Button
            onClick={() => {
              setIsModalDeleteVisible(true);
              setIdClicked(row);
            }}
            className="conditional-delete button-delete"
            type="text"
            icon={<DeleteIcon classes="delete-icon" />}
          />
        );
      },
    },
  ];
  return columns;
};
export default tableShapeCategories;
