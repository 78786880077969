import { useEffect, useState } from 'react';
import { db } from 'services/firebase';

export const useFetchAllDocumentsFromCollection = collectionRef => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    const response = await db.collection(collectionRef).get();
    const docArray = response.docs.map(doc => {
      return { id: doc.id, ...doc.data() };
    });
    setData(docArray);
  };

  useEffect(() => {
    fetchData().then(() => {
      setIsLoading(false);
    });
  }, [collectionRef]);
  return { data, isLoading };
};
