import { useEffect } from 'react';
import useFirstMountState from './useFirstMountState';

/**
 * Run an `effect` only on updates.
 *
 * [See `react-use` gh repo](https://github.com/streamich/react-use/blob/master/src/useUpdateEffect.ts)
 */
const useUpdateEffect: typeof useEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (!isFirstMount) {
      return effect();
    }
  }, deps);
};

export default useUpdateEffect;
