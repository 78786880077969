import { createSlice } from '@reduxjs/toolkit';

const questionsSlice = createSlice({
  name: 'questions',
  initialState: {
    questions: null,
    questionToEdit: null,
    route: '/question/add',
    render: true,
    levels: [],
    subjects: [],
    languages: [],
  },
  reducers: {
    setQuestions(state, { payload }) {
      state.questions = payload;
    },
    setQuestionToEdit(state, { payload }) {
      state.questionToEdit = payload;
    },
    setRoute(state, { payload }) {
      state.route = payload;
    },
    setRender(state, { payload }) {
      state.render = payload;
    },
    setLevels(state, { payload }) {
      const { levels } = payload;
      state.levels = levels;
    },
    setLanguages(state, { payload }) {
      const { languages } = payload;
      state.languages = languages;
    },
    setSubjects(state, { payload }) {
      if (!('subjects' in payload)) {
        state.subjects = payload;
        return;
      }
      const { subjects } = payload;
      state.subjects = subjects;
    },
  },
});

const { actions, reducer } = questionsSlice;

export const { setQuestions, setQuestionToEdit, setRoute, setRender, setLevels, setSubjects, setLanguages } = actions;

export default reducer;
