import React from 'react';
import { Card, Col, Row, Title, Paragraph } from 'antd/index';
import CustomInfoCard from '../../components/CustomInfoCard/CustomInfoCard';
import PropTypes from 'prop-types';
import { TEST_RESULT_VIEW } from '../../constants';

const {
  FINISH,
  HR_INF,
  CORRECT_ANSWERS,
  INCORRECT_ANSWERS,
  ERROR_OCCURED,
  CONTACT_ADMINISTRATOR,
  FINISHED_TEST,
} = TEST_RESULT_VIEW;

const TestResultUI = ({
  error,
  loading,
  redirectToCompanyPage,
  numSubmitedCorrectClosedQuestion,
  numOfClosedQuestions,
}) => {
  const incorrectAnswers = numOfClosedQuestions - numSubmitedCorrectClosedQuestion;

  return error ? (
    <CustomInfoCard
      divider
      button
      title={ERROR_OCCURED}
      lead={CONTACT_ADMINISTRATOR}
      paragraph={`Error: ${error.message}`}
      handleClick={redirectToCompanyPage}
    />
  ) : (
    <CustomInfoCard
      title={FINISHED_TEST}
      loading={loading}
      divider
      button
      paragraph={HR_INF}
      buttonLabel={FINISH}
      handleClick={redirectToCompanyPage}
    >
      <Row gutter={24}>
        <Col md={12} sm={24}>
          <Card bordered={false} className="card-info-badge">
            <div className="square square--positive-col" />
            <Paragraph className="card-info-badge__text">{CORRECT_ANSWERS}</Paragraph>
            <Title level={3}>{numSubmitedCorrectClosedQuestion}</Title>
          </Card>
        </Col>
        <Col sm={24} md={12}>
          <Card bordered={false} className="card-info-badge">
            <div className="square square--wrong-col" />
            <Paragraph className="card-info-badge__text">{INCORRECT_ANSWERS}</Paragraph>
            <Title level={3}>{incorrectAnswers}</Title>
          </Card>
        </Col>
      </Row>
    </CustomInfoCard>
  );
};
TestResultUI.propTypes = {
  error: PropTypes.object,
  loading: PropTypes.bool,
  redirectToCompanyPage: PropTypes.func,
  numSubmitedCorrectClosedQuestion: PropTypes.number,
  numOfClosedQuestions: PropTypes.number,
};
export default TestResultUI;
