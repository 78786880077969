/* eslint-disable react/prop-types */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Row, Col, Paragraph, Spin, CustomIcon } from 'antd/index';
import CustomButton from 'components/CustomButton/CustomButton';
import { FINISH_TEST } from '../../constants';
import { IFinishedTestSummary } from '../../types/interfaces';
import { generatedTestsCollectionRef } from '../../services/firestore/references';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { LogoIcon } from 'assets/icons';

const { THANKS_FOR_PARTICIPATING, GO_TO_CONTEST, USER_RESULT, TIME_SPENT, SETTLEMENT_INFO } = FINISH_TEST;

const FinishedTest: FC<IFinishedTestSummary> = ({ numberOfQuestions, correctAnswers, generatedId, startTime }) => {
  const [finishedAt, setFinishedAt] = useState(0);
  const [result, setResult] = useState({ rate: '', percent: '' });
  const [solutionTime, setSolutionTime] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const isMounted = useRef(false);
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    const testDataCleanup = () => {
      generatedTestsCollectionRef
        .doc(generatedId)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            setFinishedAt(snapshot.data()!.finishedAt);
          } else {
            console.log('No such document!');
          }
        })
        .catch(e => console.log('Error getting document:', e));
    };
    testDataCleanup();
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!finishedAt) {
      return;
    }
    setIsLoaded(false);
    setResult({
      rate: `${correctAnswers} / ${numberOfQuestions}`,
      percent: `${Math.floor((correctAnswers / numberOfQuestions) * 100)}`,
    });
    setSolutionTime(moment(finishedAt - startTime).format('mm:ss'));
    setIsLoaded(true);
  }, [finishedAt]);

  const handleButtonRedirection = () => {
    history.push('/initial');
  };

  return isLoaded ? (
    <Col className="start-wrapper start-wrapper__padding">
      <Col className="start-container">
        <Col className="start-container__logo-container">
          <CustomIcon component={LogoIcon} />
        </Col>
        <Col className="start-container__content">
          <Paragraph className="finish-text">{THANKS_FOR_PARTICIPATING}</Paragraph>
          <Paragraph className="finish-text">
            {USER_RESULT}
            {result.rate} - {result.percent}%
          </Paragraph>
          <Paragraph className="finish-text finish-text--lower--finished">
            {TIME_SPENT}
            {solutionTime}
          </Paragraph>
          <Paragraph className="finish-text">{SETTLEMENT_INFO}</Paragraph>
          <div className="btn-container">
            <CustomButton onClick={handleButtonRedirection} className="custom-button--finish-info btn-pointer">
              {GO_TO_CONTEST}
            </CustomButton>
          </div>
        </Col>
      </Col>
    </Col>
  ) : (
    <Row justify="center" align="middle" className="spinner-container">
      <Spin size="large" />
    </Row>
  );
};

export default FinishedTest;
