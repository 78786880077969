import { COMPONENT_TABLE_NAME } from 'constants/index';

const { QUESTIONS, BASE_TESTS, TESTS, RESULTS, MANAGE_TEST, VERIFICATION } = COMPONENT_TABLE_NAME;

export const accessToFilters = {
  searchFilterAccess: [RESULTS, TESTS, QUESTIONS, BASE_TESTS, MANAGE_TEST, VERIFICATION],
  testKindFilterAccess: [TESTS, BASE_TESTS],
  categoriesFilterAccess: [QUESTIONS, TESTS, BASE_TESTS, MANAGE_TEST],
  levelFilterAccess: [QUESTIONS, MANAGE_TEST],
  timeFilterAccess: [RESULTS],
  statusFilterAccess: [RESULTS],
  hidenTestsFilterAccess: [TESTS],
};
