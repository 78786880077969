// Some stuff is left because it is unknown how this table will look in nearest future

import React from 'react';
import classNames from 'classnames';
import { getUnifiedTimeFormat } from '../../../utils/getUnifiedTimeFormat';
import {
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  CustomIcon,
  EyeOutlined,
  Paragraph,
  Tag,
  Tooltip,
} from 'antd/index';
import { QUESTION_TYPES, TEST_KIND_OPTIONS, LIST_FINISHED_TEST_DETAILS } from 'constants/index';
import CorrectIcon from 'assets/icons/CorrectIcon';
import IncorrectIcon from 'assets/icons/IncorrectIcon';

const NoteIcon = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 0C1.79086 0 0 1.79086 0 4V18C0 20.2091 1.79086 22 4 22H18C20.2091 22 22 20.2091 22 18V4C22 1.79086 20.2091 0 18 0H4ZM5 2C3.34315 2 2 3.34315 2 5V17C2 18.6569 3.34315 20 5 20H17C18.6569 20 20 18.6569 20 17V5C20 3.34315 18.6569 2 17 2H5Z"
        fill="#616161"
      />
      <path
        d="M5 11C5 10.4477 5.44772 10 6 10H16C16.5523 10 17 10.4477 17 11C17 11.5523 16.5523 12 16 12H6C5.44772 12 5 11.5523 5 11Z"
        fill="#616161"
      />
      <path
        d="M6 6C6 5.44772 6.44772 5 7 5H15C15.5523 5 16 5.44772 16 6C16 6.55228 15.5523 7 15 7H7C6.44772 7 6 6.55228 6 6Z"
        fill="#616161"
      />
      <path
        d="M6 16C6 15.4477 6.44772 15 7 15H11C11.5523 15 12 15.4477 12 16C12 16.5523 11.5523 17 11 17H7C6.44772 17 6 16.5523 6 16Z"
        fill="#616161"
      />
    </svg>
  );
};

const { CLOSED, OPEN, ANSWER_FROM_FILE } = QUESTION_TYPES;
const { QUESTION_PREVIEW, DATA_FROM_CLIPBOARD } = LIST_FINISHED_TEST_DETAILS;

const questionsTableShape = ({
  setIsModalVisible,
  setIsNoteModalVisible,
  questionType,
  setPreviewResultModalData,
  setDataFromClipboard,
  testKind,
  setIsClosedQuestionInfoVisible,
  setSelectedClosedQuestion,
}) => {
  return [
    {
      dataIndex: 'number',
      key: 'number',
      align: 'left',
      width: '4%',
      ellipsis: true,
      showSorterTooltip: false,
      render: function renderQuestion(record) {
        return <Paragraph className="closed-q closed-q--instruction">{record}</Paragraph>;
      },
    },
    {
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      width: `${testKind !== TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN ? '40%' : '50%'}`,
      ellipsis: true,
      showSorterTooltip: false,
      render: function renderQuestion(record) {
        return <Paragraph className="closed-q closed-q--instruction">{record}</Paragraph>;
      },
    },
    {
      dataIndex: 'subjects',
      key: 'subjects',
      align: 'left',
      width: '20%',
      showSorterTooltip: false,
      render: function renderCustomSubject(_, { subjects }) {
        const renderData = subjects.map((item, index) => {
          return (
            <Tag
              key={index}
              className={classNames({
                cat: true,
                'cat--js': item === 'JavaScript',
                'cat--jv': item === 'Java',
                'cat--php': item === 'Php',
                'cat--c': item === 'C',
                'cat--c-sh': item === 'C#',
                'cat--cpp': item === 'C++',
                'cat--db': item === 'Database',
                'cat--ai': item === 'AI',
                'cat--distributed-sys': item === 'Distributed systems',
                'cat--salesforce': item === 'Salesforce',
                'cat--python': item === 'Python',
                'cat--sql': item === 'SQL',
                'cat--css': item === 'CSS',
              })}
            >
              {item}
            </Tag>
          );
        });
        return (
          <Tooltip
            overlayClassName="categoryTooltip"
            placement="rightTop"
            title={renderData.length > 2 ? renderData : null}
            color={'#2d2d2d'}
          >
            <Paragraph
              className="categoryTooltip"
              ellipsis={{
                rows: 1,
              }}
            >
              {renderData}
            </Paragraph>
          </Tooltip>
        );
      },
    },
    ...(testKind !== TEST_KIND_OPTIONS.POSSIBILITY_TO_RETURN
      ? [
          {
            dataIndex: 'time',
            key: 'time',
            align: 'left',
            width: '10%',
            showSorterTooltip: false,
            render: function renderCustomTime(record) {
              return (
                <Paragraph className="closed-q closed-q--time">
                  <ClockCircleOutlined className="closed-q--time__icon" />
                  <span className="closed-q--time__value">{getUnifiedTimeFormat(record)}</span>
                </Paragraph>
              );
            },
          },
        ]
      : []),
    // {
    //   dataIndex: 'mouseLeaves',
    //   key: 'mouseLeaves',
    //   align: 'center',
    //   width: '10%',
    //   showSorterTooltip: false,
    //   // render: function renderCustomMouseLeaves(record) {
    //   //   return (
    //   //     <Paragraph className="closed-q closed-q--mouse">
    //   //       {questionType === CLOSED && <span>{record ? record : '-'}</span>}
    //   //     </Paragraph>
    //   //   );
    //   // },
    // },
    {
      dataIndex: 'leaveTabsCount',
      key: 'leaveTabsCount',
      align: 'center',
      width: '10%',
      showSorterTooltip: false,
      render: function renderCustomCardLeaves(record) {
        return (
          <Paragraph className="closed-q closed-q--card">
            {questionType === CLOSED && <span>{record ? record : '-'}</span>}
          </Paragraph>
        );
      },
    },
    {
      dataIndex: 'questionId',
      key: 'Icons',
      align: 'right',
      width: '16%',
      showSorterTooltip: false,
      render: function renderIcons(_, record) {
        return (
          <Paragraph className="closed-q closed-q--icon ">
            {(questionType === OPEN || questionType === ANSWER_FROM_FILE) && (
              <span
                className="closed-q closed-q--icon closed-q--icon--file"
                onClick={() => {
                  setPreviewResultModalData(record);
                  setIsNoteModalVisible(true);
                }}
              >
                <CustomIcon className="closed-q closed-q--icon closed-q--icon--eye" />
              </span>
            )}
            {questionType === CLOSED && (
              <span
                className="closed-q closed-q--icon closed-q--icon--file"
                onClick={() => {
                  setSelectedClosedQuestion(record);
                  setIsClosedQuestionInfoVisible(true);
                }}
                title={QUESTION_PREVIEW}
              >
                <CustomIcon className="closed-q closed-q--icon closed-q--icon--eye" />
              </span>
            )}
            {record.dataFromClipboard.length > 0 && (
              <EyeOutlined
                className="closed-q closed-q--icon closed-q--icon--eye"
                onClick={() => {
                  setDataFromClipboard(record.dataFromClipboard);
                  setIsModalVisible(true);
                }}
                title={DATA_FROM_CLIPBOARD}
              />
            )}
            {record.correct === true && (
              <span className="closed-q--icon">
                <CorrectIcon />
              </span>
            )}
            {record.correct === false && (
              <span className="closed-q--icon">
                <IncorrectIcon />
              </span>
            )}
            {/* {<MinusOutlined className="closed-q closed-q--icon closed-q--icon--failure"/>} */}
          </Paragraph>
        );
      },
    },
  ];
};
export default questionsTableShape;
