/* eslint-disable react/prop-types */
//@ts-nocheck
import { ChangeEvent, FC } from 'react';
import { Form, TextArea } from 'antd/index';
import { ITextArea } from './CustomTextAreaTypes';
import { TEST_KIND_OPTIONS, PLACEHOLDERS } from 'constants/index';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const { Item } = Form;
const CustomTextArea: FC<ITextArea> = ({
  name,
  maxHeight,
  value,
  setTextAreaValue,
  form,
  possibilityChooseQuestions,
  disabled,
}) => {
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(event.target.value);
    if (possibilityChooseQuestions === POSSIBILITY_TO_RETURN) {
      form.submit();
    }
  };
  return (
    <Item name={name}>
      <TextArea
        placeholder={PLACEHOLDERS.TEXT_AREA}
        className="open-question-text-area-content"
        style={{ maxHeight: maxHeight || 'auto' }}
        spellcheck="false"
        value={value}
        maxLength={10000}
        onChange={handleChange}
        disabled={disabled}
      />
    </Item>
  );
};

export default CustomTextArea;
