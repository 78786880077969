import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { fetchTestData, getMemoizedTestData } from './TestSlice';
import { TEST_STATUSES, TEST_QUESTIONS_WRAPPER_VIEW } from 'constants/index';
import { Spin } from 'antd/index';
import TestStart from '../TestStart/TestStart';
import CustomInfoCard from 'components/CustomInfoCard/CustomInfoCard';
import SolvingTest from './SolvingTest/SolvingTest';
import redirectToCompanyPage from 'utils/redirectToCompanyPage';
import FinishTest from '../FinishTest/FinishTest';
import FinishTestWithNoResults from 'views/FinishTest/FinishTestWithNoResults';
import useFetchCollectionDocumentFromFirestore from 'hooks/useFetchCollectionDocumentFromFirestore';
import { generatedTestsCollectionRef } from 'services/firestore/references';
import getCollectionDocument from 'utils/collectionDocumentCRUD/getCollectionDocument';

const { FINISHED, FILLED, EXPIRED } = TEST_STATUSES;
const { ERROR_OCCURED, CONTACT_ADMINISTRATOR } = TEST_QUESTIONS_WRAPPER_VIEW;
const TestQuestionsWrapperContainer = () => {
  const { generatedId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const isTestStarted = useFetchCollectionDocumentFromFirestore(generatedTestsCollectionRef, generatedId)
    ?.isTestStarted;
  const [startTest, setStartTest] = useState(false);
  const [isTestFinished, setIsTestFinished] = useState(false);
  const { test, loading, hasErrors } = useSelector(getMemoizedTestData);
  const { status, resultCondition, subjects, title } = test;
  useEffect(() => {
    dispatch(fetchTestData(generatedId, history));
  }, [generatedId]);

  const isTestCompleted = async () => {
    const { status } = await getCollectionDocument(generatedTestsCollectionRef, generatedId);
    const hasCompletedStatus = [FILLED, FINISHED, EXPIRED].includes(status);

    if (hasCompletedStatus) {
      setIsTestFinished(true);
    }

    return hasCompletedStatus;
  };

  if (isTestStarted === undefined) {
    return null;
  }

  const renderTest = () => {
    if (loading) {
      return <Spin className="spinner-container" />;
    }
    if (hasErrors) {
      return (
        <CustomInfoCard
          divider
          button
          title={ERROR_OCCURED}
          lead={CONTACT_ADMINISTRATOR}
          handleClick={redirectToCompanyPage}
        />
      );
    }
    const isStatusFinishedOrFilled = status === FINISHED || status === FILLED;

    const finishTestTemplate = resultCondition ? (
      <FinishTest generatedId={generatedId} />
    ) : (
      <FinishTestWithNoResults testTitle={title} />
    );

    const content = isStatusFinishedOrFilled ? (
      <>{finishTestTemplate}</>
    ) : (
      <TestStart
        subjects={subjects}
        setStartTest={setStartTest}
        isTestStarted={isTestStarted}
        generatedId={generatedId}
        generatedTest={test}
        isTestFinished={isTestFinished}
        isTestCompleted={isTestCompleted}
        testTitle={title}
      />
    );
    return <>{content}</>;
  };

  return (
    <>
      {startTest ? (
        <SolvingTest
          startTest={startTest}
          setStartTest={setStartTest}
          isTestFinished={isTestFinished}
          setIsTestFinished={setIsTestFinished}
          testTitle={title}
          isTestCompleted={isTestCompleted}
        />
      ) : (
        renderTest()
      )}
    </>
  );
};
export default TestQuestionsWrapperContainer;
