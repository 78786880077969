// @ts-nocheck

import { Row, Col } from 'antd/index';
import { handleSignIn } from '../../services/auth/AuthProvider';
import CustomInfoCard from 'components/CustomInfoCard/CustomInfoCard';
import { LOGIN_VIEW, PATH_APP } from '../../constants';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import handleRedirect from '../../utils/handleRedirect';
import Loader from 'components/Loader/Loader';
import { useHistory } from 'react-router-dom';

const { PLATFORM_LOGIN, NOT_LOGIN, LOGIN } = LOGIN_VIEW;
const { RESULTS } = PATH_APP;

const Login = () => {
  const currentUser = useSelector(state => state.auth.currentUser);
  const history = useHistory();

  useEffect(() => {
    if (currentUser) {
      handleRedirect(history, RESULTS);
    }
  }, []);
  return !currentUser ? (
    <Row className="login-screen">
      <Col md={24}>
        <CustomInfoCard
          title="BriteWin"
          lead={PLATFORM_LOGIN}
          paragraph={NOT_LOGIN}
          divider
          button
          buttonLabel={LOGIN}
          handleClick={handleSignIn}
        />
      </Col>
    </Row>
  ) : (
    <Loader />
  );
};

export default Login;
