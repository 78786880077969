import { useEffect, useState } from 'react';
import { DocumentData } from 'services/firebase';

const useFetchCollectionDocumentFromFirestore = (collectionRef, documentRef) => {
  const [data, setData] = useState<DocumentData>();

  async function fetchData() {
    const response = await collectionRef.doc(documentRef).get();
    if (response.exists) {
      setData(response.data());
    }
  }
  useEffect(() => {
    fetchData();
  }, [collectionRef, documentRef]);
  return data;
};
export default useFetchCollectionDocumentFromFirestore;

export const useFetchFireStoreDoc = (collectionRef, documentRef) => {
  const [data, setData] = useState<DocumentData>();
  const [isTestValid, setIsTestValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  async function fetchData() {
    const response = await collectionRef.doc(documentRef).get();
    if (response.exists) {
      setData(response.data());
      setIsTestValid(true);
    }
  }

  useEffect(() => {
    fetchData();
    setIsLoading(false);
  }, [collectionRef, documentRef]);
  return { data, isTestValid, isLoading };
};

export const useFetchCollectionDocumentFromFirestoreOnSnapshot = (collectionRef, documentRef) => {
  const [data, setData] = useState<DocumentData>();

  function fetchData() {
    collectionRef.doc(documentRef).onSnapshot(document => {
      if (document.exists) {
        setData(document.data());
      }
    });
  }
  useEffect(() => {
    fetchData();
  }, [collectionRef, documentRef]);
  return data;
};
