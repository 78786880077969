import ListFinishedTestsContainer from 'views/ListFinishedTestsView/ListFinishedTestsContainer';
import ListFinishTestDetails from 'views/ListFinishTestDetails/ListFinishTestDetails';
import { v4 as uuidv4 } from 'uuid';

export const RESULTS_ROUTES = [
  {
    path: '/results',
    component: ListFinishedTestsContainer,
    key: uuidv4(),
  },
  {
    path: '/results/:resultId',
    component: ListFinishTestDetails,
    key: uuidv4(),
  },
];
