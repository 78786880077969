/* eslint react/prop-types: 0 */

import { Radio } from 'antd/index';

const CustomRadio = ({ content, value, isDisabled }) => {
  return (
    <Radio disabled={isDisabled} value={value}>
      {content}
    </Radio>
  );
};

export default CustomRadio;
