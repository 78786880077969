/* eslint-disable react/prop-types */
import React from 'react';
import { TEST_KIND_OPTIONS, DETAILS_PDF_TEMPLATE, PDF_TITLE_REGEX } from 'constants/index';
import { Page, Text, View, Document, PDFDownloadLink, Image } from '@react-pdf/renderer';
import CorrectIcon from 'assets/icons/correctIcon.png';
import IncorrectIcon from 'assets/icons/incorrectIcon.png';
import { formatDateWithoutHours, formatDate } from 'utils/formatDate';
import { checkCorrectTextColor, styles } from './stylePDF';
import { getFilteredAnswersData } from 'utils/getFilteredAnswersData';
import moment from 'moment';
import { useFetchCollectionDocumentFromFirestoreOnSnapshot } from '../../hooks/useFetchCollectionDocumentFromFirestore';
import { generatedTestsCollectionRef } from '../../services/firestore/references';

const { POSSIBILITY_TO_RETURN } = TEST_KIND_OPTIONS;
const {
  SHORTAGE,
  CANDIDATE,
  CATEGORY,
  LEVEL,
  COMPLETION,
  DEDICATED_TIME,
  OVERALL,
  CLOSED_QUESTIONS,
  TIME,
  CARD,
  OPEN_QUESTIONS,
} = DETAILS_PDF_TEMPLATE;

const GeneratePDFFromTemplate = ({ resultData, score, specificLanguagesResult, answers }) => {
  return (
    <PDFDownloadLink
      document={
        <DetailsPdfTemplate
          resultData={resultData}
          score={score}
          specificLanguagesResult={specificLanguagesResult}
          answers={answers}
        />
      }
      fileName={`${resultData.candidate.replace(' ', '_')}_${formatDateWithoutHours(moment())}.pdf`}
    >
      {({ loading }) => (loading ? 'Loading' : 'Generate PDF')}
    </PDFDownloadLink>
  );
};

const DetailsPdfTemplate = ({ resultData, score, specificLanguagesResult, answers }) => {
  const { page } = styles;
  const { candidate, finishedAt } = resultData;
  const { closedAnswers, openAnswers, answersFromFile } = getFilteredAnswersData(answers);
  const joinedOpenAnswers = [...openAnswers, ...answersFromFile];
  const detailsOfAnswers = Object.entries(specificLanguagesResult);

  const fullResultData = useFetchCollectionDocumentFromFirestoreOnSnapshot(generatedTestsCollectionRef, resultData.id);

  function renderCustomTime(time) {
    return !time
      ? '-'
      : (time >= 600000 && formatDate(time, 'mm') + ' min') ||
          (time >= 60000 && time < 600000 && formatDate(time, 'm') + ' min') ||
          (time < 60000 && time >= 10000 && formatDate(time, 'ss') + ' sec') ||
          (time < 10000 && formatDate(time, 's') + ' sec');
  }

  const isAnswerCorrect = answer => {
    if (answer === null) {
      return <Text style={{ fontSize: 10 }}>{SHORTAGE}</Text>;
    } else if (answer) {
      return <Image style={{ width: 10, height: 10 }} src={CorrectIcon} />;
    } else {
      return <Image style={{ width: 10, height: 10 }} src={IncorrectIcon} />;
    }
  };

  const millisToMinutesAndSeconds = millis => {
    if (isNaN(millis)) {
      return '-';
    }
    let minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
  };

  const isReturnPossible = fullResultData?.testKind === POSSIBILITY_TO_RETURN;

  const FirstTableRow = () => {
    return (
      <View style={styles.candidateDataContainer}>
        <View style={styles.candidateDataColumn}>
          <View style={styles.candidateDataHeader}>
            <View style={styles.candidateDataHeaderContent}>
              <Text style={styles.textStyledHeader}>{CANDIDATE}</Text>
              <Text style={styles.textStyledHeader}>{candidate}</Text>
            </View>
          </View>

          {/*category*/}
          <View style={styles.candidateDataRow}>
            <View style={styles.candidateRowContent}>
              <View style={{ flex: 2 }}>
                <Text style={styles.textStyled}>{CATEGORY}</Text>
              </View>
              <View style={{ flex: 8, textOverflow: 'ellipsis', textAlign: 'right' }}>
                <Text style={styles.textStyled}>
                  <Text style={styles.textStyled}>{fullResultData?.subjects.join(', ')}</Text>
                </Text>
              </View>
            </View>
          </View>

          {/*level*/}
          <View style={styles.candidateDataRow}>
            <View style={styles.candidateRowContent}>
              <View style={{ flex: 2 }}>
                <Text style={styles.textStyled}>{LEVEL}</Text>
              </View>
              <View style={{ flex: 8, textOverflow: 'ellipsis', textAlign: 'right' }}>
                <Text style={styles.textStyled}>
                  <Text style={styles.textStyled}>{fullResultData?.questionsFromInstance[0].level}</Text>
                </Text>
              </View>
            </View>
          </View>

          {/*completion date*/}
          <View style={styles.candidateDataRow}>
            <View style={styles.candidateRowContent}>
              <Text style={styles.textStyled}>{COMPLETION}</Text>
              <Text>
                <Text style={styles.textStyled}>{moment(finishedAt).format('DD.MM.YYYY')} </Text>
                <Text style={styles.textStyled__grey}>{moment(finishedAt).format('HH:mm')}</Text>
              </Text>
            </View>
          </View>

          {/*time spent*/}
          <View style={styles.candidateDataRow}>
            <View style={styles.candidateRowContent}>
              <Text style={styles.textStyled}>{DEDICATED_TIME}</Text>
              <Text style={styles.textStyled}>{renderCustomTime(fullResultData?.time)}</Text>
            </View>
          </View>
        </View>
        {/*general result*/}
        <View style={styles.candidateDataColumnRight}>
          <View style={styles.candidateResultsColumn}>
            <View style={styles.candidateDataHeaderContent}>
              <View style={{ flex: 60 }}>
                <Text style={styles.textStyled}>{OVERALL}</Text>
              </View>
              <View style={styles.candidateResultsHeaderScore}>
                <Text style={styles.resultText}>{score.rate}</Text>
              </View>
              <View style={styles.candidateResultsHeaderPercentage}>
                <Text style={checkCorrectTextColor(score.percentage)}>{score.percentage}%</Text>
              </View>
            </View>
          </View>

          {/*  Wyniki szczegółowe*/}
          {detailsOfAnswers.map(([category, { points, percent }], idx) => {
            return (
              <View key={idx} style={styles.candidateResultsName}>
                <View style={styles.candidateDataHeaderContent}>
                  <View style={{ flex: 60 }}>
                    <Text style={styles.textStyled}>{category}</Text>
                  </View>
                  <View style={styles.candidateResultsPoints}>
                    <Text style={styles.resultText}>{points}</Text>
                  </View>
                  <View style={styles.candidateResultsPoints}>
                    <Text style={checkCorrectTextColor(percent)}>{percent}%</Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const ClosedQuestions = () => {
    return (
      <View style={{ marginTop: 20 }} wrap>
        <View style={styles.headerRowWrapper}>
          <View style={styles.headerRowContent}>
            <View style={{ flex: 40 }}>
              <Text style={styles.textStyled}>{CLOSED_QUESTIONS}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 20, alignItems: 'flex-start', paddingHorizontal: 10 }]}>
              <Text style={styles.textStyled}>{CATEGORY}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 7 }]}>
              <Text style={styles.textStyled}>{TIME}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 9 }]}>
              <Text style={styles.textStyled}>{CARD}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 5 }]} />
          </View>
        </View>

        {closedAnswers.map(({ instructions, subjects, leaveTabsCount, correct, time }, idx) => {
          const instructionsTrimmed = instructions.replace(PDF_TITLE_REGEX, '');
          return (
            <View key={idx} style={styles.answerRowWrapper} wrap={false}>
              <View style={styles.answerRowContent}>
                <View style={[styles.answerColumn, { flex: 3, alignItems: 'flex-start' }]}>
                  <Text style={styles.textStyled}>{idx + 1}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 37, alignItems: 'flex-start', textOverflow: 'ellipsis' }]}>
                  <Text style={[styles.textStyled, { paddingHorizontal: 10 }]}>{instructionsTrimmed}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 20, alignItems: 'flex-start', paddingHorizontal: 10 }]}>
                  <Text style={styles.textStyled}>{subjects.join(', ')}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 7 }]}>
                  <Text style={styles.textStyled}>{isReturnPossible ? '-' : millisToMinutesAndSeconds(time)}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 9 }]}>
                  <Text style={{ fontSize: 10 }}>{leaveTabsCount}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 5, borderRight: 'none' }]}>{isAnswerCorrect(correct)}</View>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  const OpenQuestions = () => {
    return (
      <View style={{ marginTop: 20 }}>
        <View style={styles.headerRowWrapper}>
          <View style={styles.headerRowContent}>
            <View style={{ flex: 62 }}>
              <Text style={styles.textStyled}>{OPEN_QUESTIONS}</Text>
            </View>
            <View style={[styles.headerColumn, { paddingHorizontal: 10, flex: 25, alignItems: 'flex-start' }]}>
              <Text style={{ fontSize: 10 }}>{CATEGORY}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 8 }]}>
              <Text style={{ fontSize: 10 }}>{TIME}</Text>
            </View>
            <View style={[styles.headerColumn, { flex: 5 }]} />
          </View>
        </View>

        {joinedOpenAnswers.map(({ instructions, subjects, correct, time }, idx) => {
          const index = closedAnswers.length ? closedAnswers.length + 1 + idx : idx;
          return (
            <View key={idx} style={styles.answerRowWrapper} wrap={false}>
              <View style={styles.answerRowContent}>
                <View style={[styles.answerColumn, { flex: 3, alignItems: 'flex-start' }]}>
                  <Text style={styles.textStyled}>{index}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 59, alignItems: 'flex-start' }]}>
                  <Text style={[styles.textStyled, { paddingHorizontal: 10 }]}>{instructions}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 25, alignItems: 'flex-start', paddingHorizontal: 10 }]}>
                  <Text style={{ fontSize: 10 }}>{subjects.join(', ')}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 8 }]}>
                  <Text style={{ fontSize: 10 }}>{isReturnPossible ? '-' : millisToMinutesAndSeconds(time)}</Text>
                </View>
                <View style={[styles.answerColumn, { flex: 5, borderRight: 'none' }]}>{isAnswerCorrect(correct)}</View>
              </View>
            </View>
          );
        })}
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={page} wrap>
        <FirstTableRow />
        <ClosedQuestions />
        {joinedOpenAnswers.length ? <OpenQuestions /> : null}
      </Page>
    </Document>
  );
};

export default GeneratePDFFromTemplate;
