import firebase from 'firebase';
import { setCurrentUser } from './authSlice';
import customNotification from 'utils/customNotification';
import { provider } from 'services/firebase';
import handleRedirect from 'utils/handleRedirect';
import { AUTH_PROVIDER } from '../../constants';

const { LOGOUT_FAILED, CONNECTION_ERROR } = AUTH_PROVIDER;

export const handleSignOut = async dispatch => {
  await firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch(setCurrentUser(null));
      localStorage.removeItem('auth');
    })
    .catch(() => {
      customNotification('Error', `${LOGOUT_FAILED}`, 'topRight', 5);
    });
};

export const handleSignIn = () => {
  firebase.auth().signInWithRedirect(provider);
};

export const resultSignIn = resolve => {
  firebase
    .auth()
    .getRedirectResult()
    .then(result => {
      resolve(result);
    })
    .catch(() => {
      customNotification('Error', `${CONNECTION_ERROR}`, 'topRight', 5);
    });
};
