import { QUESTION_TYPES, QUESTION_STATUSES } from '../constants';

export const setQuestionsStatus = (allQuestions, answersData, presentQuestionIndex) => {
  const { CLOSED } = QUESTION_TYPES;
  const { SELECTED, SOLVED, UNSOLVED } = QUESTION_STATUSES;
  return allQuestions.reduce((questionStatus, question, index) => {
    const allQuestionAnswers = answersData.filter(answer => answer.questionId === question.id);
    let lastAnswer = allQuestionAnswers && allQuestionAnswers[allQuestionAnswers.length - 1]?.answers;
    if (question.type === CLOSED) {
      lastAnswer = lastAnswer ? lastAnswer[0] : null;
    }
    if (index === presentQuestionIndex) {
      questionStatus[index] = { type: SELECTED, answer: lastAnswer, questionId: question.id };
    } else if (
      (!lastAnswer ||
        lastAnswer[0] === '' ||
        (lastAnswer[0] &&
          typeof lastAnswer[0] === 'object' &&
          'fileAnswerValue' in lastAnswer[0] &&
          !lastAnswer[0]?.fileAnswerValue)) &&
      index < presentQuestionIndex
    ) {
      questionStatus[index] = { type: UNSOLVED };
    } else if (
      !allQuestionAnswers.length ||
      !allQuestionAnswers.filter(item => 'answers' in item && item.answers).length
    ) {
      questionStatus[index] = { type: '' };
    } else {
      questionStatus[index] = { type: SOLVED, answer: lastAnswer, questionId: question.id };
    }
    return questionStatus;
  }, {});
};
