import { useRef } from 'react';

/**
 * Check if current render is first.
 *
 * [See `react-use` gh repo](https://github.com/streamich/react-use/blob/master/src/useFirstMountState.ts)
 */
const useFirstMountState = (): boolean => {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }

  return isFirst.current;
};

export default useFirstMountState;
