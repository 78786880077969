/* eslint react/prop-types: 0 */

import { Col, Paragraph, CustomIcon } from 'antd/index';
import { FC } from 'react';
import { IQuestionCardProps } from './TestResultModalTypes';
// IT'S TEMPORARY SOLUTION DUE TO A BUG WITH WEBPACK CONFIG ON PROD, IN THE END THIS WILL BE REPLACED WITH IMPORT STRAIGHT FROM SVG ICON FILE
export const CorrectIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="#1DD78F" />
      <rect
        x="7.82812"
        y="12.5352"
        width="8"
        height="2"
        rx="1"
        transform="rotate(-45 7.82812 12.5352)"
        fill="#2D2D2D"
      />
      <rect
        x="9.24219"
        y="13.9502"
        width="6"
        height="2"
        rx="1"
        transform="rotate(-135 9.24219 13.9502)"
        fill="#2D2D2D"
      />
    </svg>
  );
};

export const IncorrectIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="#F34161" />
      <rect
        x="5.75781"
        y="13.0713"
        width="10"
        height="2"
        rx="1"
        transform="rotate(-45 5.75781 13.0713)"
        fill="#2D2D2D"
      />
      <rect
        x="12.8281"
        y="14.4854"
        width="10"
        height="2"
        rx="1"
        transform="rotate(-135 12.8281 14.4854)"
        fill="#2D2D2D"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="20" height="20" rx="4" fill="#FFCC49" />
      <rect x="9" y="9" width="2" height="2" rx="1" fill="#2D2D2D" />
      <rect x="4" y="9" width="2" height="2" rx="1" fill="#2D2D2D" />
      <rect x="14" y="9" width="2" height="2" rx="1" fill="#2D2D2D" />
    </svg>
  );
};

const QuestionCard: FC<IQuestionCardProps> = ({ status: { isExamined, isCorrect }, counter }) => {
  return (
    <Col className="cards-container__card card">
      <Paragraph className="card__counter">{counter}</Paragraph>
      {isExamined ? (
        isCorrect ? (
          <CustomIcon className="card__status-icon" component={CorrectIcon} />
        ) : (
          <CustomIcon className="card__status-icon" component={IncorrectIcon} />
        )
      ) : (
        <CustomIcon className="card__status-icon" component={CheckIcon} />
      )}
    </Col>
  );
};

export default QuestionCard;
