import { QUESTION_TYPES } from 'constants/index';
import { isObject } from 'lodash';

const { CLOSED, OPEN } = QUESTION_TYPES;

export const getFilteredAnswersData = answers => {
  if (answers) {
    const closedAnswers = answers.filter(({ type }) => type === CLOSED);
    const openAnswers = answers.filter(({ type, hasAnswerFile }) => {
      return type === OPEN && hasAnswerFile === false;
    });
    const answersFromFile = answers.filter(({ type, hasAnswerFile }) => {
      return type === OPEN && hasAnswerFile === true;
    });

    const answersWithNumbers = [...closedAnswers, ...openAnswers, ...answersFromFile].map((answer, index) => {
      return { ...answer, number: index + 1 };
    });

    const closedAnswersWithNum = answersWithNumbers.filter(({ type }) => type === CLOSED);

    const openAnswersWithNum = answersWithNumbers.filter(
      ({ type, hasAnswerFile }) => type === OPEN && hasAnswerFile === false,
    );

    const answersFromFileWithNum = answersWithNumbers.filter(
      ({ type, hasAnswerFile }) => type === OPEN && hasAnswerFile === true,
    );

    const isExaminedAll = openAnswers.every(({ isExamined, correct }) => isExamined || correct !== null);

    const numberOfCorrectAnswers = answers.filter(({ correct }) => correct === true).length;

    const numberOfEmptyAnswers = answers.filter(({ answers }) => {
      return (
        answers === '' ||
        (answers.length === 1 && (answers.includes('') || (isObject(answers[0]) && !Object.keys(answers[0]).length)))
      );
    }).length;

    return {
      closedAnswers,
      openAnswers,
      answersFromFile,
      closedAnswersWithNum,
      openAnswersWithNum,
      answersFromFileWithNum,
      isExaminedAll,
      numberOfCorrectAnswers,
      numberOfEmptyAnswers,
    };
  }
};
