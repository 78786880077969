/* eslint-disable react/prop-types */
//@ts-nocheck

import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import remarkBreaks from 'remark-breaks';
import MDEditor from '@uiw/react-md-editor';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';

function checkIfValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const codeBlock = () => {
  return {
    code({ children, ...props }) {
      return (
        <SyntaxHighlighter wrapLines wrapLongLines showLineNumbers style={vscDarkPlus} PreTag="div" {...props}>
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      );
    },
  };
};

const MarkdownPreview = ({ content, className }) => {
  return (
    <MDEditor.Markdown
      components={codeBlock()}
      remarkPlugins={[remarkBreaks]}
      source={checkIfValidJSON(content) && !Number(content) ? JSON.parse(content) : content}
      className={['markdown-preview', className].join(' ')}
    />
  );
};

export default MarkdownPreview;
