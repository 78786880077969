import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Col, Row, Search, SearchOutlined, Spin, Table, Modal, Text } from '../../antd';
import { IContestModal, IOverviewContestModal, IExcelColumns } from '../../types/interfaces';
import { NUMBER_OF_ITEMS_PER_PAGE, PLACEHOLDER_TEXTS } from '../../constants';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { setSearchFilter } from '../../views/TableShapes/filtersSlice';
import { generatedTestsCollectionRef } from '../../services/firestore/references';
import { showInfoMessage } from '../../utils/showInfoMessage';
import messages from '../../utils/validationSchemaOptions/validationSchemaOptions';
import { getContestResultsColumns } from './OverviewContestScheme';
import moment from 'moment';
import CustomButton from '../CustomButton/CustomButton';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';

const { somethingWentWrong } = messages;

const OverviewContestModal = ({
  isContestModalVisible,
  handleOnCancel,
  selectedContestId,
  selectedContestTitle,
}: IContestModal) => {
  const dispatch = useDispatch();
  const filterContests: string = useSelector((state: RootStateOrAny) => state.filters.searchFilter);
  const [dataSource, setDataSource] = useState<IOverviewContestModal[]>([] as IOverviewContestModal[]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  let [correctAnswers] = useState<number>(0);
  let [numberOfAnswers] = useState<number>(0);

  const workSheetName = 'Worksheet1';
  const contestName = `Contest ${selectedContestTitle}`;
  const contestNameTitle = `Contest: ${selectedContestTitle}`;

  const columns: IExcelColumns[] = [
    { header: 'Name', key: 'recruitedPerson' },
    { header: 'E-mail', key: 'emailRecruitedPerson' },
    { header: 'Phone', key: 'phoneRecruitedPerson' },
    { header: 'Solution Time', key: 'solutionTime' },
    { header: 'Result - rate', key: 'rate' },
    { header: 'Result - percentage', key: 'percent' },
  ];

  const workbook = new Excel.Workbook();

  const saveExcel = async () => {
    try {
      const fileName = contestName;
      const worksheet = workbook.addWorksheet(workSheetName);

      worksheet.columns = columns;

      worksheet.getRow(1).font = { bold: true };

      worksheet.columns.forEach(column => {
        column.width = column.header!.length < 40 ? 40 : column.header!.length;
        column.alignment = { horizontal: 'center' };
      });

      sortedDataToRender.forEach(singleData => {
        worksheet.addRow({
          recruitedPerson: singleData.recruitedPerson,
          emailRecruitedPerson: singleData.emailRecruitedPerson,
          phoneRecruitedPerson: singleData.phoneRecruitedPerson,
          solutionTime: singleData.solutionTime,
          rate: singleData.result.rate,
          percent: singleData.result.percent,
        });
      });

      worksheet.eachRow({ includeEmpty: false }, row => {
        // @ts-ignore
        const currentCell = row._cells;

        currentCell.forEach(singleCell => {
          const cellAddress = singleCell._address;

          worksheet.getCell(cellAddress).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      });

      const buf = await workbook.xlsx.writeBuffer();

      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error: any) {
      console.error('<<<ERROR>>>', error);
      console.error('Something Went Wrong', error.message);
    } finally {
      workbook.removeWorksheet(workSheetName);
    }
  };

  useEffect(() => {
    const dataSourceCleanup = generatedTestsCollectionRef.where('testId', '==', selectedContestId).onSnapshot(
      snapshots => {
        const tempDataArray: IOverviewContestModal[] = [];
        snapshots.forEach(snap => {
          setIsLoaded(false);
          const {
            testIdGenerated,
            recruitedPerson,
            emailRecruitedPerson,
            phoneRecruitedPerson,
            startedAt,
            finishedAt,
            answers,
          } = snap.data();
          tempDataArray.push({
            testIdGenerated,
            recruitedPerson,
            emailRecruitedPerson,
            phoneRecruitedPerson,
            startedAt,
            finishedAt,
            answers,
          } as IOverviewContestModal);
        });
        setDataSource(tempDataArray);
        setIsLoaded(true);
      },
      () => {
        showInfoMessage('error', somethingWentWrong);
      },
    );
    return () => {
      dataSourceCleanup();
    };
  }, [selectedContestId]);

  const handleFilterContests = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearchFilter(e.target.value));
  };

  const testDataToRender = dataSource?.map(
    ({
      testIdGenerated,
      recruitedPerson,
      emailRecruitedPerson,
      phoneRecruitedPerson,
      answers,
      startedAt,
      finishedAt,
    }) => {
      {
        correctAnswers = answers?.filter(({ correct }) => correct).length;
        numberOfAnswers = answers?.length;
      }
      return {
        testIdGenerated,
        recruitedPerson,
        emailRecruitedPerson,
        phoneRecruitedPerson,
        startedAt,
        finishedAt,
        solutionTime: finishedAt ? moment(finishedAt - startedAt).format('mm:ss') : 'unfinished',
        result: {
          rate: `${correctAnswers} / ${numberOfAnswers}`,
          percent: correctAnswers && numberOfAnswers ? `${Math.floor((correctAnswers / numberOfAnswers) * 100)}` : '0',
        },
      };
    },
  );

  const sortedDataToRender = testDataToRender.sort((a, b) => {
    if (a.result.percent === b.result.percent) {
      return b.solutionTime < a.solutionTime ? 1 : -1;
    }
    return Number(b.result.percent) > Number(a.result.percent) ? 1 : -1;
  });

  const filteredContestParticipants = useMemo(
    () =>
      sortedDataToRender.filter(
        filteredContestName =>
          filteredContestName.recruitedPerson.toLowerCase().includes(filterContests.toLowerCase()) ||
          filteredContestName.emailRecruitedPerson.toLowerCase().includes(filterContests.toLowerCase()),
      ),
    [filterContests, testDataToRender],
  );

  return isLoaded ? (
    <Modal
      footer={null}
      onCancel={handleOnCancel}
      centered
      destroyOnClose
      width="1366px"
      visible={isContestModalVisible}
    >
      <Row justify="center" align="middle" className="overview-contest-container settings-container table">
        <Col className="settings-container__col">
          <Row>
            <Search
              placeholder={PLACEHOLDER_TEXTS.SEARCH_RESULT}
              className="input-search"
              value={filterContests}
              maxLength={100}
              onChange={handleFilterContests}
              prefix={<SearchOutlined />}
            />
          </Row>
          <Row className="overview-details">
            <Text className="active-contest-info">{contestNameTitle}</Text>
            <CustomButton onClick={saveExcel} className="custom-button--excel">
              Export To Excel
            </CustomButton>
          </Row>
          <Row className="overview-table">
            <Table
              rowKey="testIdGenerated"
              dataSource={filteredContestParticipants}
              columns={getContestResultsColumns()}
              pagination={{
                defaultPageSize: NUMBER_OF_ITEMS_PER_PAGE,
                position: ['bottomCenter'],
              }}
            />
          </Row>
        </Col>
      </Row>
    </Modal>
  ) : (
    <Row justify="center" align="middle" className="spinner-container">
      <Spin size="large" />
    </Row>
  );
};

export default OverviewContestModal;
