/* eslint-disable react/prop-types */

import { useLocation, matchPath, Redirect } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { USER_REDIRECT_URL, PATH_APP, ROLES } from 'constants/index';
import { ICurrentUser } from 'types/interfaces';
import { RootState } from 'App/store';

const { HOME } = PATH_APP;
const { ROOT, ADMIN, LEAD, TECHNICAL, RECRUITER } = ROLES;
const RESTRICTED_FOR_TECHNICAL = [ROOT, ADMIN, LEAD, RECRUITER];
const RESTRICTED_FOR_RECRUITER = [ROOT, ADMIN, TECHNICAL, LEAD];
const RESTRICTED_FOR_TECHNICAL_AND_RECRUITER = [ROOT, ADMIN, LEAD];
const FOR_ROOT_AND_ADMIN = [ROOT, ADMIN];

// Add new rule for a new added route, if all the roles in app should have access to it, add :exact:true
// There is no no-auth demanding routes in here cuz the role in such situation simply doesn't matter
const REDIRECT_RULES = [
  { route: { path: '/', exact: true } },
  { route: { path: '/tests', exact: true } },
  { route: { path: '/tests/add-test' }, exact: true },
  { route: { path: '/tests/edit/:id' }, exact: true },
  { route: { path: '/template/edit/:id' }, only: RESTRICTED_FOR_RECRUITER },
  { route: { path: '/tests/add-test/:questionsDuplicatedJSON' }, exact: true },
  { route: { path: '/question/add' }, only: RESTRICTED_FOR_RECRUITER },
  { route: { path: '/verification/:generatedTestId/:questionId' }, only: RESTRICTED_FOR_RECRUITER },
  { route: { path: '/question/edit/:id' }, only: RESTRICTED_FOR_RECRUITER },
  { route: { path: '/questions' }, only: RESTRICTED_FOR_RECRUITER },
  { route: { path: '/results' }, exact: true },
  { route: { path: '/results/:resultId' }, exact: true },
  { route: { path: '/verification' }, only: RESTRICTED_FOR_TECHNICAL_AND_RECRUITER },
  { route: { path: '/settings' }, only: FOR_ROOT_AND_ADMIN },
  { route: { path: '/contests' }, exact: true },
];

export const useRedirect = (): { redirect: string } | { redirect?: undefined } => {
  const currentUser = useSelector<RootState>(state => state.auth.currentUser) as ICurrentUser;
  const { pathname } = useLocation();
  const rule = REDIRECT_RULES.find(rule => matchPath(pathname, rule.route));
  const hasMissingProps = !currentUser?.role || !rule;
  const shouldRedirect = rule?.only && rule.only.indexOf(currentUser?.role) < 0;

  if (pathname === HOME) {
    return { redirect: USER_REDIRECT_URL[currentUser?.role] };
  } else {
    if (hasMissingProps || shouldRedirect) {
      return { redirect: HOME };
    }
  }

  return {};
};

export const RedirectHandler = ({ children }) => {
  const { redirect } = useRedirect();

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return <>{children}</>;
};
