import { Row, Modal, Input, Form, Paragraph } from 'antd/index';
import React, { useEffect, useState } from 'react';
import { bool, func, string, array } from 'prop-types';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import CustomButton from 'components/CustomButton/CustomButton';
import { PLACEHOLDERS, BUTTON_TEXTS, SETTINGS_VIEW } from '../../../constants';

const { ENTER } = PLACEHOLDERS;
const { CANCEL, SAVE } = BUTTON_TEXTS;
const { EDIT_CATEGORY_NAME, NEW_NAME } = SETTINGS_VIEW;

const { enterCategory } = messages;
const { Item } = Form;

export const ModalCategory = ({
  isModalVisible,
  setIsModalVisible,
  updateCategory,
  category: categoryName,
  categories,
}) => {
  const [newCategoryName, setNewCategoryName] = useState(categoryName);
  const [indexEditedCategory, setIndexEditedCategory] = useState();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      categoryName,
    });
    setNewCategoryName(categoryName);
    setIndexEditedCategory(categories.indexOf(categoryName));
  }, [categoryName]);

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleOnFinishUpdateCategory = () => {
    updateCategory(newCategoryName, categoryName, indexEditedCategory, setIsModalVisible);
  };
  const handleSetData = event => setNewCategoryName(event.target.value);

  return (
    <Modal
      footer={null}
      title={EDIT_CATEGORY_NAME}
      visible={isModalVisible}
      onCancel={handleCancel}
      className="modal-in-settings"
      closable={false}
      centered
    >
      <Form
        form={form}
        requiredMark="optional"
        layout="vertical"
        className="container__form form"
        onFinish={handleOnFinishUpdateCategory}
        autoComplete="off"
      >
        <Item
          initialValue={categoryName}
          className="form-item form__item"
          label={<Paragraph className="form-item__label">{NEW_NAME}</Paragraph>}
          rules={[
            {
              required: true,
              message: enterCategory,
              whitespace: true,
            },
          ]}
          onChange={handleSetData}
          name="categoryName"
        >
          <Input className="form-item__input" placeholder={ENTER} autoComplete="off" maxLength="35" />
        </Item>
        <Row className="action-container" justify="space-around">
          <CustomButton onClick={handleCancel} className="custom-button--resign">
            {CANCEL}
          </CustomButton>
          <CustomButton type="primary" htmlType="submit" className="custom-button--green">
            {SAVE}
          </CustomButton>
        </Row>
      </Form>
    </Modal>
  );
};
ModalCategory.propTypes = {
  isModalVisible: bool,
  updateCategory: func,
  setIsModalVisible: func,
  category: string,
  categories: array,
};
