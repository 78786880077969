import React from 'react';
import { Button, Paragraph, Tag, Tooltip } from 'antd/index';
import DeleteIcon from './TableShapeCustomIcons/DeleteIcon';
import { USER_ROLES, TABLE_SHAPES_VIEW, BUTTON_TEXTS } from 'constants/index';
import assignNumericValueValueToLevel from 'utils/assignNumericValueToLevel';
import classNames from 'classnames';
import LevelButton from 'components/LevelButton/LevelButton';
import CustomButton from 'components/CustomButton/CustomButton';

const { QUESTION_TITLE, AUTHOR, CATEGORY, LEVEL } = TABLE_SHAPES_VIEW;
const { EDIT, PREVIEW } = BUTTON_TEXTS;

const { ROOT } = USER_ROLES;
const questionsListTableShape = ({
  sortArray,
  sortArrayFullName,
  handleOnEdit,
  role,
  quizzes,
  showModal,
  setIdClicked,
  setIsModalDeleteVisible,
}) => {
  const columns = [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">{QUESTION_TITLE}</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      align: 'left',
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">{AUTHOR}</Paragraph>;
      },
      dataIndex: 'createdBy',
      key: 'createdBy',
      align: 'left',
      width: 200,
      ellipsis: true,
      showSorterTooltip: false,
      sorter: (a, b) => sortArrayFullName(a, b, 'createdBy'),
    },
    {
      title: <Paragraph className="text-title">{CATEGORY}</Paragraph>,
      dataIndex: 'subjects',
      key: 'subjects',
      align: 'left',
      width: 250,
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a.subjects, b.subjects, '0'),
      render: function renderCustomSubject(_, { subjects }) {
        const renderData = subjects.map((item, index) => {
          return (
            <Tag
              key={index}
              className={classNames({
                cat: true,
                // 'no-ellipsis':true,
                'cat--js': item === 'JavaScript',
                'cat--jv': item === 'Java',
                'cat--php': item === 'Php',
                'cat--c': item === 'C',
                'cat--c-sh': item === 'C#',
                'cat--cpp': item === 'C++',
                'cat--db': item === 'Database',
                'cat--ai': item === 'AI',
                'cat--distributed-sys': item === 'Distributed systems',
                'cat--salesforce': item === 'Salesforce',
                'cat--python': item === 'Python',
                'cat--sql': item === 'SQL',
                'cat--css': item === 'CSS',
              })}
            >
              {item}
            </Tag>
          );
        });
        return (
          <Tooltip
            overlayClassName="categoryTooltip"
            placement="rightTop"
            title={renderData.length > 2 ? renderData : null}
            color={'#2d2d2d'}
          >
            <Paragraph
              className="categoryTooltip"
              ellipsis={{
                rows: 1,
              }}
            >
              {renderData}
            </Paragraph>
          </Tooltip>
        );
      },
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title ">{LEVEL}</Paragraph>;
      },
      dataIndex: 'level',
      key: 'level',
      align: 'left',
      width: 150,
      showSorterTooltip: false,
      sorter: (a, b) => {
        return assignNumericValueValueToLevel(b.level) - assignNumericValueValueToLevel(a.level);
      },
      render: function renderLevel(level) {
        return <LevelButton level={level} />;
      },
    },
    {
      align: 'right',
      key: 'action',
      width: 130,
      render: function renderActions(record) {
        return (
          <CustomButton className="button custom-button--preview" onClick={() => showModal(true, record)}>
            {PREVIEW}
          </CustomButton>
        );
      },
    },
    {
      align: 'right',
      key: 'action',
      width: 130,
      render: function renderActions(_, { id }) {
        return (
          <CustomButton className="custom-button--edit" onClick={() => handleOnEdit(id)}>
            {EDIT}
          </CustomButton>
        );
      },
    },
    {
      align: 'center',
      key: 'action',
      width: 80,
      render: function renderActions(_, { id }) {
        const quizzesWithQuestion = quizzes?.filter(({ questions }) => {
          return questions.some(({ id: questionId }) => {
            return questionId === id;
          });
        });
        const isQuestionInQuizz = quizzesWithQuestion?.length;
        return (
          <Button
            onClick={() => {
              setIsModalDeleteVisible(true);
              setIdClicked(id);
            }}
            disabled={isQuestionInQuizz}
            className="conditional-delete button-delete"
            type="text"
            title="Delete"
            icon={<DeleteIcon classes={`delete-icon ${isQuestionInQuizz && 'delete-icon--disabled'}`} />}
          />
        );
      },
    },
  ];
  const isNotRootColumns = columns.filter(({ dataIndex }) => dataIndex !== 'id');
  return role === ROOT ? columns : isNotRootColumns;
};
export default questionsListTableShape;
