export const ArrowDown = () => {
  return (
    <svg
      className="arrow-down"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="#3D6FF8"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="arrow-down__path"
        d="M6.24119 10.9998C6.24119 11.552 5.79347 11.9998 5.24119 11.9998C4.68891 11.9998 4.24119 11.552 4.24119 10.9998L4.24119 0.999755C4.24119 0.447471 4.68891 -0.000244394 5.24119 -0.00024437C5.79348 -0.000244346 6.24119 0.447471 6.24119 0.999756L6.24119 10.9998Z"
        fill="#3D6FF8"
      />
      <path
        className="arrow-down__path"
        d="M0.291987 9.46492C-0.0985369 9.0744 -0.0985369 8.44124 0.291987 8.05071C0.682511 7.66019 1.31568 7.66019 1.7062 8.05071L5.94884 12.2934C6.33936 12.6839 6.33936 13.317 5.94884 13.7076C5.55832 14.0981 4.92515 14.0981 4.53463 13.7076L0.291987 9.46492Z"
        fill="#3D6FF8"
      />
      <path
        className="arrow-down__path"
        d="M5.94884 13.7076C5.55832 14.0981 4.92515 14.0981 4.53463 13.7076C4.1441 13.317 4.1441 12.6839 4.53463 12.2934L8.77727 8.05071C9.16779 7.66019 9.80096 7.66019 10.1915 8.05071C10.582 8.44123 10.582 9.0744 10.1915 9.46492L5.94884 13.7076Z"
        fill="#3D6FF8"
      />
    </svg>
  );
};
