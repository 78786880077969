import { Paragraph, Text } from '../../antd';
import { sortArray } from '../../utils/sortArray';
import CustomButton from '../../components/CustomButton/CustomButton';
import { AlignType } from 'rc-table/lib/interface';
import classNames from 'classnames';

export const getContestsColumns = (currentUserId: string, activeContestId: string, handleOnClickOverview) => {
  return [
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--contest">Contest</Paragraph>;
      },
      dataIndex: 'title',
      key: 'title',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'title'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--attempts">Attempts</Paragraph>;
      },
      dataIndex: 'numOfAttempts',
      key: 'numOfAttempts',
      showSorterTooltip: false,
      sorter: (a, b) => sortArray(a, b, 'numOfAttempts'),
    },
    {
      title: function renderTitle() {
        return <Paragraph className="text-title text-title--active">Active</Paragraph>;
      },
      dataIndex: 'testId',
      key: 'testId',
      showSorterTooltip: false,
      render: function renderCustomRecord(record) {
        return (
          <Text
            className={classNames({
              'active-contest': record === activeContestId,
              'inactive-contest': record !== activeContestId,
            })}
          >
            {record === activeContestId ? 'active' : 'inactive'}
          </Text>
        );
      },
    },
    {
      align: 'right' as AlignType,
      key: 'overview',
      width: 130,
      render: function renderActions({ testId, title }) {
        return (
          <CustomButton
            className="button custom-button--preview"
            onClick={() => {
              handleOnClickOverview(testId, title);
            }}
          >
            Preview
          </CustomButton>
        );
      },
    },
  ];
};
