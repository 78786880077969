import Contests from '../../views/Contests/Contests';
import { v4 as uuidv4 } from 'uuid';

export const CONTESTS_ROUTES = [
  {
    path: '/contests',
    component: Contests,
    key: uuidv4(),
  },
];
