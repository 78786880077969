import { useEffect, useState } from 'react';
import {
  Col,
  Paragraph,
  Button,
  List,
  Form,
  Select,
  Checkbox,
  Option,
  Input,
  Image,
  Tooltip,
  Upload,
  Tag,
} from 'antd/index';
import { CaretUpOutlined, CaretDownOutlined, ClockCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { array, func, number, string, bool, oneOfType } from 'prop-types';
import { SCROLLING_TYPE, SECONDS_TO_MINUTES, MANAGE_TEST_VIEW, CUSTOM_TIME_PICKER } from 'constants/index';
import { useHistory } from 'react-router-dom';
import { handleDispatchValue } from 'utils/handleDispatchValue';
import { setTitleAction, setTestKindTagsAction, setTestTimeAction } from 'views/ManageTest/ManageTestSlice';
import { useDispatch } from 'react-redux';
import messages from 'utils/validationSchemaOptions/validationSchemaOptions';
import arrow from '../../assets/icons/arrow.svg';
import FailureMessageModal from 'components/FailureMessageModal/AddNewFailure';
import DeleteIcon from '../TableShapes/TableShapeCustomIcons/DeleteIcon';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import CustomButton from 'components/CustomButton/CustomButton';

const PDFIcon = () => {
  return (
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 0C0.482233 0 0.0563729 0.3935 0.00516289 0.897756C0.00174889 0.931373 0 0.965482 0 1V15C0 15.5523 0.447715 16 1 16H13C13.5523 16 14 15.5523 14 15V1C14 0.447715 13.5523 0 13 0H1ZM12 14V2L2 2V14L12 14ZM10 6C10 5.44772 9.55228 5 9 5H5C4.44772 5 4 5.44772 4 6C4 6.55228 4.44772 7 5 7H9C9.55228 7 10 6.55228 10 6ZM9 9C9.55228 9 10 9.44772 10 10C10 10.5523 9.55228 11 9 11H5C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9H9Z"
        fill="#3D6FF8"
      />
    </svg>
  );
};

const { title: emptyTitle, tooLongText } = messages;
const { PREV, NEXT } = SCROLLING_TYPE;
const {
  ACCEPT,
  SELECT,
  QUIT_EDITING,
  WITHOUT_SAVING,
  BACK,
  ENTER_TEST_NAME,
  PREVIEW,
  READ_REGULATIONS,
  TEST_TYPE,
  SAVE,
  CANCEL,
  ADD_REGULATIONS_FILE,
  REPLACE_REGULATIONS_FILE,
  UPLOAD_FIELD_TEXT,
} = MANAGE_TEST_VIEW;
const { MIN, SEC } = CUSTOM_TIME_PICKER;

const QuestionsPanelUI = ({
  handleOnFinish,
  isFormSubmit,
  suggestTimeInSeconds,
  selectedItems,
  uploadedFile,
  handleUploadedFile,
  fileLink,
  handleRemoveQuestionFromList,
  title,
  testKind,
  handleChangePosition,
  testKindOptions,
  isModalAcive,
  setIsModalActie,
  handleGoBack,
  showModal,
  onDragEnd,
  testTime,
  minutes,
}) => {
  const { Item } = Form;
  const [form] = Form.useForm();
  const [newTestKind, setNewTestKind] = useState(testKind ? testKind : []);
  const [inputTitle, setInputTitle] = useState();
  const numberOfQuestions = selectedItems.length;
  const isFieldFileValid = fileLink || uploadedFile.length;
  const [isTitle, setIsTitle] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultTestKind = SELECT;
  const handleOnChange = value => {
    setNewTestKind(value);
    handleDispatchValue(dispatch, setTestKindTagsAction, value);
    handleDispatchValue(dispatch, setTestTimeAction, suggestTimeInSeconds);
  };

  const seconds = testTime % SECONDS_TO_MINUTES;

  const handleTitleChange = e => {
    const newTitle = e.target.value;
    setInputTitle(newTitle);
    setIsTitle(true);
    handleDispatchValue(dispatch, setTitleAction, newTitle);
  };

  useEffect(() => {
    form.setFieldsValue({ numberOfQuestions });
  }, [form, numberOfQuestions]);

  useEffect(() => {
    if (history.location.pathname.includes('add-test')) {
      handleDispatchValue(dispatch, setTestKindTagsAction, newTestKind);
    }
  }, [history.location.pathname]);

  useEffect(() => {
    if (!history.location.pathname.includes('add-test')) {
      setNewTestKind(testKind);
    }
    setInputTitle(title);
    if (title) {
      setIsTitle(true);
    }
  }, [title, testKind, history.location.pathname]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    background: isDragging ? '#3f3f3f' : '#2D2D2D',
    borderRadius: '0.8rem',
    ...draggableStyle,
  });

  return (
    <Col className="test-details">
      <FailureMessageModal
        firstLine={QUIT_EDITING}
        secondLine={WITHOUT_SAVING}
        buttonTextAccept={ACCEPT}
        buttonTextResign={CANCEL}
        isVisible={isModalAcive}
        handleOnClickAccept={handleGoBack}
        handleOnClickResign={() => setIsModalActie(false)}
      />
      <Form
        requiredMark="optional"
        layout="vertical"
        className="container__form form"
        onFinish={handleOnFinish}
        form={form}
        autoComplete="off"
      >
        <Paragraph className="test-details__title">
          <Image
            className="test-details__title-arrow"
            src={arrow}
            alt={BACK}
            preview={false}
            onClick={() => setIsModalActie(true)}
          />
          {isTitle && (
            <Item
              className="test-details__title-item"
              initialValue={inputTitle}
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: emptyTitle,
                },
                {
                  max: 100,
                  message: tooLongText,
                },
              ]}
              name="title"
              validateFirst
              tabIndex="0"
            >
              <Input
                value={inputTitle}
                onChange={handleTitleChange}
                name="title"
                placeholder={ENTER_TEST_NAME}
                className="item"
              />
            </Item>
          )}
          <Paragraph className="test-details__title-clock">
            <ClockCircleOutlined />
            <Paragraph className="test-details__title-clock-icon" />
            {minutes} {MIN} {seconds} {SEC}
          </Paragraph>
        </Paragraph>
        <Item
          className="test-details__warning"
          name="numberOfQuestions"
          hidden={numberOfQuestions > 0}
          rules={[
            () => ({
              validator() {
                const value = form.getFieldValue().numberOfQuestions;
                if (!value) {
                  return Promise.reject();
                }
                if (isNaN(value) || value < 1 || value > 1000) {
                  return Promise.reject();
                }
                return Promise.resolve();
              },
            }),
          ]}
          validateFirst
          hasFeedback
          tabIndex="0"
        >
          <Input className="test-details__warning-item" />
        </Item>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div className="test-details__questions" ref={provided.innerRef} {...provided.droppableProps}>
                <List
                  dataSource={selectedItems}
                  renderItem={({ id, title }, index) => {
                    return (
                      <Draggable draggableId={id} index={index} key={id}>
                        {(provided, snapshot) => (
                          <div
                            className="test-details__questions-item"
                            name="question"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <Item key={id}>
                              <Button
                                className="test-details__questions-item-button up"
                                value={PREV}
                                index={index}
                                onClick={handleChangePosition}
                              >
                                <CaretUpOutlined className="icon" />
                              </Button>
                              <Paragraph className="test-details__questions-item-title">{`${
                                index + 1
                              }.\u00A0\u00A0\u00A0\u00A0${title}`}</Paragraph>
                              <Button
                                className="test-details__questions-item-button down"
                                value={NEXT}
                                index={index}
                                onClick={handleChangePosition}
                              >
                                <CaretDownOutlined className="icon" />
                              </Button>
                              <div className="test-details__questions-item-container">
                                <CustomButton
                                  onClick={() => {
                                    showModal(true, selectedItems[index]);
                                  }}
                                  className="custom-button--preview test-details__questions-item-preview"
                                >
                                  {PREVIEW}
                                </CustomButton>
                                <Button
                                  className="test-details__questions-item-icon"
                                  onClick={() => handleRemoveQuestionFromList(id)}
                                >
                                  <DeleteIcon classes={'delete-icon'} />
                                </Button>
                              </div>
                            </Item>
                          </div>
                        )}
                      </Draggable>
                    );
                  }}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {fileLink && (
          <Tag className="test-details__file">
            <a href={fileLink} target="_blank" rel="noopener noreferrer" download>
              <PDFIcon /> {READ_REGULATIONS}
            </a>
          </Tag>
        )}
        <Paragraph className="test-details__uploadpdf-title">
          {fileLink ? REPLACE_REGULATIONS_FILE : ADD_REGULATIONS_FILE}
        </Paragraph>
        <Upload
          size={3000}
          className="test-details__uploadpdf"
          fileList={uploadedFile}
          onChange={handleUploadedFile}
          maxCount={1}
          multiple={false}
          onPreview={() => false}
          isImageUrl={() => false}
          listType="text"
        >
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>{UPLOAD_FIELD_TEXT}</div>
        </Upload>

        <Paragraph className="text test-type">{TEST_TYPE}</Paragraph>
        <Paragraph className="test-details__confirmation">
          {newTestKind && (
            <Item
              initialValue={newTestKind ? newTestKind : defaultTestKind}
              className="form"
              name="testKind"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder={newTestKind.length ? newTestKind : defaultTestKind}
                suffixIcon={<CaretDownOutlined />}
                defaultValue={newTestKind.length ? newTestKind : null}
                className="select-question-panel form__item"
                dropdownAlign={{ offset: [0, 15] }}
                value={newTestKind}
                optionLabelProp="label"
                onChange={handleOnChange}
              >
                {testKindOptions.map(item => (
                  <Option key={item.value} value={item.value} label={item.label} className="form-item__test-option">
                    <Tooltip placement="right" title={item.label} key={newTestKind.length ? newTestKind : item.value}>
                      <Checkbox
                        value={item.value}
                        checked={item.value === newTestKind}
                        className="form-item__select-option"
                      />
                      {item.value}
                    </Tooltip>
                  </Option>
                ))}
              </Select>
            </Item>
          )}
          <CustomButton
            className={
              !numberOfQuestions || !newTestKind.length > 0 || !isFieldFileValid || isFormSubmit
                ? 'custom-button--disabled'
                : 'custom-button--submit'
            }
            disabled={!numberOfQuestions || !newTestKind.length > 0 || !isFieldFileValid || isFormSubmit}
            style={{ width: '11rem' }}
            htmlType="submit"
          >
            {SAVE}
          </CustomButton>
        </Paragraph>
      </Form>
    </Col>
  );
};
QuestionsPanelUI.propTypes = {
  handleOnFinish: func,
  isFormSubmit: func,
  testKindOptions: array,
  suggestTimeInSeconds: number,
  selectedItems: array,
  uploadedFile: array,
  handleUploadedFile: func,
  fileLink: string,
  handleRemoveQuestionFromList: func,
  title: string,
  testKind: oneOfType([string, array]),
  handleChangePosition: func,
  showModal: func,
  isModalAcive: bool,
  setIsModalActie: func,
  handleGoBack: func,
  onDragEnd: func,
  testTime: number,
  minutes: number,
};

export default QuestionsPanelUI;
