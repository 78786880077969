import { ALPHABET } from 'constants/index';

const getNewAnswer = ({ length }) => {
  return {
    letter: ALPHABET[length],
    count: length,
    content: '',
    isCorrect: false,
    name: `answer-${ALPHABET[length]}`,
    firestoreContent: '',
  };
};

export default getNewAnswer;
