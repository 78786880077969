/* eslint react/prop-types: 0 */

import { Checkbox, Button, CustomIcon, Row } from 'antd/index';
import CustomBBCodeEditor from 'components/CustomBBCodeEditor/CustomBBCodeEditor';
import ImageUpload from './ImageUpload';

import { ALPHABET, MANAGE_QUESTION_FORM_ITEM_NAMES, QUESTION_TYPES, MANAGE_QUESTION } from 'constants/index';

const { CLOSED, OPEN } = QUESTION_TYPES;
const { TYPE, SUBTYPE } = MANAGE_QUESTION_FORM_ITEM_NAMES;
const { ANSWER, DELETE, ENTER_TEXT, ENTER_ANSWER } = MANAGE_QUESTION;

const XIcon = () => {
  return (
    <svg width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83883 7.18198C6.0341 7.37724 6.35068 7.37724 6.54594 7.18198C6.7412 6.98672 6.7412 6.67014 6.54594 6.47487L4.07107 4L6.54594 1.52513C6.7412 1.32986 6.7412 1.01328 6.54594 0.81802C6.35068 0.622758 6.0341 0.622758 5.83883 0.81802L3.36396 3.29289L0.889087 0.81802C0.693825 0.622757 0.377242 0.622758 0.18198 0.81802C-0.0132818 1.01328 -0.0132819 1.32986 0.18198 1.52513L2.65685 4L0.18198 6.47487C-0.0132817 6.67014 -0.0132818 6.98672 0.18198 7.18198C0.377242 7.37724 0.693825 7.37724 0.889087 7.18198L3.36396 4.70711L5.83883 7.18198Z"
        fill="#FFA39E"
      />
    </svg>
  );
};

const Answer = ({
  name,
  letter,
  canBeDisabled,
  setAnswers,
  handleSetAnswersState,
  answers,
  handleSetFieldsValue,
  handleGetFormFieldValue,
  action,
  link,
  contentImageLink,
  setEditorValue,
}) => {
  const subtype = handleGetFormFieldValue(SUBTYPE);
  const type = handleGetFormFieldValue(TYPE);

  const handleDeleteAnswer = letter => {
    setAnswers(prevAnswersState =>
      prevAnswersState
        .filter(answer => answer.letter !== letter)
        .map((answer, index) => ({
          ...answer,
          count: index,
          name: `answer-${ALPHABET[index]}`,
          letter: ALPHABET[index],
        })),
    );
  };

  const handleCheckAnswer = e => {
    const clickedCheckboxValue = e.target.value;
    setAnswers(prevAnswersState =>
      prevAnswersState.map(answer =>
        answer.name === clickedCheckboxValue ? { ...answer, isCorrect: !answer.isCorrect } : answer,
      ),
    );
  };

  const actionUI =
    type === CLOSED ? (
      <Row align="middle" className="answer">
        <Checkbox
          value={name}
          checked={answers.find(answer => answer.name === name).isCorrect}
          onChange={handleCheckAnswer}
          className="answer__checkbox"
        >
          {ANSWER} {letter}
        </Checkbox>
        <Button
          disabled={canBeDisabled}
          onClick={() => {
            handleDeleteAnswer(letter);
          }}
          className="answer__delete-button"
        >
          {DELETE} <CustomIcon component={XIcon} />{' '}
        </Button>
      </Row>
    ) : null;

  const answerUI = (
    <>
      <CustomBBCodeEditor
        handleSetAnswersState={handleSetAnswersState}
        type={type}
        name={name}
        initialContent={ENTER_TEXT}
        letter={letter}
        handleSetFieldsValue={handleSetFieldsValue}
        validationMessage={ENTER_ANSWER}
        setEditorValue={setEditorValue}
        subtype={subtype}
      />

      {type !== OPEN && (
        <ImageUpload
          letter={letter}
          action={action}
          link={type === CLOSED ? link : contentImageLink}
          handleSetFieldsValue={handleSetFieldsValue}
          name={name}
          setAnswers={setAnswers}
        />
      )}
    </>
  );

  return (
    <div className="answer-container">
      {actionUI}
      {answerUI}
    </div>
  );
};

export default Answer;
