import React from 'react';
import { Button, Card, Col, Divider, Row, Skeleton, Paragraph, Title } from 'antd/index';
import PropTypes from 'prop-types';
import { INFO_CARD } from '../../constants';

/**
 * Draws customisable Card in the middle of the view
 * @param {string} title Card title
 * @param {string} lead Card lead
 * @param {string} paragraph Cart paragraph
 * @param {boolean} divider Ant divider - horisontal line
 * @param {boolean | object} button If boolean - default button, if prop - custom button label can be set
 * @param {function} handleClick Method to handle button action
 * @param {component} children JSX component can be set between paragraph and divider
 */

const CustomInfoCard = ({
  loading,
  children,
  title,
  lead,
  paragraph,
  handleClick,
  divider,
  button,
  disabled = false,
  buttonLabel = INFO_CARD.BUTTON_LABEL,
}) => {
  return (
    <Row className="custom-info" justify="center" align="middle">
      <Col sm={6} md={12} lg={12} xl={8} xxl={6}>
        <Card className="custom-info__card">
          <Skeleton loading={loading} active>
            {title && (
              <Title level={2} className="custom-info__title">
                {title}
              </Title>
            )}
            {lead && <Title level={4}>{lead}</Title>}
            {paragraph && <Paragraph className="custom-info__description">{paragraph}</Paragraph>}
            {children}
            {divider && <Divider />}
            {button && (
              <Button disabled={disabled} onClick={handleClick} type={'primary'} className="custom-info__button">
                {buttonLabel}
              </Button>
            )}
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
};

export default CustomInfoCard;
CustomInfoCard.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.node,
  title: PropTypes.string,
  lead: PropTypes.string,
  paragraph: PropTypes.string,
  handleClick: PropTypes.func,
  divider: PropTypes.bool,
  button: PropTypes.bool,
  buttonLabel: PropTypes.string,
  disabled: PropTypes.bool,
};
