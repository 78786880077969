/* eslint react/prop-types: 0 */
// * eslint-disable indent */

import { useEffect, useState } from 'react';
import { Select, Option, Checkbox, Item, Paragraph, Text } from 'antd/index';
import { CaretDownOutlined } from '@ant-design/icons';
import { PLACEHOLDERS } from 'constants/index';

const { SELECT } = PLACEHOLDERS;

const styles = {
  boxShadow: 'none !important',
  background: '#2d2d2d',
  borderRadius: '7px 7px',
  border: '1px solid #3d6ff8',
};

const FormSelect = ({ options, mode, message, name, handleGetFormFieldValue, wasFormSubmitted, validationMessage }) => {
  const initialValue = handleGetFormFieldValue(name);
  const [selectedCheckbox, setSelectedCheckbox] = useState(
    Array.isArray(initialValue) ? initialValue : [initialValue] || [],
  );

  useEffect(() => {
    if (wasFormSubmitted === '') {
      return;
    }
    setSelectedCheckbox([]);
  }, [wasFormSubmitted]);

  return (
    <Item
      rules={[{ required: true, message: validationMessage }]}
      message={message}
      name={name}
      className="add-question-form__item"
      label={<Paragraph className="add-question-form__label">{message} </Paragraph>}
    >
      <Select
        disabled={options.length === 1}
        mode={mode}
        dropdownStyle={styles}
        maxTagCount={4}
        suffixIcon={<CaretDownOutlined />}
        placeholder={SELECT}
        showArrow={true}
        showSearch={true}
        optionFilterProp="label"
        defaultActiveFirstOption={false}
        tagRender={({ label }) => <Text className="form-select__tag"> {label}</Text>}
        className="form-select"
        dropdownAlign={{ offset: [0, 8] }}
        optionLabelProp="label"
        onSelect={(_, { value }) => {
          setSelectedCheckbox(prev => (mode === null ? [value] : [...prev, value]));
        }}
        onDeselect={(_, { value }) => {
          setSelectedCheckbox(prev => (mode === null ? [] : prev.filter(item => item !== value)));
        }}
      >
        {options.map(option => (
          <Option key={option} value={option} label={option}>
            <Checkbox value={option} checked={selectedCheckbox.includes(option)} className="custom-checkbox" />
            {option}
          </Option>
        ))}
      </Select>
    </Item>
  );
};

export default FormSelect;
