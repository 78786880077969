import { db } from '../firebase';
import firebase from 'firebase/app';

export const generatedTestsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'generatedTests',
);

export const questionOptionsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'questionOptions',
);

export const questionsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'questions',
);

export const testsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'quizzes',
);

export const testAnswersCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'test',
);

export const tagsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'questionOptions',
);

export const rolesCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'roles',
);

export const usersRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection('users');

export const templatesCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'templates',
);

export const resultsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'results',
);

export const accessRequestsCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'accessRequests',
);

export const messageCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'message',
);

export const contactCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'contact',
);

export const activeContestCollectionRef: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> = db.collection(
  'activeContest',
);
