/* eslint-disable react/prop-types */
import { FC, useEffect, useState } from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import { Checkbox, Option, Select, Tag } from 'antd/index';
import { ICustomSelect } from './CustomSelectTypes';

const CustomSelect: FC<ICustomSelect> = ({
  value,
  options,
  placeholder,
  onChange,
  initial,
  isSingle,
  labels,
  updateData,
  disabledSelectInUsersTable = false,
  isUsersTable = false,
  containerRef,
}) => {
  const [values, setValues] = useState<any[]>(initial || []);
  const [label, setLabel] = useState<string[]>(() => {
    const isArrayOfNumbers = array => array.every(element => typeof element === 'number');

    if (Array.isArray(initial) && isArrayOfNumbers(initial)) {
      return options.filter(({ value }) => initial.includes(value)).map(({ label }) => label);
    }

    return initial || [];
  });

  useEffect(() => {
    if (typeof initial === 'number' || !initial?.length) {
      setValues([]);
    }
  }, [initial]);

  useEffect(() => {
    if (value && labels && isUsersTable) {
      setValues(value);
      setLabel(labels);
    }
  }, [value]);

  useEffect(() => {
    if (labels?.length) {
      setLabel(labels);
      if (!isUsersTable) {
        setValues(labels);
      }
    }
  }, [labels]);

  const handleOnChange = (value, e) => {
    if (isSingle) {
      setLabel(e.label);
      setValues([e.value]);
    } else {
      const label = e
        .reduce((label, item) => {
          return [...label, item.label];
        }, [])
        .filter(item => item);
      setLabel(label);
      setValues(value);
    }
    if (onChange) {
      onChange(value);
    }
  };
  const tagRender = () => {
    return (
      <Tag closable={false} className="form-item__select-tag">
        {label ? label.join(', ') : ''}
      </Tag>
    );
  };

  return (
    options && (
      <Select
        mode={isSingle ? undefined : 'multiple'}
        suffixIcon={<CaretDownOutlined />}
        placeholder={placeholder}
        className="form-item__select"
        showArrow
        dropdownAlign={{ offset: [0, 15] }}
        dropdownStyle={{ width: 'auto' }}
        dropdownMatchSelectWidth={false}
        optionLabelProp="label"
        onChange={handleOnChange}
        defaultValue={label}
        value={values}
        tagRender={tagRender}
        maxTagCount={1}
        disabled={(!!labels?.length && !isUsersTable) || typeof labels === 'number' || disabledSelectInUsersTable}
        onDropdownVisibleChange={open => {
          if (!open) {
            updateData?.(values);
          }
        }}
        showSearch={true}
        optionFilterProp="label"
        getPopupContainer={containerRef ? () => containerRef.current : undefined}
      >
        {options.map(item => (
          <Option key={item.value} value={item.value} label={item.label}>
            <Checkbox
              value={item.value}
              checked={
                typeof values === 'string'
                  ? !![values].filter(value => value === item.value).length
                  : Array.isArray(values)
                  ? values.includes(item.value)
                  : false
              }
              className="form-item__select--option"
            />
            {item.label}
          </Option>
        ))}
      </Select>
    )
  );
};

export default CustomSelect;
