import React, { ReactNode } from 'react';
import { Button } from '../../antd';

interface ICustomButtonProps {
  className?: string;
  children: ReactNode;
  onClick?: () => void;
  otherProps?: unknown;
  disabled?: boolean;
  htmlType?: 'submit';
}

const CustomButton = ({ className, children, onClick, disabled, ...otherProps }: ICustomButtonProps) => {
  return (
    <Button onClick={onClick} className={['custom-button', className].join(' ')} disabled={disabled} {...otherProps}>
      {children}
    </Button>
  );
};

export default CustomButton;
