import CustomButton from 'components/CustomButton/CustomButton';
import DeleteIcon from 'assets/icons/DeleteIcon';
import { CustomIcon } from 'antd/index';
import { SETTINGS_VIEW } from '../../constants';
import React from 'react';

const { ADD_ACCESS } = SETTINGS_VIEW;

const tableShapeAccess = (
  setIsModalDeleteVisible,
  setIdClicked,
  setIsAddAccessModalVisible,
  setUserAccessEmail,
  setUserAccessId,
) => {
  const showAddUserAccessModal = (email, id) => {
    setIsAddAccessModalVisible(prev => !prev);
    setUserAccessEmail(email);
    setUserAccessId(id);
  };

  const columns = [
    {
      key: 'email',
      dataIndex: 'email',
      width: '100%',
    },
    {
      key: 'addAccess',
      width: '100%',
      align: 'end',
      render: function renderEditButton({ email, id }) {
        return (
          <>
            <CustomButton className="custom-button--edit" onClick={() => showAddUserAccessModal(email, id)}>
              {ADD_ACCESS}
            </CustomButton>
          </>
        );
      },
    },

    {
      key: 'deleteAccessItem',
      width: '100%',
      align: 'end',
      render: function renderDeleteAccessItem(row) {
        return (
          <>
            <CustomIcon
              onClick={() => {
                setIsModalDeleteVisible(true);
                setIdClicked(row.id);
              }}
              component={DeleteIcon}
            />
          </>
        );
      },
    },
  ];
  return columns;
};
export default tableShapeAccess;
