import TestExpired from 'views/TestExpired/TestExpired';
import TestQuestionsWrapperContainer from 'views/TestQuestionsWrapper/TestQuestionsWrapperContainer';
import TestResult from 'views/TestResult/TestResult';
import { v4 as uuidv4 } from 'uuid';

export const CANDIDATE_ROUTES = [
  {
    path: '/test-to-solve/:generatedId',
    component: TestQuestionsWrapperContainer,
    key: uuidv4(),
  },

  {
    path: '/test-expired/:generatedId',
    component: TestExpired,
    key: uuidv4(),
  },
  {
    path: '/test-result/:generatedId',
    component: TestResult,
    key: uuidv4(),
  },
];
